import { useMemo, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import useReportContext from '../../hooks/useReportContext'
import { IDelete } from '../../iconComponents'
import { SectionImageGalery } from '../../types/generateReport'
import useAppContext from '../../hooks/useAppContext'
import {
  addReportImageToGallery,
  getReportImagesGallery,
  uploadImages3,
} from '../../api/newReport'
import { getOrganizations } from '../../api/organizations'
import SearchInput from '../SearchInput'
import { URL as URL_BACK } from '../../constants'
import { handleInputFile } from '../../utils/handleInputFile'
import ImagesSelector from './ImagesSelector'
import Input from '../InputCleared'
import Button from '../buttons/Button'

type Props = {
  section: SectionImageGalery;
};

export default function ImageGaleryCard({ section }: Props) {
  const { token, isCentralAdmin, user } = useAppContext()
  const { handleAddImageGalery, handleUpdateImageGalery, hadleDeleteImageGalery } = useReportContext()

  const {
    data: library,
    isFetching,
    refetch,
  } = useQuery(
    ['getReportImagesGallery', section.gallery_type],
    () => getReportImagesGallery(token, { gallery_type: section.gallery_type ?? 'general' }),
    {
      initialData: {
        response: new Response(),
        data: {
          data: [],
        },
        ok: true,
      },
      enabled: token !== '',
      refetchOnWindowFocus: false,
    }
  )
  const initialData = { page: 1, total_items: 0, total_pages: 1, data: [] }
  const { data: organizatoins } = useQuery(
    ['getOrganizations'],
    () => getOrganizations(token, initialData),
    {
      initialData,
      enabled: token !== '' && isCentralAdmin,
      refetchOnWindowFocus: false,
    }
  )
  const organizatoinsForInput = useMemo(
    () =>
      organizatoins.data?.map((organizatoin) => ({
        value: organizatoin.id,
        label: organizatoin.name,
      })),
    [organizatoins]
  )
  const [organization_id, setOrganization_id] = useState<number | null>(null)
  const [organizationError, setOrganizationError] = useState('')

  const [file, setFile] = useState<File | null>(null)
  const [fileName, setFileName] = useState('')
  const [isLoading, setIsLoading] = useState(false)


  const [error, seterror] = useState('')

  useEffect(() => {
    if (!error) return 
    const clearError = setTimeout(() => seterror(''), 10000)
  
    return () => clearTimeout(clearError)
  }, [error])

  const UploadImage = async () => {
    if (!file) return
    if (!fileName) return
    if (isLoading) return 
    setIsLoading(true)

    const formData = new FormData()
    formData.append('image', file)
    formData.append(
      'organization',
      isCentralAdmin
        ? organization_id.toString()
        : user.organization.id.toString()
    )
    formData.append('name', fileName)
    formData.append(
      'gallery_type',
      section.gallery_type ?? 'general'
    )

    const { ok, data } = await addReportImageToGallery(
      token,
      formData
    )

    if (!ok) {
      console.error(data)
    } else {
      handleUpdateImageGalery(section.id, section.value.at(-1).id, URL_BACK + data.image)
      refetch()
    }
    setFile(null)
    setFileName('')
    setIsLoading(false)
    refetch()
  }

  const clearInput = () => {
    hadleDeleteImageGalery(section.id, section.value.at(-1).id)
    setFile(null)
    setFileName('')
  }

  return (
    <>
      <ImagesSelector
        library={library}
        isFetching={isFetching}
        refetch={refetch}
        onSelect={(value: string) => handleAddImageGalery(section.id, value)}
      />

      {section.value.slice(0, file ? -1 : undefined).map((image) => (
        <div key={image.id} className="InputFile">
          <img src={image.value} className="InputFile_Image" />
          <div className="InputFile_Text">
            <p className="text-5 text-primary">
              {section.placeholder ?? 'Upload image'}
            </p>
            <span className="text-5 text-secondary">Max 5mb.</span>
          </div>
          <IDelete
            width={24}
            height={24}
            className="text-primary me-2"
            onClick={() => hadleDeleteImageGalery(section.id, image.id)}
          />
        </div>
      ))}

      {isCentralAdmin && (
        <SearchInput
          label="Chose organization"
          name="organization_id"
          placeholder="Choose organization"
          search={organizatoinsForInput}
          value={organization_id}
          onChange={(id) => setOrganization_id(id as unknown as number)}
          error={organizationError}
        />
      )}

      {file && section.value.at(-1) && (
        <div className="InputFile_Library">
          <div className="InputFile_Library_Header">
            <img src={section.value.at(-1).value} className="InputFile_Image" />
            <span className="InputFile_Text">
              <span className="text-5 text-primary">
                {(file.size / 1024 ** 2).toFixed(2)}MB
              </span>
            </span>
            <IDelete
              width={24}
              height={24}
              className="text-primary me-2"
              onClick={clearInput}
            />
          </div>
          <Input
            name="image_name"
            label="Save image to library?"
            placeholder="Name"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            innerRight={
              <Button
                variant="blue"
                size="xs"
                onClick={UploadImage}
                disabled={!fileName}
              >
                Save
              </Button>
            }
          />
        </div>
      )}
      <label
        className="text-5 text-blue text-center"
        tabIndex={0}
        onChange={(event) =>
          handleInputFile({
            event,
            fileHandler: async (file: File) => {
              if (isLoading) return 
              setIsLoading(true)
              const formData = new FormData()
              formData.append('image', file)
              const { ok, data } = await uploadImages3(token, formData)
                
              if (!ok) {
                // console.error(data)
              } else {
                handleAddImageGalery(section.id, data.url)
              }
              setFile(file)
              setIsLoading(false)
            },
            fileTypes: ['image/jpg', 'image/jpeg', 'image/png'],
            fileMaxSize: 5,
            errorHandler: (error) => seterror(error)
          })
        }
        onClick={(e) => {
          if (!isCentralAdmin) return null
          if (!organization_id) {
            e.preventDefault()
            return setOrganizationError('Organization is required')
          }
          if (isLoading) return e.preventDefault()
          setOrganizationError('')
        }}
      >
          + Add new image
        <input
          id="Add-imageto "
          name="Add-imageto "
          type="file"
          accept={['image/jpg', 'image/jpeg', 'image/png'].reduce(
            (acc, cur, index) => acc + (index === 0 ? cur : `, ${cur}`),
            ''
          )}
          style={{ display: 'none' }}
          multiple
        />
      </label>
      
      
      {error ? (
        <div
          className="Input__Error"
          style={{ height: 18, opacity: 1 }}
          dangerouslySetInnerHTML={{ __html: error }}
        />
      ) : (
        <></>
      )}
    </>
  )
}
