import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import useAppContext from '../../hooks/useAppContext'
import useModalsContext from '../../hooks/useModalsContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import { useEffect, useState } from 'react'
import { IList, IPlus } from '../../iconComponents'
import MaktBanner from '../../components/MaktBanner'

const Home = () => {
  const { firstCharge, token, user, isCentralAdmin, isOrganizationAdmin, isRegionAdmin, toggleShowNewClientModal } =
    useAppContext()
  const { resetNewSimulation } = useNewSimulationContext()
  const { setShowContinueSimulationModal } = useModalsContext()
  const [userError, setUserError] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (!firstCharge && token && user?.error)
      setUserError('Something went wrong 🤷‍♂️.')
  }, [token, firstCharge, user])

  const goToCreateNewSimulation = () => {
    setShowContinueSimulationModal(false)
    resetNewSimulation()
    navigate('/dashboard/create-simulation/step/general-information')
  }

  return (
    <>
      <Helmet>
        <title>Makt: Home</title>
      </Helmet>

      <main className="Home">
        <section className="Home__Logo">
          <MaktBanner />
        </section>

        <section className="Home__Content">
          {userError ? (
            <div className="Home__UserError">
              <h2 className="Text-2 text-primary">{userError}</h2>
            </div>
          ) : isCentralAdmin ? (
            <div className="Home__Card-Container">
              <HomeCard path="users" name="Users" icon={<IList className="Home__Card_Icon" />} />
              <HomeCard
                path="organizations"
                name="Organizations"
                icon={<IList className="Home__Card_Icon" />}
              />
              <HomeButton
                name="Create new simulation"
                icon={<IPlus className="Home__Button_Icon" />}
                onClick={goToCreateNewSimulation}
              />
              <HomeButton path="manage-simulations" name="Manage simulations" />
              <HomeButton path="parameters" name="Parameters" />
              <HomeButton path="clients" name="Clients" />
              <HomeButton path="statistics" name="Statistics" />
              <HomeButton path="logs" name="Logs" />
            </div>
          ) : isOrganizationAdmin ? (
            <div className="Home__Card-Container">
              <HomeCard path="users" name="Users" icon={<IList className="Home__Card_Icon" />} />
              <HomeCard
                path="regions"
                name="Offices & regions"
                icon={<IList className="Home__Card_Icon" />}
              />
              <HomeButton
                name="Create new simulation"
                icon={<IPlus className="Home__Button_Icon" />}
                onClick={goToCreateNewSimulation}
              />
              <HomeButton path="manage-simulations" name="Manage simulations" />
              <HomeButton path="clients" name="Clients" />
              <HomeButton path="statistics" name="Statistics" />
              <HomeButton path="logs" name="Logs" />
            </div>
          ) : isRegionAdmin ? (
            <div className="Home__Card-Container">
              <HomeCard
                name="Create new simulation"
                icon={<IPlus className="Home__Card_Icon" />}
                onClick={goToCreateNewSimulation}
              />
              <HomeCard
                path="manage-simulations"
                name="Manage simulations"
                icon={<IList className="Home__Card_Icon" />}
              />
              <HomeButton path="users" name="Users" />
              <HomeButton path="clients" name="Clients" />
              <HomeButton path="statistics" name="Statistics" />
              <HomeButton path="logs" name="Logs" />
            </div>
          ) : (
            <div className="Home__Card-Container">
              <HomeCard
                name="Create new simulation"
                icon={<IPlus className="Home__Card_Icon" />}
                onClick={goToCreateNewSimulation}
              />
              <HomeCard
                path="manage-simulations"
                name="Manage simulations"
                icon={<IList className="Home__Card_Icon" />}
              />
              <HomeButton path="clients" name="Clients" />
              <HomeButton name="Create new client" icon={<IPlus className="Home__Button_Icon" />} onClick={toggleShowNewClientModal} />
            </div>
          )
          }
        </section>
      </main>
    </>
  )
}

export default Home

export const HomeCard = ({
  path,
  name,
  icon,
  onClick,
}: {
  path?: string;
  name: string;
  icon: JSX.Element;
  onClick?: () => void;
}) => {
  if (onClick) return (
    <button className="Home__Card" onClick={onClick} title={name}>
      {icon}
      <h2 className="text-4 fw-normal">{name}</h2>
      <span className='Button blue m justify-content-between w-100'>
        Access
        <FontAwesomeIcon icon={faChevronRight} />
      </span>
    </button>
  )

  return (
    <Link to={`/dashboard/${path}`} className="Home__Card" title={name}>
      {icon}
      <h2 className="text-4 text-surface-900 fw-normal">{name}</h2>
      <span className='Button black m justify-content-between w-100'>
        Access
        <FontAwesomeIcon icon={faChevronRight} />
      </span>
    </Link>
  )
}

export const HomeButton = ({ path, name, icon = <IList className="Home__Button_Icon" />, onClick }: { path?: string; name: string; icon?: JSX.Element; onClick?: () => void; }) => {
  if (onClick) return (
    <button className="Home__Button" onClick={onClick} title={name}>
      {icon}
      <h2 className="w-100 text-4 text-surface-900 fw-normal">{name}</h2>
      <span className='Button black m' style={{ padding: '6px 8px' }}>
        <FontAwesomeIcon icon={faChevronRight} />
      </span>
    </button>
  )

  return (
    <Link to={`/dashboard/${path}`} className="Home__Button" title={name}>
      {icon}
      <h2 className="w-100 text-4 text-surface-900 fw-normal">{name}</h2>
      <span className='Button black m' style={{ padding: '6px 8px' }}>
        <FontAwesomeIcon icon={faChevronRight} />
      </span>
    </Link>
  )
}
