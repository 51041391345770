import classNames from 'classnames'
import { ICircleCheck } from '../../iconComponents'
import Selector from '../inputs/Selector'
import Modal from './Modal'
import { useLocalStorage } from 'usehooks-ts'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import dayjs from 'dayjs'
import { DateFormats } from '../../types/index'
import Button from '../buttons/Button'

interface Props {
  closeModal: () => void;
  handleGenerateReport: (
    language: 'en' | 'du',
    numbersType: 'dot' | 'comma',
    dateFormat: DateFormats
  ) => void;
}

const dateList = {
  'DD/MM/YYYY': 'DD/MM/YYYY',
  'MM/DD/YYYY': 'MM/DD/YYYY',
  'YYYY/MM/DD': 'YYYY/MM/DD',
}

export default function ReportInfoModal({
  closeModal,
  handleGenerateReport,
}: Props) {
  const { simulationDetails } = useNewSimulationContext()
  const [reportLanguage, setReportLanguage] = useLocalStorage<'en' | 'du'>(
    'reportLanguage',
    'en'
  )
  const [numbersType, setNumbersType] = useLocalStorage<'dot' | 'comma'>(
    'numbersType',
    'dot'
  )
  const [dateFormat, setDateFormat] = useLocalStorage<DateFormats>(
    'dateFormat',
    'DD/MM/YYYY'
  )

  const handleSubmit = () => {
    handleGenerateReport(reportLanguage, numbersType, dateFormat)
    closeModal()
  }

  const {
    last_updated_report_information_en,
    last_updated_report_information_du,
  } = simulationDetails

  return (
    <Modal title="Report information" closeModal={closeModal}>
      <div className="d-flex flex-column pt-4" style={{ gap: '2rem' }}>
        <Selector
          label="Language"
          selected={reportLanguage}
          options={{
            en: `English ${last_updated_report_information_en
              ? `<span class="ms-auto">Last saved on ${dayjs(
                last_updated_report_information_en,
                'YYYY-MM-DDTHH:mm'
              ).format('DD/MM/YY, HH:mm')}.</span>`
              : ''
            }`,
            du: `Dutch ${last_updated_report_information_du
              ? `<span class="ms-auto">Last saved on ${dayjs(
                last_updated_report_information_du,
                'YYYY-MM-DDTHH:mm'
              ).format('DD/MM/YY, HH:mm')}.</span>`
              : ''
            }`,
          }}
          name="language"
          onSelect={(key) => setReportLanguage(key)}
        />

        <Selector
          label="Date format"
          selected={dateFormat}
          options={dateList}
          name="language"
          onSelect={(key) => setDateFormat(key)}
        />

        <div className="d-flex flex-column gap-2">
          <p className="text-5">Select number format</p>

          <div className="d-flex gap-2">
            <button
              className={classNames('ReportInfoModal__Options', {
                active: 'dot' === numbersType,
              })}
              onClick={() => setNumbersType('dot')}
            >
              {'dot' === numbersType && (
                <ICircleCheck className="ReportInfoModal__Options_Icon text-surface-900" />
              )}
              <span className="text-5 fw-bold">10.000</span>
              <span className="text-6">Separated by dot</span>
            </button>
            <button
              className={classNames('ReportInfoModal__Options', {
                active: 'comma' === numbersType,
              })}
              onClick={() => setNumbersType('comma')}
            >
              {'comma' === numbersType && (
                <ICircleCheck className="ReportInfoModal__Options_Icon text-surface-900" />
              )}
              <span className="text-5 fw-bold">10,000</span>
              <span className="text-6">Separated by comma</span>
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center gap-2">
          <Button
            variant="white_black"
            className="w-100"
            onClick={() => closeModal()}
          >
            Go back to simulation
          </Button>
          <Button className="w-100" onClick={() => handleSubmit()}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  )
}
