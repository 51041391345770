import useReportContext from '../../hooks/useReportContext'
import Button from '../buttons/Button'


export default function ButtonPreviewReport() {
  const { showReport, handleToggleShowReport } = useReportContext()

  return (
    <Button className="GenerateReport_Actions_Preview" onClick={handleToggleShowReport}>
      {!showReport ? 'Open' : 'Close'}{' '}
      preview
    </Button>
  )
}
