import { CrossIcon, EditIcon } from '../../assets/icons'

interface props<T> {
  name: string;
  data: T[];
  handleEdit: (number) => void;
  openDeleteModal: (model) => void;
}

function TableManage({ name, data, handleEdit, openDeleteModal }: props<any>) {
  const getName = (item) => {
    switch (name) {
    case 'systems':
      return item.system_name
    case 'profiles':
      return item.name

    case 'models':
      return item.model_name

    case 'pv models':
      return item.name

    default:
      break
    }
  }

  return (
    <>
      {data?.length !== 0 ? (
        <div className="ManageTableModal">
          <div className="ManageTableModal__Item">
            <p className="text-6 fw-normal w-100 p-2">Name</p>
            <p className="text-6 fw-normal p-2">Edit</p>
            <p className="text-6 fw-normal p-2">Delete</p>
          </div>
          <div className="ManageTableModal overflow-auto Scroll_MS-SEC">
            {data.map((item) => {
              if((name == 'models' || name == 'pv models') && item.is_admin_creation) return (
                <div key={item.id} className="ManageTableModal__Item">
                  <p className="ManageTableModal__Item_Label text-6 text-secondary fw-normal p-2">
                    {getName(item)}
                  </p>
                </div>
              )
              else if(name == 'systems' || name == 'profiles') return (
                <div key={item.id} className="ManageTableModal__Item">
                  <p className="ManageTableModal__Item_Label text-6 text-secondary fw-normal p-2">
                    {getName(item)}
                  </p>
                  {!item.is_admin_creation && (
                    <>
                      <button className="ManageTableModal__Item_Button" onClick={() => handleEdit(item.id)}>
                        <EditIcon />
                      </button>
                      <button className="ManageTableModal__Item_Button" onClick={() => openDeleteModal(item)}>
                        <CrossIcon />
                      </button>
                    </>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <div className="text-5 fw-medium my-4">No {name} created yet</div>
      )}
    </>
  )
}

export default TableManage