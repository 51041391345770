import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import Input from '../Input'
import Modal from './Modal'

const SimulationModal = () => {
  const { toggleShowSimulationModal } = useAppContext()
  const { skip, newSimulation } = useNewSimulationContext()

  return (
    <Modal
      title='Simulation Modal'
      closeModal={toggleShowSimulationModal}
    >
      <div className="Modal__Scroll-Content">
        <SectionContainer>
          <h4 className="text-4 fw-medium">Simulation Data</h4>

          <InputsContainer>
            <div className="d-flex gap-1">
              <Input
                label="ID"
                name='test'
                value={newSimulation.id}
                disabled
              />

              <Input
                label="Skip Step"
                name='test'
                value={skip ? 'true' : 'false'}
                disabled
              />

              <Input
                label="Current Step"
                name='test'
                value={newSimulation.current_step}
                disabled
              />

              <Input
                label="Last Step Done"
                name='test'
                value={newSimulation.last_step}
                disabled
              />
            </div>
          </InputsContainer>
        </SectionContainer>

        <SectionContainer>
          <h4 className="text-4 fw-medium">General Information</h4>

          <InputsContainer>
            <Input
              label="Project Name"
              name='test'
              value={newSimulation.steps.general_information.name}
              disabled
            />

            <div className="d-flex gap-1">
              <Input
                label="Client ID"
                name='test'
                value={newSimulation.steps.general_information.client}
                disabled
              />
              <Input
                label="Currency Selected ID"
                name='test'
                value={newSimulation.steps.general_information.currency_selected}
                disabled
              />
            </div>
          </InputsContainer>
        </SectionContainer>


        <SectionContainer>
          <h4 className="text-4 fw-medium">Location Detail</h4>

          <InputsContainer>
            <div className="d-flex gap-1">
              <Input
                label="Country Region ID"
                name='test'
                value={newSimulation.steps.location_details.country_region}
                disabled
              />
              <Input
                label="State ID"
                name='test'
                value={newSimulation.steps.location_details.state}
                disabled
              />
            </div>

            <div className="d-flex gap-1">
              <Input
                label="City"
                name='test'
                value={newSimulation.steps.location_details.city}
                disabled
              />
              <Input
                label="Address"
                name='test'
                value={newSimulation.steps.location_details.address}
                disabled
              />
            </div>

            <div className="d-flex gap-1">
              <Input
                label="Postal Code"
                name='test'
                value={newSimulation.steps.location_details.postal_code}
                disabled
              />
              <Input
                label="Weather File"
                name='test'
                value={newSimulation.steps.location_details.weather_file}
                disabled
              />
            </div>
          </InputsContainer>
        </SectionContainer>

        <SectionContainer>
          <h4 className="text-4 fw-medium">Hot Water Demand</h4>

          <InputsContainer>
            <div className="d-flex gap-1">
              <Input
                label="Sector"
                name='test'
                value={newSimulation.steps.hot_water_demand.sector}
                disabled
              />
              <Input
                label="Daily Profile ID"
                name='test'
                value={newSimulation.steps.hot_water_demand.daily_profile}
                disabled
              />
            </div>

            <div className="d-flex gap-1">
              <Input
                label="Monday"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_mon}
                small
                disabled
              />
              <Input
                label="Tuesday"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_tue}
                small
                disabled
              />
              <Input
                label="Wednesday"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_wed}
                small
                disabled
              />
              <Input
                label="Thursday"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_thu}
                small
                disabled
              />
              <Input
                label="Friday"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_fri}
                small
                disabled
              />
              <Input
                label="Saturday"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_sat}
                small
                disabled
              />
              <Input
                label="Sunday"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_sun}
                small
                disabled
              />
            </div>

            <div className="d-flex gap-1">
              <Input
                label="Jan"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_jan}
                small
                disabled
              />
              <Input
                label="Feb"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_feb}
                small
                disabled
              />
              <Input
                label="Mar"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_mar}
                small
                disabled
              />
              <Input
                label="Apr"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_apr}
                small
                disabled
              />
              <Input
                label="May"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_may}
                small
                disabled
              />
              <Input
                label="Jun"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_jun}
                small
                disabled
              />
              <Input
                label="Jul"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_jul}
                small
                disabled
              />
              <Input
                label="Aug"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_aug}
                small
                disabled
              />
              <Input
                label="Sep"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_sep}
                small
                disabled
              />
              <Input
                label="Oct"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_oct}
                small
                disabled
              />
              <Input
                label="Nov"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_nov}
                small
                disabled
              />
              <Input
                label="Dec"
                name='test'
                value={newSimulation.steps.hot_water_demand.f_dec}
                small
                disabled
              />
            </div>

            <div className="d-flex gap-1">
              <Input
                label="Maximum Hot Water Demand"
                name='test'
                value={newSimulation.steps.hot_water_demand.maximum_hot_water_demand}
                disabled
              />
              <Input
                label="Desired Hot Water Temperature"
                name='test'
                value={newSimulation.steps.hot_water_demand.desired_hot_water_temperature}
                disabled
              />
            </div>

            <div className="d-flex gap-1">
              <Input
                label="Is Recirculation"
                name='test'
                value={newSimulation.steps.hot_water_demand.is_recirculation}
                disabled
              />
              <Input
                label="Water Temperature"
                name='test'
                value={newSimulation.steps.hot_water_demand.water_temperature}
                disabled
              />
            </div>
          </InputsContainer>
        </SectionContainer>

        <SectionContainer>
          <h4 className="text-4 fw-medium">Electricity Demand</h4>

          <InputsContainer>
            <div className="d-flex gap-1">
              <Input
                label="Supply Choice"
                name='test'
                value={newSimulation.steps.electricity_demand.supply_choices}
                disabled
              />
              <Input
                label="Annual Demand"
                name='test'
                value={newSimulation.steps.electricity_demand.annual_demand}
                disabled
              />
            </div>

            <div className="d-flex gap-1">
              <Input
                label="January"
                name='test'
                value={newSimulation.steps.electricity_demand.fel_jan}
                small
                disabled
              />
              <Input
                label="Febraury"
                name='test'
                value={newSimulation.steps.electricity_demand.fel_feb}
                small
                disabled
              />
              <Input
                label="March"
                name='test'
                value={newSimulation.steps.electricity_demand.fel_mar}
                small
                disabled
              />
              <Input
                label="April"
                name='test'
                value={newSimulation.steps.electricity_demand.fel_apr}
                small
                disabled
              />
              <Input
                label="May"
                name='test'
                value={newSimulation.steps.electricity_demand.fel_may}
                small
                disabled
              />
              <Input
                label="June"
                name='test'
                value={newSimulation.steps.electricity_demand.fel_jun}
                small
                disabled
              />
            </div>

            <div className="d-flex gap-1">
              <Input
                label="July"
                name='test'
                value={newSimulation.steps.electricity_demand.fel_jul}
                small
                disabled
              />
              <Input
                label="August"
                name='test'
                value={newSimulation.steps.electricity_demand.fel_aug}
                small
                disabled
              />
              <Input
                label="September"
                name='test'
                value={newSimulation.steps.electricity_demand.fel_sep}
                small
                disabled
              />
              <Input
                label="October"
                name='test'
                value={newSimulation.steps.electricity_demand.fel_oct}
                small
                disabled
              />
              <Input
                label="November"
                name='test'
                value={newSimulation.steps.electricity_demand.fel_nov}
                small
                disabled
              />
              <Input
                label="December"
                name='test'
                value={newSimulation.steps.electricity_demand.fel_dec}
                small
                disabled
              />
            </div>
          </InputsContainer>
        </SectionContainer>
      </div>
    </Modal>
  )
}

const SectionContainer = ({ children }) => (
  <>
    <div className="d-flex flex-column gap-2 py-2">
      { children }
    </div>

    <div className="Modal__Divider--MS-SEC"></div>
  </>
)

const InputsContainer = ({ children }) => (
  <div className="d-flex flex-column gap-1">
    { children }
  </div>
)

export default SimulationModal