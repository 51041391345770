import { ChangeEventHandler, useState } from 'react'
import { Region } from '../api/apiTypes'
import { ISearch } from '../iconComponents'
import { TableHeaders } from '../types'
import Input from './Input'
import EditRegionModal from './modals/EditRegionModal'
import NewRegionModal from './modals/NewRegionModal'
import Table from './tables/Table'
import TableRegions from './tables/TableRegions'
import TableTitle from './TableTitle'
import useOrderby from '../hooks/useOrderby'
import { useQuery } from 'react-query'
import { getOrganizationRegions } from '../api/organizations'
import useAppContext from '../hooks/useAppContext'
import TableFiltersMobile from './TableFiltersMobile'
import useMatchMedia from '../hooks/useMatchMedia'
import useTable from '../hooks/useTable'
import DeleteRegionModal from './modals/DeleteRegionModal'
import Button from './buttons/Button'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  organizationId: number;
}

const ShowRegions = ({ organizationId, ...restProps }: Props) => {
  const { token } = useAppContext()
  const [search, setSearch] = useState('')
  const [showNewRegionModal, setShowNewRegionModal] = useState(false)
  const [showEditRegionModal, setShowEditRegionModal] = useState(false)
  const [showDeleteRegionModal, setShowDeleteRegionModal] = useState(false)
  const [regionInfo, setRegionInfo] = useState<Region>(null)
  const isMobile = useMatchMedia()
  const { orderBy, sort, handleSort } = useOrderby('name')
  const { data, isFetching, refetch } = useQuery(
    ['getOrganizationRegions', search, orderBy, organizationId],
    () =>
      getOrganizationRegions(token, organizationId, {
        search,
        order_by: orderBy,
      }),
    {
      initialData: { data: [] },
      enabled: token !== '',
      refetchOnWindowFocus: false,
    }
  )
  const { selectedItems, handleSelectItem, handleSelectAll } = useTable({
    tableData: data.data ? data.data : [],
  })

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value)
  }

  const openShowNewRegionModal = () => setShowNewRegionModal(true)

  const closeShowNewRegionModal = () => setShowNewRegionModal(false)

  const openShowEditRegionModal = (region: Region) => {
    setRegionInfo(region)
    setShowEditRegionModal(true)
  }

  const closeShowEditRegionModal = () => setShowEditRegionModal(false)

  const openShowDeleteRegionModal = () => {
    setRegionInfo(selectedItems[0])
    setShowDeleteRegionModal(true)
  }

  const closeShowDeleteRegionModal = () => setShowDeleteRegionModal(false)

  const tableHeaders: TableHeaders[] = [
    {
      id: 0,
      label: 'Region',
      value: 'name',
    },
    {
      id: 1,
      label: 'Admins',
      value: '',
    },
    {
      id: 2,
      label: 'Countries',
      value: '',
    },
    {
      id: 3,
      label: 'Edit',
      value: '',
    },
  ]

  return (
    <div {...restProps}>
      <div>
        <Input
          name="Search"
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          icon={<ISearch className="Input__Icon" />}
        />
      </div>

      <div className="Dashboard__Filters_Container">
        <p className="text-4 fw-medium">Regions</p>
        <div className="Dashboard__Filters_Container_Mobile d-flex justify-between flex-wrap d-md-none pr-1 w-100">
          <TableFiltersMobile
            headers={tableHeaders}
            sort={sort}
            handleSort={handleSort}
          />
          <TableTitle
            totalItems={data.data.length}
            itemsSelected={selectedItems.length}
            mobile
          />
          <button
            className="button_red text-5"
            onClick={openShowDeleteRegionModal}
            disabled={selectedItems.length !== 1}
          >
            Delete
          </button>
          <Button
            className="ms-auto text-5"
            onClick={openShowNewRegionModal}
          >
            New region
          </Button>
        </div>
        {/* <TableFilters
          filtersSelected={filtersSelected}
          setFiltersSelected={setFiltersSelected}
          filtersGroup={filtersGroup}
        /> */}
        <TableTitle totalItems={data.data?.length} />
        <button
          className="button_red text-5 d-none d-md-flex"
          onClick={openShowDeleteRegionModal}
          disabled={selectedItems.length !== 1}
        >
          Delete
        </button>
        <Button
          className="ms-auto d-none d-md-flex"
          onClick={openShowNewRegionModal}
        >
          New region
        </Button>
      </div>

      <Table
        type="Regions"
        handleSelectAll={handleSelectAll}
        selectedItemsLength={selectedItems.length}
        headers={tableHeaders}
        dataLength={data.data?.length}
        loading={isFetching || token === ''}
        sort={sort}
        handleSort={handleSort}
      >
        {data.data?.map((item) => (
          <TableRegions
            key={item.id}
            data={item}
            selected={selectedItems.some(
              (itemSelect) => itemSelect?.id === item.id
            )}
            handleSelectItem={handleSelectItem}
            action={openShowEditRegionModal}
            isMobile={isMobile}
          />
        ))}
      </Table>

      {showNewRegionModal ? (
        <NewRegionModal
          organizaionId={organizationId}
          refetch={refetch}
          closeModal={closeShowNewRegionModal}
        />
      ) : (
        <></>
      )}

      {showEditRegionModal ? (
        <EditRegionModal
          organizaionId={organizationId}
          regionInfo={regionInfo}
          refetch={refetch}
          closeModal={closeShowEditRegionModal}
        />
      ) : (
        <></>
      )}

      {showDeleteRegionModal ? (
        <DeleteRegionModal
          regionInfo={regionInfo}
          refetch={refetch}
          closeModal={closeShowDeleteRegionModal}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default ShowRegions
