import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { IAngleUp } from '../iconComponents'
import LoadingDots from './LoadingDots'
import Button from './buttons/Button'

interface Props {
  step: number;
  totalSteps: number;
  handleBack: () => void;
  backLabel?: string;
  handleNext: () => void;
  nextLabel?: string;
  nextDisabled?: boolean;
  nextIsLoading?: boolean;
  nextLoadingLabel?: string;
}

const NavigateSteps = ({
  step,
  totalSteps,
  handleBack,
  backLabel = 'Back',
  handleNext,
  nextLabel = 'Next',
  nextDisabled = false,
  nextIsLoading = false,
  nextLoadingLabel = 'transfering',
}: Props) => {
  return (
    <div className="Dashboard__Table_path_info_Steps">
      <CSSTransition in={step !== 1 && step !== totalSteps} timeout={200}>
        <Button
          variant='gray'
          className={classNames('button  button_defult', {
            button__Hidden: step === 1 || step === totalSteps,
          })}
          onClick={handleBack}
          // disabled={step === 1 || step === totalSteps}
        >
          <IAngleUp width={20} height={20} className="-rotate-90" />
          <p className="text-4 text-regular">{backLabel}</p>
        </Button>
      </CSSTransition>
      <StepsDots totalCount={totalSteps - 1} currentCount={step} show />
      <Button
        className="button_defult"
        onClick={handleNext}
        disabled={nextDisabled}
      >
        {step === totalSteps ? (
          <p className="text-4 text-regular text-white">Go back to panel</p>
        ) : nextIsLoading ? (
          <>
            <p>{nextLoadingLabel}</p>
            <LoadingDots className="enter-done" />
          </>
        ) : (
          <>
            <p className="text-4 text-regular text-white">{nextLabel}</p>
            <IAngleUp width={20} height={20} className="rotate-90" />
          </>
        )}
      </Button>
    </div>
  )
}

export default NavigateSteps

export const StepsDots = ({ totalCount, currentCount, show =false }) => {
  const [totalCountArray, setTotalCountArray] = useState([])

  useEffect(() => {
    const array = []
    for (let i = 0; i < totalCount; i++) {
      array.push(i + 1)
    }
    setTotalCountArray(array)
  }, [totalCount])

  return (
    <div className="TransferSimulation__Steps_Dots_Container">
      {totalCountArray.map((number) => (
        <div
          key={number}
          className={classNames(
            'Radio',
            { Show: show},
            { Selected: currentCount === number },
            { Complete: currentCount > number },
          )}
        />
      ))}
    </div>
  )
}
