import { useState } from 'react'
import { deleteDailyProfile } from '../../api/simulations/input-parameters/hot-water-demand'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import useModalsContext from '../../hooks/useModalsContext'
import Modal from './Modal'
import TableManage from '../tables/TableManage'
import Button from '../buttons/Button'

const ManageProfilesModal = () => {
  const { token } = useAppContext()
  const { waterProfiles, getExtraData } = useNewSimulationContext()
  const { setModalId, toggleShowManageProfilesModal, openShowNewProfileModal } =
    useModalsContext()
  const [showDeleteProfile, setShowDeleteProfile] = useState(false)
  const [profileToDelete, setProfileToDelete] = useState(null)

  const handleEdit = (id: number) => {
    toggleShowManageProfilesModal()
    setModalId(id)
    openShowNewProfileModal()
  }

  const openshowDeleteProfile = (profile) => {
    setProfileToDelete(profile)
    setShowDeleteProfile(true)
  }

  const handleDelete = async () => {
    const response = await deleteDailyProfile(token, profileToDelete.id)

    if (response.error) console.error(response.error)
    else if (response) getExtraData()

    setShowDeleteProfile(false)
  }

  return (
    <Modal title="Manage profiles" closeModal={toggleShowManageProfilesModal}>
      <div className="Modal__Scroll-Content d-flex flex-column align-items-center py-2">
        <Button
          variant="MS-SEC"
          onClick={() => {
            toggleShowManageProfilesModal()
            openShowNewProfileModal()
          }}
        >
          + Add new profile
        </Button>

        <TableManage
          name="profiles"
          data={waterProfiles}
          handleEdit={handleEdit}
          openDeleteModal={openshowDeleteProfile}
        />
      </div>
      {showDeleteProfile ? (
        <Modal
          title="Delete profile"
          closeModal={() => setShowDeleteProfile(false)}
        >
          <div className="d-flex flex-column justify-content-center align-items-center  p-5">
            <p className="text-center">
              Are you sure you want to delete the{' '}
              <span className="fw-medium">{profileToDelete.name}</span> profile?
            </p>
          </div>
          <Button className="mx-auto w-100" onClick={handleDelete}>
            Delete
          </Button>
        </Modal>
      ) : (
        <></>
      )}
    </Modal>
  )
}

export default ManageProfilesModal
