import Button from './buttons/Button'
import { EditIcon } from '../assets/icons'
import classNames from 'classnames'

interface Props {
  setEdit: (prev: any) => void;
  available?: boolean;
  className?: string;
}

const EditButton = ({ setEdit, available = true, className }: Props) => {
  const toggleEdit = () => available && setEdit((prev) => !prev)

  return (
    <Button
      variant="MS-SEC"
      className={classNames('px-3', className)}
      style={{ width: 'max-content'}}
      onClick={toggleEdit}
      disabled={!available}
    >
      <EditIcon />
      <span style={{
        width: 6,
        height: 6,
        borderRadius: 6, 
        backgroundColor: 'currentcolor'
      }} />
      <span className="text-6">Edit marked parameters</span>
    </Button>
  )
}

export default EditButton
