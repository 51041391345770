import { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import {
  faArrowRightFromBracket,
  faBars,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAppContext from '../hooks/useAppContext'
import AccountInfoModal from '../components/modals/AccountInfoModal'
import Logo from '../assets/images/logo.png'
import LogoutModal from '../components/modals/LogoutModal'
import { CSSTransition } from 'react-transition-group'
import useNewSimulationContext from '../hooks/useNewSimulationContext'
import useModalsContext from '../hooks/useModalsContext'
import useMatchMedia from '../hooks/useMatchMedia'
import Button from '../components/buttons/Button'
import AccountActions from '../components/AccountActions'

interface Props {
  handleErrorBoundary?: () => void;
}

const DashboardHeader = ({ handleErrorBoundary }: Props) => {
  const {
    user,
    isCentralAdmin,
    isOrganizationAdmin,
    isRegionAdmin,
    toggleShowNewClientModal,
  } = useAppContext()
  const { setShowContinueSimulationModal } = useModalsContext()
  const { newSimulation, resetNewSimulation } = useNewSimulationContext()
  const [showAccountInfoModal, setShowAccountInfoModal] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [showMenuMobile, setShowMenuMobile] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isMedia = useMatchMedia(
    isCentralAdmin
      ? '(max-width: 1199px)'
      : isOrganizationAdmin || isRegionAdmin
        ? '(max-width: 991px)'
        : '(max-width: 767px)'
  )

  useEffect(() => {
    if (!isMedia) setShowMenuMobile(false)
  }, [isMedia])

  const toggleAccountInfoModal = () => setShowAccountInfoModal((prev) => !prev)

  const toggleShowLogoutModal = () => setShowLogoutModal((prev) => !prev)

  const toggleShowMenuMobile = () => setShowMenuMobile((prev) => !prev)

  const closeShowMenuMobile = () => setShowMenuMobile(false)

  const handleMobileClick = () => {
    closeShowMenuMobile()
    if (handleErrorBoundary) handleErrorBoundary()
  }

  const goToCreateNewSimulation = () => {
    if (!pathname.includes('step')) {
      setShowContinueSimulationModal(false)
      resetNewSimulation()
      navigate('/dashboard/create-simulation/step/general-information')
    } else if (newSimulation.id) {
      setShowContinueSimulationModal(true)
    }
    if (isMedia) closeShowMenuMobile()
  }

  return (
    <>
      <header
        className={classNames('DashboardHeader', {
          isMedia: isMedia,
        })}
      >
        <NavLink to="/" onClick={handleErrorBoundary} title="Home">
          <img
            src={Logo}
            alt="Makt logo"
            className="DashboardHeader__Logo"
          />
        </NavLink>

        <nav className={'DashboardHeader__Nav'}>
          <Button
            size="s"
            variant='black'
            active={pathname.includes('step')}
            onClick={goToCreateNewSimulation}
            title="New Simulation"
          >
            New Simulation
          </Button>
          <Link
            path="manage-simulations"
            name="Manage simulations"
            onClick={handleErrorBoundary}
          />

          {isCentralAdmin ? (
            <Link
              path="parameters"
              name="Parameters"
              onClick={handleErrorBoundary}
            />
          ) : (
            <></>
          )}
          {isCentralAdmin || isOrganizationAdmin || isRegionAdmin ? (
            <Link path="users" name="Users" onClick={handleErrorBoundary} />
          ) : (
            <></>
          )}

          <Link path="clients" name="Clients" onClick={handleErrorBoundary} />

          {isCentralAdmin ? (
            <Link
              path="organizations"
              name="Organizations"
              onClick={handleErrorBoundary}
            />
          ) : isOrganizationAdmin ? (
            <Link
              path="regions"
              name="Offices & regions"
              onClick={handleErrorBoundary}
            />
          ) : (
            <></>
          )}

          {isCentralAdmin || isOrganizationAdmin || isRegionAdmin ? (
            <>
              <Link
                path="statistics"
                name="Statistics"
                onClick={handleErrorBoundary}
              />
              <Link path="logs" name="Logs" onClick={handleErrorBoundary} />
            </>
          ) : (
            <></>
          )}

          <AccountActions />
        </nav>
        <FontAwesomeIcon
          icon={faBars}
          className="DashboardHeader__Menu_Mobile_Icon"
          onClick={toggleShowMenuMobile}
        />
      </header>

      <CSSTransition in={showMenuMobile && isMedia} timeout={400} unmountOnExit>
        <div className="DashboardHeader__Menu_Mobile">
          <div className="DashboardHeader__Menu_Mobile_Content Scroll_MS-SEC">
            <div className="DashboardHeader__Menu_Mobile_User_info__BB">
              <p className="DashboardHeader__Menu_Mobile_User_info_Name text-3 fw-medium text-primary">
                {user?.first_name}
              </p>
              <button
                className="DashboardHeader__Menu_Mobile_User_info_Button text-4"
                onClick={toggleAccountInfoModal}
              >
                Account
              </button>
            </div>
            <nav className="DashboardHeader__Menu_Mobile_Nav">
              <Button
                size='l'
                active={pathname.includes('step')}
                onClick={goToCreateNewSimulation}
                title="New Simulation"
              >
                New Simulation
              </Button>
              <Link
                m
                path="manage-simulations"
                name="Manage simulations"
                onClick={handleMobileClick}
              />

              {isCentralAdmin ? (
                <Link
                  m
                  path="parameters"
                  name="Parameters"
                  onClick={handleMobileClick}
                />
              ) : (
                <></>
              )}
              {isCentralAdmin || isOrganizationAdmin || isRegionAdmin ? (
                <Link m path="users" name="Users" onClick={handleMobileClick} />
              ) : (
                <></>
              )}

              <Link
                m
                path="clients"
                name="Clients"
                onClick={handleMobileClick}
              />

              {isCentralAdmin ? (
                <Link
                  m
                  path="organizations"
                  name="Organizations"
                  onClick={handleMobileClick}
                />
              ) : isOrganizationAdmin ? (
                <Link
                  m
                  path="regions"
                  name="Offices & regions"
                  onClick={handleMobileClick}
                />
              ) : (
                <></>
              )}

              {isCentralAdmin || isOrganizationAdmin || isRegionAdmin ? (
                <>
                  <Link
                    m
                    path="statistics"
                    name="Statistics"
                    onClick={handleMobileClick}
                  />
                  <Link m path="logs" name="Logs" onClick={handleMobileClick} />
                </>
              ) : (
                <></>
              )}

              <div
                className="DashboardHeader__Link text-3"
                onClick={toggleShowNewClientModal}
              >
                Create new client
              </div>
            </nav>
            <div className="DashboardHeader__Menu_Mobile_User_info__BT">
              <Button size='l' variant='transparent' onClick={toggleShowLogoutModal}>
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  onClick={toggleShowMenuMobile}
                />
                <span>Log out</span>
              </Button>
            </div>
          </div>
        </div>
      </CSSTransition>

      {showAccountInfoModal && (
        <AccountInfoModal closeModal={toggleAccountInfoModal} />
      )}

      {showLogoutModal && <LogoutModal closeModal={toggleShowLogoutModal} />}
    </>
  )
}

export default DashboardHeader

export const Link = ({
  path,
  name,
  onClick,
  m = false,
}: {
  path: string;
  name: string;
  onClick?: () => void;
  m?: boolean;
}) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  if (m)
    return (
      <button
        className={classNames(
          'DashboardHeader__Link',
          {
            Active: pathname.includes(path),
          },
          'text-3'
        )}
        onClick={() => {
          navigate(`/dashboard/${path}`)
          onClick()
        }}
        title={name}
      >
        {name}
      </button>
    )

  return (
    <NavLink
      to={`/dashboard/${path}`}
      className={({ isActive }) =>
        classNames(
          'DashboardHeader__Link',
          {
            Active: isActive,
          },
          'text-5'
        )
      }
      onClick={onClick}
      title={name}
    >
      {name}
    </NavLink>
  )
}
