import { Helmet } from 'react-helmet'
import { ChangeEventHandler, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Input from '../../components/Input'
import TableFilters from '../../components/TableFilters'
import TableFiltersMobile from '../../components/TableFiltersMobile'
import Table from '../../components/tables/Table'
import TableTitle from '../../components/TableTitle'
import useAppContext from '../../hooks/useAppContext'
import { getClients } from '../../api/clients'
import { FiltersGroup, FiltersSelected, TableHeaders } from '../../types'
import { TableClients } from '../../components/tables/TableClients'
import ClientInfoModal from '../../components/modals/ClientInfoModal'
import { Client } from '../../api/apiTypes'
import useOrderby from '../../hooks/useOrderby'
import { emptyClients } from '../../constants'
import { ISearch } from '../../iconComponents'
import useMatchMedia from '../../hooks/useMatchMedia'
import EditClientModal from '../../components/modals/EditClientModal'
import DeleteClienteModal from '../../components/modals/DeleteClienteModal'
import Button from '../../components/buttons/Button'

const Clients = () => {
  const { token, isCentralAdmin, toggleShowNewClientModal, clients } = useAppContext()
  const [page, setPage] = useState(1)
  const perPage = 20
  const [search, setSearch] = useState('')
  const { orderBy, sort, handleSort } = useOrderby('name')
  const [filtersSelected, setFiltersSelected] = useState<FiltersSelected[]>([
    // {
    //   label: 'City',
    //   search: '',
    //   defaultOption: {
    //     label: 'All',
    //     value: '',
    //   },
    //   option: {
    //     label: 'All',
    //     value: '',
    //   },
    // },
    {
      label: 'N° of Simulations',
      defaultOption: {
        label: 'All',
        value: { sim_lower: null, sim_upper: null },
      },
      option: {
        label: 'All',
        value: { sim_lower: null, sim_upper: null },
      },
    },
  ])

  const params = {
    page,
    per_page: perPage,
    search,
    order_by: orderBy,
    sim_lower:
      typeof filtersSelected[0].option.value === 'object'
        ? filtersSelected[0].option.value.sim_lower
        : null,
    sim_upper:
      typeof filtersSelected[0].option.value === 'object'
        ? filtersSelected[0].option.value.sim_upper
        : null,
  }
  const { data, refetch, isFetching } = useQuery(
    ['getClients', page, search, orderBy, filtersSelected],
    () => getClients(token, params),
    {
      initialData: emptyClients,
      enabled: token !== '',
      refetchOnWindowFocus: false,
    }
  )
  const [clientSelected, setClientSelected] = useState<Client>(null)
  const [showClientInfoModal, setShowClientInfoModal] = useState(false)
  const [showEditClientModal, setShowEditClientModal] = useState(false)
  const [showDeleteClienteModal, setShowDeleteClienteModal] = useState(false)
  const isMobile = useMatchMedia()

  useEffect(() => {
    refetch()
  }, [clients])

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPage(1)
    setSearch(event.target.value)
  }

  const openShowClientInfoModal = (info: Client) => {
    setClientSelected(info)
    setShowClientInfoModal(true)
  }

  const closeShowClientInfoModal = () => setShowClientInfoModal(false)

  const openShowEditClientModal = (info: Client) => {
    setClientSelected(info)
    setShowEditClientModal(true)
  }

  const closeShowEditClientModal = () => setShowEditClientModal(false)

  const openShowDeleteClienteModal = (info: Client) => {
    setClientSelected(info)
    setShowDeleteClienteModal(true)
  }

  const closeShowDeleteClienteModal = () => setShowDeleteClienteModal(false)

  const filtersGroup: FiltersGroup[] = [
    // {
    //   label: 'City',
    //   options: [
    //     {
    //       label: 'Córdoba',
    //       value: 'cba',
    //     },
    //   ],
    // },
    {
      label: 'N° of Simulations',
      options: [
        {
          label: '0 - 10',
          value: { sim_lower: 0, sim_upper: 10 },
        },
        {
          label: '10 - 30',
          value: { sim_lower: 10, sim_upper: 30 },
        },
        {
          label: '30 - 50',
          value: { sim_lower: 30, sim_upper: 50 },
        },
        {
          label: '50 +',
          value: { sim_lower: 50, sim_upper: 999999999 },
        },
      ],
    },
  ]

  const tableHeaders: TableHeaders[] = [
    {
      id: 0,
      label: 'Name',
      value: 'name',
    },
    {
      id: 2,
      label: 'Location',
      value: 'country',
    },
    {
      id: 3,
      label: 'Simulations',
      value: 'simulation_count',
    },
    {
      id: 4,
      label: 'Email',
      value: 'email',
    },
    {
      id: 5,
      label: 'Phone number',
      value: 'phone',
    },
    {
      id: 6,
      label: 'Address',
      value: 'address',
    },
    {
      id: 7,
      label: 'Actions',
      value: '',
    },
  ]

  if (isCentralAdmin)
    tableHeaders.splice(1, 0, {
      id: 1,
      label: 'Organization',
      value: 'organization',
    })

  return (
    <>
      <Helmet>
        <title>Makt: Clients</title>
      </Helmet>

      <section className="Dashboard">
        <div>
          <Input
            name="Search"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            icon={<ISearch className="Input__Icon" />}
          />
        </div>

        <div className="Dashboard__Filters_Container">
          <div className="Dashboard__Filters_Container_Mobile d-flex justify-between d-md-none w-100 pr-1">
            <TableFiltersMobile
              filtersSelected={filtersSelected}
              setFiltersSelected={setFiltersSelected}
              filtersGroup={filtersGroup}
              headers={tableHeaders}
              sort={sort}
              handleSort={handleSort}
            />
            <Button
              onClick={() => {toggleShowNewClientModal()}}
            >
              New client
            </Button>
          </div>

          <TableFilters
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            filtersGroup={filtersGroup}
          />
          <TableTitle totalItems={data.data.length} />
          <Button
            className="d-none d-md-flex ms-auto"
            onClick={() => {toggleShowNewClientModal()}}
          >
            New client
          </Button>
        </div>
        <Table
          type={isCentralAdmin ? 'Clients_Extended' : 'Clients'}
          headers={tableHeaders}
          dataLength={data.data.length}
          loading={isFetching || token === ''}
          sort={sort}
          handleSort={handleSort}
          page={page}
          setPage={setPage}
          perPage={perPage}
          totalPages={data.total_pages}
          totalItems={data.total_items}
        >
          {data.data.map((item) => (
            <TableClients
              key={item.id}
              data={item}
              openInfo={openShowClientInfoModal}
              openEdit={openShowEditClientModal}
              openDelete={openShowDeleteClienteModal}
              unselectable
              isMobile={isMobile}
            />
          ))}
        </Table>
      </section>

      {showClientInfoModal ? (
        <ClientInfoModal
          info={clientSelected}
          closeModal={closeShowClientInfoModal}
        />
      ) : (
        <></>
      )}

      {showEditClientModal ? (
        <EditClientModal
          refetch={refetch}
          clientInfo={clientSelected}
          closeModal={closeShowEditClientModal}
        />
      ) : (
        <></>
      )}

      {showDeleteClienteModal ? (
        <DeleteClienteModal
          refetch={refetch}
          clientInfo={clientSelected}
          closeModal={closeShowDeleteClienteModal}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default Clients
