import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { SectionTable } from '../../types/generateReport'

const styles = StyleSheet.create({
  container: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    overflow: 'hidden',
  },
  column: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 44,
    padding: 4,
    backgroundColor: '#212121',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 20,
    padding: 4,
    backgroundColor: '#FFFFFF',
  },
  item2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 20,
    padding: 4,
    backgroundColor: '#EEEEEE',
  },
  text: {
    fontSize: 10,
    fontFamily: 'Roboto',
    fontWeight: 400,
    color: '#252525',
    textAlign: 'center',
    // textOverflow: 'ellipsis',
  },
})

type Props = {
  section: SectionTable;
};

export default function TableRender({ section }: Props) {
  const ActiveColumns = section.value.filter(column => column.show).length

  return (
    <View
      style={{
        ...styles.container,
        height: 44 + (section.value.at(0).list.length * 22.69),
      }}
      render={() =>
        section.value.map((column) => {
          if (!column.show) return null
          return (
            <View
              key={column.id}
              style={{
                ...styles.column,
                flexBasis: 531.28 / ActiveColumns
              }}
            >
              <View style={styles.header}>
                <Text style={{...styles.text, width: (531.28 / ActiveColumns) - 8, color: '#FFFFFF',}}>{column.title}</Text>
              </View>
              {column.list.map((item, index) => (
                <View
                  key={column.id + index}
                  style={index % 2 === 0 ? styles.item : styles.item2}
                >
                  <Text style={styles.text}>{item || '0'}</Text>
                </View>
              ))}
            </View>
          )
        })
      }
      wrap={false}
    />
  )
}
