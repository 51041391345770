// import { useSortable } from '@dnd-kit/sortable'
// import { CSS } from '@dnd-kit/utilities'
import { useMemo } from 'react'
import Switch from './Switch'
import { Section } from '../types/generateReport'
import useReportContext from '../hooks/useReportContext'
import ImageCard from './GenerateReport/ImageCard'
import ImageGaleryCard from './GenerateReport/ImageGaleryCard'
import ListInfoCard from './GenerateReport/ListInfoCard'
import GoalCard from './GenerateReport/GoalCard'
import GridCard from './GenerateReport/GridCard'
import TableCard from './GenerateReport/TableCard'
import ImageLibraryCard from './GenerateReport/ImageLibraryCard'
import InputDebounce from './InputDebounce'
import InputTextDebounce from './InputTextDebounce'
import ExpirationDateCard from './GenerateReport/ExpirationDateCard'

type Props = {
  section: Section;
  footer?: boolean;
};

export default function PageInfoCard({ section, footer }: Props) {
  const { handleToggleSection, handleUpdateText } = useReportContext()

  // const { attributes, listeners, setNodeRef, transform, transition } =
  //   useSortable({ id: section.id })

  // const style: React.CSSProperties = {
  //   transform: CSS.Translate.toString(transform),
  //   transition,
  // }

  const content = useMemo(() => {
    if (
      section.type === 'text' ||
      section.type === 'text_title' ||
      section.type === 'text_link'
    ) {
      return (
        <InputDebounce
          name={section.id}
          placeholder={section.placeholder}
          value={section.value}
          // innerRight={
          //   <label htmlFor={section.id} className="text-5 text-secondary">
          //     0/40
          //   </label>
          // }
          onDebounce={(value) =>
            handleUpdateText(
              section.id,
              value
            )
          }
        />
      )
    } else if (section.type === 'text_special' || section.type === 'text_conf' ) {
      return (
        <InputTextDebounce
          placeholder={section.placeholder}
          value={section.value}
          onDebounce={(value) =>
            handleUpdateText(
              section.id,
              value
            )
          }
        />
      )
    } else if (section.type === 'image') {
      return <ImageCard section={section} footer={footer} />
    } else if (
      section.type === 'image_select' ||
      section.type === 'image_logo'
    ) {
      return <ImageLibraryCard section={section} footer={footer} />
    } else if (section.type === 'image_galery') {
      return <ImageGaleryCard section={section} />
    } else if (section.type === 'list_info' || section.type === 'contact') {
      return <ListInfoCard section={section} />
    } else if (section.type === 'goal') {
      return <GoalCard section={section} />
    } else if (section.type === 'grid') {
      return <GridCard section={section} />
    } else if (section.type === 'table') {
      return <TableCard section={section} />
    }
    else if (section.type === 'expiration_date') {
      return <ExpirationDateCard section={section} />
    }
    return null
  }, [section])

  return (
    <div
      className="PageInfo__Card"
      // ref={setNodeRef}
      // style={style}
      // {...attributes}
    >
      <div className="PageInfo__Card_Header">
        {/* <IMoveSub
          width={24}
          height={24}
          className="PageInfo__Card_Icon"
          {...listeners}
        /> */}
        <p className="PageInfo__Card_Header_Text">{section.title}</p>
        {typeof section.active === 'boolean' && (
          <Switch
            active={section.active}
            onClick={() => handleToggleSection(section.id)}
          />
        )}
      </div>

      {content}
    </div>
  )
}
