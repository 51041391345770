import { SVGProps, memo } from 'react'
const PaperIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="currentColor"
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      fill="#ffffff"
      d="M8.214 6.786a.714.714 0 0 0 0 1.428V6.786Zm2.857 1.428a.714.714 0 1 0 0-1.428v1.428Zm-2.857 3.572a.715.715 0 0 0 0 1.428v-1.428Zm8.571 1.428a.714.714 0 1 0 0-1.428v1.428Zm-8.571 1.429a.714.714 0 1 0 0 1.428v-1.428Zm8.571 1.428a.714.714 0 1 0 0-1.428v1.428ZM8.214 17.5a.715.715 0 0 0 0 1.429V17.5Zm2.857 1.429a.714.714 0 1 0 0-1.429v1.429Zm-4.286-15h11.429V2.5H6.785v1.429Zm12.143.714v15.714h1.429V4.643h-1.429Zm-.714 16.428H6.785V22.5h11.429v-1.429Zm-12.143-.714V4.643H4.643v15.714H6.07Zm.714.714a.714.714 0 0 1-.714-.714H4.643A2.143 2.143 0 0 0 6.785 22.5v-1.429Zm12.143-.714a.714.714 0 0 1-.714.714V22.5a2.143 2.143 0 0 0 2.143-2.143h-1.429ZM18.214 3.93a.715.715 0 0 1 .714.714h1.429A2.143 2.143 0 0 0 18.214 2.5v1.429ZM6.785 2.5a2.143 2.143 0 0 0-2.142 2.143H6.07a.714.714 0 0 1 .714-.714V2.5Zm1.429 5.714h2.857V6.786H8.214v1.428Zm0 5h8.571v-1.428H8.214v1.428Zm0 2.857h8.571v-1.428H8.214v1.428Zm0 2.858h2.857V17.5H8.214v1.429Z"
    />
    <path
      fill="#ffffff"
      stroke="#ffffff"
      d="M16.786 7.857a.357.357 0 1 0 0-.714.357.357 0 0 0 0 .714Z"
    />
  </svg>
)
export default memo(PaperIcon)
