import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { createHotWaterSystem, getHotWaterSystemDetails, updateHotWaterSystem } from '../../api/simulations/input-parameters/existing-hot-water-system-details'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import useModalsContext from '../../hooks/useModalsContext'
import Input from '../Input'
import Modal from './Modal'
import CheckModal from './CheckModal'
import { normalizeNumberWithCommas, normalizePercentToSend, normalizePercentToShow, threeDecimalRegex } from '../../constants'
import LoadingDots from '../LoadingDots'
import Button from '../buttons/Button'

const NewSystemModal = () => {
  const { token } = useAppContext()
  const { setWaterSystems } = useNewSimulationContext()
  const { modalId, closeShowNewSystemModal, toggleShowManageSystemModal } = useModalsContext()
  const [step, setStep] = useState(1)
  const [serverError, setServerError] = useState(false)

  const yupNumberValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 0', value => value >= 0)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const yupPercentageValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 0%', value => value >= 0)
    .test('max', 'Max: 1000%', value => value <= 1000)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const formik = useFormik({
    initialValues: {
      system_name: '',
      energy_source: '',
      CO2_Fuel_txt: '',
      CO2_Fuel: '',
      kWH_CF_Fuel_txt: '',
      kWh_CF_Fuel: '',
      n_ex_syst: '',
      is_showed: false,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      system_name: Yup.string().required('Required'),
      energy_source: Yup.string().required('Required'),
      CO2_Fuel_txt: Yup.string().required('Required'),
      CO2_Fuel: yupNumberValidation,
      kWH_CF_Fuel_txt: Yup.string().required('Required'),
      kWh_CF_Fuel: yupNumberValidation,
      n_ex_syst: yupPercentageValidation,
    }),
    onSubmit: async (values) => {
      let response = null

      const newValues = {
        ...values,
        CO2_Fuel: normalizeNumberWithCommas(values.CO2_Fuel),
        kWh_CF_Fuel: normalizeNumberWithCommas(values.kWh_CF_Fuel),
        n_ex_syst: normalizePercentToSend(values.n_ex_syst),
        is_showed: String(values.is_showed)
      }

      if (!modalId) response = await createHotWaterSystem(token, newValues)
      else response = await updateHotWaterSystem(token, modalId, newValues)

      const nonFieldErrors = response?.non_field_errors

      if ((nonFieldErrors?.length && nonFieldErrors[0] == 'A water system with the same name already exists') || response?.error == 'Water system with this system_name already exists') {
        formik.setFieldError('system_name', 'This name is already in use')
      }
      else if (response.error)
        setServerError(true)
      else if (response?.data) {
        setStep(2)
        setWaterSystems((prev) => {
          if (modalId) {
            const index = prev.findIndex((item) => item.id === modalId)
            const newWaterProfiles = [...prev]
            newWaterProfiles[index] = response.data
            return newWaterProfiles
          } else {
            return [...prev, response.data]
          }
        })
      }
    },
  })

  useEffect(() => {
    if (!modalId) return
    const getInfo = async () => {
      const response = await getHotWaterSystemDetails(token, modalId)

      if (response.error) console.error(response.error)
      else if (response) {
        formik.setValues({
          ...response,
          n_ex_syst: normalizePercentToShow(response.n_ex_syst),
        })
      }
    }
    getInfo()
  }, [modalId])

  if (step === 1)
    return (
      <Modal
        title={!modalId ? '+ Add new system' : 'Edit system'}
        closeModal={closeShowNewSystemModal}
        prevStep={() => {toggleShowManageSystemModal(); closeShowNewSystemModal()}}
        notCloseOnBlur
      >
        <div
          className="d-flex flex-column justify-content-center align-items-center gap-3 my-3 mx-auto"
          style={{ maxHeight: 'calc(90dvh - 160px)' }}
        >
          <div className="Columns Two">
            <Input
              label="System name"
              name="system_name"
              placeholder="Enter system name"
              value={formik.values.system_name}
              onChange={formik.handleChange}
              error={formik.errors.system_name}
            />

            <Input
              label="Energy source"
              name="energy_source"
              placeholder="Enter energy source name"
              value={formik.values.energy_source}
              onChange={formik.handleChange}
              error={formik.errors.energy_source}
            />
          </div>

          <div className="Modal__Divider" />

          <div className="Columns Two overflow-auto Scroll_MS-SEC">
            <Input
              label="CO2 factor text"
              name="CO2_Fuel_txt"
              placeholder="Enter CO2 factor text"
              value={formik.values.CO2_Fuel_txt}
              onChange={formik.handleChange}
              error={formik.errors.CO2_Fuel_txt}
            />

            <Input
              label="CO2 factor"
              name="CO2_Fuel"
              type="number"
              placeholder="0"
              value={formik.values.CO2_Fuel}
              onChange={formik.handleChange}
              error={formik.errors.CO2_Fuel}
            />

            <Input
              label="Energy factor text"
              name="kWH_CF_Fuel_txt"
              placeholder="Enter energy factor text"
              value={formik.values.kWH_CF_Fuel_txt}
              onChange={formik.handleChange}
              error={formik.errors.kWH_CF_Fuel_txt}
            />

            <Input
              label="Energy factor"
              name="kWh_CF_Fuel"
              placeholder="0"
              value={formik.values.kWh_CF_Fuel}
              onChange={formik.handleChange}
              error={formik.errors.kWh_CF_Fuel}
            />

            <Input
              label="Thermal efficiency / COP"
              name="n_ex_syst"
              type="number"
              placeholder="0%"
              value={formik.values.n_ex_syst}
              onChange={formik.handleChange}
              error={formik.errors.n_ex_syst}
            />
          </div>

          {!modalId ? (
            <>
              <div className="Modal__Divider" />

              <div className="d-flex flex-column gap-2 w-100">
                <p className="text-5 fw-medium">
                  Save system for future simulations?
                </p>
                <button
                  className="d-flex gap-2"
                  onClick={() =>formik.setFieldValue('is_showed', !formik.values.is_showed)}
                >
                  <div
                    className={classNames('Checkbox', {
                      Selected: formik.values.is_showed,
                    })}
                  />
                  <p className="text-6">Yes</p>
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        {serverError ? <p className='text-red'>Something went wrong</p> : <></>}

        <Button
          className={classNames('gap-0 mx-auto w-100', { 'mt-2': modalId })}
          type="submit"
          onClick={() => formik.handleSubmit()}
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <>
              <span>{!modalId ? 'Creating' : 'Saving'}</span>
              <LoadingDots className='enter-done' />
            </>
          ) : (
            <span>{!modalId ? 'Create' : 'Save'}</span>
          )}
          
        </Button>
      </Modal>
    )

  if (step === 2)
    return (
      <CheckModal
        closeModal={closeShowNewSystemModal}
        title={!modalId ? '+ Add new system' : 'Edit system'}
        message={
          !modalId
            ? 'The new system has been added successfully, now you can select it in the simulation'
            : 'System has been edited successfully'
        }
      />
    )
}

export default NewSystemModal
