import { Helmet } from 'react-helmet'
import { ChangeEventHandler, useState, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { UserComplete } from '../../api/apiTypes'
import { usersList } from '../../api/users'
import Input from '../../components/Input'
import CreateUserModal from '../../components/modals/CreateUserModal'
import EditUserModal from '../../components/modals/EditUserModal'
import UserInfoModal from '../../components/modals/UserInfoModal'
import TableFilters from '../../components/TableFilters'
import TableFiltersMobile from '../../components/TableFiltersMobile'
import Table from '../../components/tables/Table'
import { TableUsers } from '../../components/tables/TableUsers'
import TableTitle from '../../components/TableTitle'
import useAppContext from '../../hooks/useAppContext'
import useTable from '../../hooks/useTable'
import { FiltersGroup, FiltersSelected, TableHeaders } from '../../types'
import useOrderby from '../../hooks/useOrderby'
import { ISearch } from '../../iconComponents'
import DeleteUserModal from '../../components/modals/DeleteUserModal'
import Modal from '../../components/modals/Modal'
import useMatchMedia from '../../hooks/useMatchMedia'
import Button from '../../components/buttons/Button'

const Users = () => {
  const {
    token,
    user,
    isCentralAdmin,
    isOrganizationAdmin,
    isRegionAdmin,
    setUserToDelete,
    setsimulationsToTransfer,
    setUserToTransfer,
    setSimulationActionSelected,
    setUserActionSelected,
  } = useAppContext()
  const [page, setPage] = useState(1)
  const perPage = 20
  const [search, setSearch] = useState('')
  const { orderBy, sort, handleSort } = useOrderby('first_name')
  const [filtersSelected, setFiltersSelected] = useState<FiltersSelected[]>([
    {
      label: 'N° of Simulations',
      defaultOption: {
        label: 'All',
        value: { sim_lower: null, sim_upper: null },
      },
      option: {
        label: 'All',
        value: { sim_lower: null, sim_upper: null },
      },
    },
    // {
    //   label: 'Usertype',
    //   defaultOption: {
    //     label: 'All',
    //     value: '',
    //   },
    //   option: {
    //     label: 'All',
    //     value: '',
    //   },
    // },
  ])
  const params = {
    page,
    per_page: perPage,
    search,
    order_by: orderBy,
    sim_lower:
      typeof filtersSelected[0].option.value === 'object'
        ? filtersSelected[0].option.value.sim_lower
        : null,
    sim_upper:
      typeof filtersSelected[0].option.value === 'object'
        ? filtersSelected[0].option.value.sim_upper
        : null,
  }
  const { data, isFetching, refetch } = useQuery(
    ['usersList', page, search, filtersSelected, orderBy],
    () => usersList(token, params),
    {
      initialData: {
        page: 1,
        total_items: 0,
        total_pages: 1,
        data: [],
      },
      enabled: token !== '',
      refetchOnWindowFocus: false,
    }
  )
  const usersListFilter = useMemo(
    () => data.data.filter((item) => item.id !== user?.id),
    [data]
  )
  const { selectedItems, handleSelectItem, handleSelectAll } = useTable({
    tableData: usersListFilter,
  })
  const [showCreateUserModal, setShowCreateUserModal] = useState(false)
  const [userInfo, setUserInfo] = useState<UserComplete>(null)
  const [showEditUserModal, setShowEditUserModal] = useState(false)
  const [showUserInfoModal, setShowUserInfoModal] = useState(false)
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false)

  const isMobile = useMatchMedia()
  const navigate = useNavigate()

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPage(1)
    setSearch(event.target.value)
  }

  const openShowEditUserModal = (info: UserComplete) => {
    if (info.user_profile.user_type === 'central_admin') return setShowAlertModal(true)

    setUserInfo(info)
    setShowEditUserModal(true)
  }

  const closeShowEditUserModal = () => setShowEditUserModal(false)

  const openShowUserInfoModal = (info: UserComplete) => {
    setUserInfo(info)
    setShowUserInfoModal(true)
  }

  const closeShowUserInfoModal = () => setShowUserInfoModal(false)

  const closeShowAlertModal = () => setShowAlertModal(false)

  const filtersGroup: FiltersGroup[] = [
    {
      label: 'N° of Simulations',
      options: [
        {
          label: '0 - 10',
          value: { sim_lower: 0, sim_upper: 10 },
        },
        {
          label: '10 - 30',
          value: { sim_lower: 10, sim_upper: 30 },
        },
        {
          label: '30 - 50',
          value: { sim_lower: 30, sim_upper: 50 },
        },
        {
          label: '50 +',
          value: { sim_lower: 50, sim_upper: 999999999 },
        },
      ],
    },
    // {
    //   label: 'Usertype',
    //   options: [
    //     {
    //       label: 'Region admin',
    //       value: 'region_admin',
    //     },
    //     {
    //       label: 'Operator',
    //       value: 'operator',
    //     },
    //   ],
    // },
  ]

  const tableHeaders: TableHeaders[] = [
    {
      id: 0,
      label: 'Name',
      value: 'first_name',
    },
    {
      id: 1,
      label: 'Email',
      value: 'email',
    },
    {
      id: 3,
      label: 'User type',
      value: 'user_profile__user_type',
    },
    {
      id: 4,
      label: 'Permissions',
      value: 'permissions',
    },
    {
      id: 5,
      label: 'Created by',
      value: 'created_by__first_name',
    },
    {
      id: 6,
      label: 'Simulations',
      value: 'simulation_count',
    },
    {
      id: 7,
      label: 'Last active',
      value: 'last_active',
    },
  ]

  if (isCentralAdmin) {
    tableHeaders.splice(2, 0, {
      id: 2,
      label: 'Organization',
      value: 'user_profile__organization__name',
    })
  }

  if (isOrganizationAdmin) {
    tableHeaders.splice(2, 0, {
      id: 2,
      label: 'Region',
      value: 'user_profile__region__name',
    })
  }

  const openShowCreateUserModal = () => setShowCreateUserModal(true)

  const closeShowCreateUserModal = () => setShowCreateUserModal(false)

  const openShowDeleteUser = () => {
    if (selectedItems[0].simulations === 0) {
      setUserToDelete(selectedItems)
      setShowDeleteUserModal(true)
      return
    }

    setUserToDelete(selectedItems)
    setsimulationsToTransfer([])
    setUserToTransfer([])
    setSimulationActionSelected('')
    setUserActionSelected('')
    navigate('/dashboard/users/delete-user')
  }

  const closeShowDeleteUserModal = () => {
    setUserToDelete([])
    setShowDeleteUserModal(false)
  }

  return (
    <>
      <Helmet>
        <title>Makt: Users</title>
      </Helmet>

      <section className="Dashboard">
        <div>
          <Input
            name="Search"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            icon={<ISearch className="Input__Icon" />}
          />
        </div>

        <div className="Dashboard__Filters_Container">
          <div className="Dashboard__Filters_Container_Mobile d-flex justify-between d-md-none pr-1">
            <TableFiltersMobile
              filtersSelected={filtersSelected}
              setFiltersSelected={setFiltersSelected}
              filtersGroup={filtersGroup}
              headers={tableHeaders}
              sort={sort}
              handleSort={handleSort}
            />

            <TableTitle
              totalItems={usersListFilter.length}
              itemsSelected={selectedItems.length}
              mobile
            />
          </div>
          <div className="Dashboard__Filters_Container_Mobile gap-2 d-flex d-md-none">
            <button
              className="button_red text-5"
              onClick={openShowDeleteUser}
              disabled={selectedItems.length !== 1}
              style={{ minWidth: '100px' }}
            >
              Delete
            </button>

            <Button
              className="text-5 ms-auto"
              size='s'
              onClick={openShowCreateUserModal}
              style={{ minWidth: '100px' }}
            >
              Create User
            </Button>
          </div>

          <TableFilters
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            filtersGroup={filtersGroup}
          />
          <TableTitle
            totalItems={usersListFilter.length}
            itemsSelected={selectedItems.length}
          />
          <button
            className="button_red text-5 d-none d-md-flex"
            onClick={openShowDeleteUser}
            disabled={selectedItems.length !== 1}
          >
            Delete
          </button>
          <Button
            className="d-none d-md-flex ms-auto"
            size='s'
            onClick={openShowCreateUserModal}
          >
              Create User
          </Button>
        </div>

        <Table
          type={
            isCentralAdmin || isOrganizationAdmin
              ? 'Users_Extended_Plus_Action'
              : isRegionAdmin
                ? 'Users_Extended_Action'
                : 'Users'
          }
          handleSelectAll={handleSelectAll}
          selectedItemsLength={selectedItems.length}
          headers={tableHeaders}
          dataLength={usersListFilter.length}
          loading={isFetching || token === ''}
          sort={sort}
          handleSort={handleSort}
          page={page}
          setPage={setPage}
          perPage={data.total_items}
          totalPages={data.total_pages}
          totalItems={data.total_items - 1}
        >
          {usersListFilter.map((item) => {
            return (
              <TableUsers
                key={item.id}
                data={item}
                selected={selectedItems.some(
                  (itemSelect) => itemSelect?.id === item.id
                )}
                handleSelectItem={handleSelectItem}
                edit={isCentralAdmin || isOrganizationAdmin || isRegionAdmin ? openShowEditUserModal : null}
                details={openShowUserInfoModal}
                isMobile={isMobile}
              />
            )
          })}
        </Table>
      </section>

      {showCreateUserModal ? (
        <CreateUserModal
          closeModal={closeShowCreateUserModal}
          refetch={refetch}
        />
      ) : (
        <></>
      )}

      {showEditUserModal ? (
        <EditUserModal
          userInfo={userInfo}
          refetch={refetch}
          closeModal={closeShowEditUserModal}
        />
      ) : (
        <></>
      )}

      {showUserInfoModal ? (
        <UserInfoModal data={userInfo} closeModal={closeShowUserInfoModal} />
      ) : (
        <></>
      )}

      {showDeleteUserModal ? (
        <DeleteUserModal refetch={refetch} closeModal={closeShowDeleteUserModal} />
      ) : (
        <></>
      )}

      {showAlertModal ? (
        <Modal title="" closeModal={closeShowAlertModal}>
          <div className="py-5 text-center">
            <p className="text-3 fw-medium">{'Central admins can\'t be edited'}</p>
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </>
  )
}

export default Users
