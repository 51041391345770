import { useState, useMemo } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { OrganizationDetail } from '../../api/apiTypes'
import Modal from './Modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addOffice, getCountries } from '../../api/organizations'
import useAppContext from '../../hooks/useAppContext'
import CheckModal from './CheckModal'
import Input from '../Input'
import SearchInput from '../SearchInput'
import { useQuery } from 'react-query'
import LoadingDots from '../LoadingDots'
import PhoneNumberInput from '../PhoneNumberInput'
import classNames from 'classnames'
import Button from '../buttons/Button'

interface Props {
  closeModal: () => void;
  organization: OrganizationDetail;
  refetch: () => void;
}

const NewOfficeModal = ({ closeModal, organization, refetch }: Props) => {
  const { token } = useAppContext()
  const [step, setStep] = useState(1)
  const [serverError, setServerError] = useState(false)

  const { data } = useQuery(['getCountries'], () => getCountries(token), {
    initialData: {
      user_countries: [],
      all_countries: [],
    },
    enabled: token !== '',
  })
  const countries = useMemo(
    () =>
      data.all_countries
        ? data.all_countries.map((country) => ({
          value: country.id,
          label: country.name,
        }))
        : [],
    [data]
  )

  const formik = useFormik<{
    name: string;
    country: number | null;
    city: string;
    zip_code: string;
    address_line_1: string;
    address_line_2: string;
    phone: string;
    email: string;
    HQ: boolean;
  }>({
    initialValues: {
      name: '',
      country: null,
      city: '',
      zip_code: '',
      address_line_1: '',
      address_line_2: '',
      phone: '',
      email: '',
      HQ: false,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      country: Yup.number().required('Required'),
      city: Yup.string().required('Required'),
      zip_code: Yup.string().required('Required'),
      address_line_1: Yup.string().required('Required'),
      phone: Yup.string()
        .required('Required')
        .test((value) => isValidPhoneNumber(value)),
      email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: async ({
      name,
      country,
      city,
      zip_code,
      address_line_1,
      address_line_2,
      phone,
      email,
      HQ,
    }) => {
      const response = await addOffice(
        token,
        {
          name,
          country,
          city,
          zip_code,
          address_line_1,
          phone,
          email,
          HQ,
          organization: organization.id,
        },
        { address_line_2 }
      )

      if (response.error)
        if (
          response.error.name ||
          response.error.country ||
          response.error.city ||
          response.error.zip_code ||
          response.error.phone ||
          response.error.email ||
          response.error.address_line_1 ||
          response.error.address_line_2
        )
          Object.keys(response.error).forEach((key) =>
            formik.setFieldError(
              key,
              response.error[key] instanceof Array ? response.error[key][0] : response.error[key]
            )
          )
        else setServerError(true)
      else if (response) {
        setStep((prev) => prev + 1)
        refetch()
      }
    },
  })

  const toggleHQ = () => formik.setFieldValue('HQ', !formik.values.HQ)

  if (step === 1)
    return (
      <Modal
        title={`Add new office to ${organization.name}`}
        closeModal={closeModal}
      >
        <div className="Modal__Scroll-Content d-flex flex-column gap-3 py-3">
          <Input
            label="Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
          />
          <div className="d-flex gap-2">
            <SearchInput
              label="Country"
              name="country"
              placeholder="Country"
              search={countries}
              value={Number(formik.values.country)}
              onChange={(id) => formik.setFieldValue('country', id)}
              error={formik.errors.country}
            />
            <Input
              label="City"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.errors.city}
            />
            <Input
              label="Zip code"
              name="zip_code"
              value={formik.values.zip_code}
              onChange={formik.handleChange}
              error={formik.errors.zip_code}
              containerProps={{ style: { maxWidth: '100px' } }}
            />
          </div>
          <Input
            label="Address line 1"
            name="address_line_1"
            value={formik.values.address_line_1}
            onChange={formik.handleChange}
            error={formik.errors.address_line_1}
          />
          <Input
            label="Address line 2 <span class='ms-2 text-6 fw-light text-secondary'>Optional</span>"
            name="address_line_2"
            value={formik.values.address_line_2}
            onChange={formik.handleChange}
            error={formik.errors.address_line_2}
          />

          <Input
            label="Contact email"
            name="email"
            type="email"
            placeholder="someone@gmail.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
          />

          <PhoneNumberInput
            label="Phone number"
            name="phone"
            value={formik.values.phone}
            onChange={(value) => formik.setFieldValue('phone', value)}
            errors={formik.errors.phone}
          />

          <div className="d-flex flex-column gap-2 w-100">
            <p className="text-5 fw-medium">
              Mark this office as Head Quarter’s location?
            </p>
            <button className="d-flex gap-2" onClick={toggleHQ}>
              <div
                className={classNames('Checkbox', {
                  Selected: formik.values.HQ,
                })}
              />
              <p className="text-6">Yes</p>
            </button>
          </div>

          {serverError ? (
            <p className="text-red">Something went wrong</p>
          ) : (
            <></>
          )}

          <div className="d-flex flex-column align-items-center gap-2">
            <Button
              className="gap-0 w-100"
              type="submit"
              onClick={() => formik.handleSubmit()}
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? (
                <>
                  <span>Adding</span>
                  <LoadingDots className="enter-done" />
                </>
              ) : (
                <span>Add office</span>
              )}
            </Button>
          </div>
        </div>
      </Modal>
    )

  return (
    <CheckModal
      closeModal={closeModal}
      title={`Add new office to ${organization.name}`}
      message="¡Office added succesfully!"
    />
  )
}

export default NewOfficeModal
