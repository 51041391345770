import { useMemo, useState } from 'react'
import { Client } from '../../api/apiTypes'
import Modal from './Modal'
import CheckModal from './CheckModal'
import useAppContext from '../../hooks/useAppContext'
import {
  IAlert,
  IList,
  ILocation,
  IMail,
  IOrganization,
  IPhone,
} from '../../iconComponents'
import LoadingDots from '../LoadingDots'
import { deleteClient } from '../../api/clients'
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import Button from '../buttons/Button'

interface Props {
  closeModal: () => void;
  clientInfo: Client;
  refetch: () => void;
}

const DeleteClienteModal = ({ clientInfo, refetch, closeModal }: Props) => {
  const { token, isCentralAdmin, isOrganizationAdmin, isRegionAdmin } =
    useAppContext()
  const [step, setStep] = useState(1)
  const [buttonModalIsLoading, setButtonModalIsLoading] = useState(false)
  const [serverError, setServerError] = useState(false)

  const isEditable = useMemo(
    () =>
      (!(isCentralAdmin || isOrganizationAdmin || isRegionAdmin) &&
        clientInfo?.simulation_count === 0) ||
      isCentralAdmin ||
      isOrganizationAdmin ||
      isRegionAdmin,
    [isCentralAdmin, isOrganizationAdmin, isRegionAdmin, clientInfo]
  )

  const handleDelete = async () => {
    if (buttonModalIsLoading) return
    setButtonModalIsLoading(true)

    const response = await deleteClient(token, {
      client_id: clientInfo?.id,
    })

    if (response.error) setServerError(true)
    else if (response) {
      refetch()
      setStep(2)
    }

    setButtonModalIsLoading(false)
  }

  if (step === 1)
    return (
      <Modal title="Delete client" closeModal={closeModal}>
        <div className="Modal__Scroll-Content d-flex flex-column gap-3 p-3">
          {!isEditable ? (
            <>
              <div className="d-flex align-items-center gap-2 ">
                <div className="flex-shrink-0 d-flex justify-content-center align-items-center p-2 bg-transparent_red rounded-full">
                  <IAlert width={12} height={12} className="text-red" />
                </div>
                <div
                  className="text-5 py-2 px-3 bg-transparent_red"
                  style={{ borderRadius: '30px' }}
                >
                  You cannot delete this client because it has simulations on
                  course.
                </div>
              </div>
              <p className="text-5 ">
                If you need to edit or delete this client, get in contact with
                your administrator.
              </p>

              <Button
                className="w-100 text-4 mt-3"
                onClick={closeModal}
              >
                Go back to panel
              </Button>
            </>
          ) : (
            <>
              <IAlert
                width={60}
                height={60}
                className="flex-shrink-0 text-yellow d-block mx-auto"
              />
              <p className="text-3 text-center fw-medium">
                Are you sure you want to delete “{clientInfo?.name}”?
              </p>

              <div className="Modal__Divider" />

              <p className="text-center text-4">{clientInfo?.name}</p>

              <div className="Modal__Divider" />

              <div className="DeleteSimulationModal__Grid my-4">
                <div className="DeleteSimulationModal__Grid_Item">
                  <div className="DeleteSimulationModal__Grid_Item_Title">
                    <IOrganization className="DeleteSimulationModal__Grid_Item_Title_Icon" />

                    <p className="text-5 text-secondary">Organization</p>
                  </div>
                  <div className="DeleteSimulationModal__Grid_Item_Info text-5">
                    {clientInfo?.organization_name || '-'}
                  </div>
                </div>
                <div className="DeleteSimulationModal__Grid_Item">
                  <div className="DeleteSimulationModal__Grid_Item_Title">
                    <IList className="DeleteSimulationModal__Grid_Item_Title_Icon" />

                    <p className="text-5 text-secondary">Simulations</p>
                  </div>
                  <div className="DeleteSimulationModal__Grid_Item_Info text-5">
                    {clientInfo?.simulation_count ?? '-'}
                  </div>
                </div>
                <div className="DeleteSimulationModal__Grid_Item">
                  <div className="DeleteSimulationModal__Grid_Item_Title">
                    <ILocation className="DeleteSimulationModal__Grid_Item_Title_Icon" />

                    <p className="text-5 text-secondary">Location</p>
                  </div>
                  <div className="DeleteSimulationModal__Grid_Item_Info text-5">
                    {clientInfo?.country_name || '-'}
                  </div>
                </div>
                <div className="DeleteSimulationModal__Grid_Item">
                  <div className="DeleteSimulationModal__Grid_Item_Title">
                    <IMail className="DeleteSimulationModal__Grid_Item_Title_Icon" />

                    <p className="text-5 text-secondary">Email</p>
                  </div>
                  <div className="DeleteSimulationModal__Grid_Item_Info text-5">
                    {clientInfo?.email || '-'}
                  </div>
                </div>
                <div className="DeleteSimulationModal__Grid_Item">
                  <div className="DeleteSimulationModal__Grid_Item_Title">
                    <ILocation className="DeleteSimulationModal__Grid_Item_Title_Icon" />
                    <p className="text-5 text-secondary">Address</p>
                  </div>
                  <div className="DeleteSimulationModal__Grid_Item_Info text-5">
                    {clientInfo?.address || '-'}
                  </div>
                </div>
                <div className="DeleteSimulationModal__Grid_Item">
                  <div className="DeleteSimulationModal__Grid_Item_Title">
                    <IPhone className="DeleteSimulationModal__Grid_Item_Title_Icon" />
                    <p className="text-5 text-secondary">Phone number</p>
                  </div>
                  <div className="DeleteSimulationModal__Grid_Item_Info text-5">
                    {isValidPhoneNumber(clientInfo?.phone) ? formatPhoneNumberIntl(clientInfo?.phone) : clientInfo?.phone || '-'}
                  </div>
                </div>
              </div>

              {serverError ? (
                <p className="text-red">Something went wrong</p>
              ) : (
                <></>
              )}

              <div className="d-flex align-items-center gap-2">
                <button
                  className="button_transparent_red button_defult w-100 text-4 gap-0"
                  type="submit"
                  onClick={handleDelete}
                  disabled={buttonModalIsLoading}
                >
                  {buttonModalIsLoading ? (
                    <>
                      <span>Deleting</span>
                      <LoadingDots className="enter-done" />
                    </>
                  ) : (
                    <span className="text-red">Yes, delete client</span>
                  )}
                </button>
                <Button
                  variant='gray'
                  className="w-100 text-4"
                  onClick={closeModal}
                >
                  No, do not delete
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    )

  return (
    <CheckModal
      closeModal={closeModal}
      title="Delete client"
      message="¡Client deleted successfully!"
    />
  )
}

export default DeleteClienteModal
