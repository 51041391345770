import PageInfo from '../../components/PageInfo'
import PagesSort from '../../components/PagesSort'
import ReportContextProvider from '../../context/ReportContext'
import PreviewPDF from '../../components/GenerateReport/PreviewPDF'
import DownloadReport from '../../components/GenerateReport/DownloadReport'
import UploadReport from '../../components/GenerateReport/UploadReport'
import ButtonPreviewReport from '../../components/GenerateReport/ButtonPreviewReport'
import PageHeader from '../../components/GenerateReport/PageHeader'
import ButtonLink from '../../components/buttons/ButtonLink'

export default function GenerateReport() {
  return (
    <ReportContextProvider>
      <div className="GenerateReport">
        <PageHeader />
        <PagesSort />
        <PageInfo />
        <PreviewPDF />

        <div className="GenerateReport_Actions">
          <ButtonLink
            to="/dashboard/create-simulation/running-complete"
            variant='white_black'
            className='GenerateReport_Actions_GoBack'
            style={{ minWidth: 192 }}
          >
            Go back to simulation
          </ButtonLink>
          <UploadReport />
          <DownloadReport />
          <ButtonPreviewReport />
        </div>
      </div>
    </ReportContextProvider>
  )
}
