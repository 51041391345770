import { useMemo, useState } from 'react'
import { deletePVModel } from '../../api/simulations/system-parameters/pv-field'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import useModalsContext from '../../hooks/useModalsContext'
import TableManage from '../tables/TableManage'
import Modal from './Modal'
import Button from '../buttons/Button'

const ManagePVModelModal = () => {
  const { token } = useAppContext()
  const { pvModels, getExtraData } = useNewSimulationContext()
  const { pvModelTypeSelected, setModalId, toggleShowManagePVModelsModal, openShowNewPVModelModal, } = useModalsContext()
  const [showDeletePVModel, setShowDeletePVModel] = useState(false)
  const [pvModelToDelete, setPVModelToDelete] = useState(null)

  const pvModelsFilter = useMemo(
    () =>
      pvModels.filter(
        (model) =>
          model.panel_type ===
          pvModelTypeSelected[pvModelTypeSelected.panelSelected]
      ),
    [pvModels]
  )

  const handleEdit = (id: number) => {
    toggleShowManagePVModelsModal()
    setModalId(id)
    openShowNewPVModelModal()
  }

  const openshowDeletePVModel = (model) => {
    setPVModelToDelete(model)
    setShowDeletePVModel(true)
  }

  const handleDelete = async () => {
    const response = await deletePVModel(token, pvModelToDelete.id)

    if (response.error) console.error(response.error)
    else if (response) getExtraData()

    setShowDeletePVModel(false)
  }

  return (
    <Modal title="Manage PV models" closeModal={toggleShowManagePVModelsModal}>
      <div className="Modal__Scroll-Content d-flex flex-column align-items-center">
        {/* <button
          className="p-2 text-5 fw-normal button-transparent"
          onClick={() => {
            toggleShowManagePVModelsModal()
            openShowNewPVModelModal()
          }}
        >
          + Add new PV model
        </button>
        <div className="Modal__Divider mb-2" /> */}

        <TableManage
          name="pv models"
          data={pvModelsFilter}
          handleEdit={handleEdit}
          openDeleteModal={openshowDeletePVModel}
        />
      </div>
      {showDeletePVModel ? (
        <Modal
          title="Delete PV model"
          closeModal={() => setShowDeletePVModel(false)}
        >
          <div className="d-flex flex-column justify-content-center align-items-center p-5">
            <p className="text-center">Are you sure you want to delete the <span className="fw-medium">{pvModelToDelete.name}</span> PV model?</p>
          </div>
          <Button className="mx-auto w-100" onClick={handleDelete}>
            Delete
          </Button>
        </Modal>
      ) : (
        <></>
      )}
    </Modal>
  )
}

export default ManagePVModelModal
