import background from '../assets/images/background.jpg'

const MaktBanner = () => {
  return (
    <div className="MaktBanner h-100">
      <img
        className="MaktBanner__Logo"
        src={background}
        alt="Makt - Simplify energy, optimize efficiency"
        title="Makt - Simplify energy, optimize efficiency"
      />
    </div>
  )
}

export default MaktBanner
