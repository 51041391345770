import { useMemo } from 'react'
import { Document, pdf } from '@react-pdf/renderer'
import useReportContext from '../../hooks/useReportContext'
import PageRender from '../PageRender'
import { v4 as uuidv4 } from 'uuid'
import { saveAs } from 'file-saver'
import Button from '../buttons/Button'

export default function DownloadReport() {
  const { pages, dateFormat, reportLanguage } = useReportContext()

  const pagesRender = useMemo(() => {
    const values = []
    pages.forEach((page) => {
      if (!page.active) return null
      if (page.type === 'single') {
        values.push(
          <PageRender key={page.id} pages={pages} pageSelected={page} dateFormat={dateFormat} language={reportLanguage} />
        )
      } else {
        page.subPages.forEach((subPage) => {
          if (!subPage.active) return null
          values.push(
            <PageRender key={subPage.id} pages={pages} pageSelected={subPage} dateFormat={dateFormat} language={reportLanguage} />
          )
        })
      }
    })
    const lastPageID = uuidv4()
    values.push(
      <PageRender
        key={lastPageID}
        pages={pages}
        pageSelected={{
          id: lastPageID,
          label: '',
          title: '',
          active: true,
          type: 'single',
          sections: [],
          footer: [],
        }}
        dateFormat={dateFormat}
        language={reportLanguage}
      />
    )
    return values
  }, [pages])

  const handleDownload = async () => {
    const blob = await pdf(
      <Document title="Report">{pagesRender}</Document>
    ).toBlob()
    saveAs(blob, 'untitled.pdf')
  }

  return (
    <Button
      className="GenerateReport_Actions_Download"
      style={{ minWidth: 192 }}
      onClick={handleDownload}
    >
      Download report
    </Button>
  )
}
