import { faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useState, Dispatch, SetStateAction, useMemo } from 'react'
import { CSSTransition } from 'react-transition-group'
import { FiltersGroup, FiltersSelected, Option, TableHeaders } from '../types'
import Input from './Input'
import { IAngleUp, IList } from '../iconComponents'
import Button from './buttons/Button'

interface Props {
  filtersSelected?: Array<FiltersSelected>;
  setFiltersSelected?: Dispatch<SetStateAction<Array<FiltersSelected>>>;
  filtersGroup?: Array<FiltersGroup>;
  headers: Array<TableHeaders>;
  sort: {
    value: string;
    bool: boolean;
  };
  handleSort: (value: string) => void;
}

const TableFiltersMobile = ({
  filtersSelected,
  setFiltersSelected,
  filtersGroup,
  headers,
  sort,
  handleSort,
}: Props) => {
  const [showFilters, setShowFilters] = useState(false)
  const [showOrders, setShowOrders] = useState(false)

  const searchOptionsFiltered = useMemo(
    () =>
      filtersSelected
        ? filtersGroup.map((filterGroup, index) => {
          if (typeof filtersSelected[index].search !== 'string')
            return filterGroup.options
          if (filtersSelected[index].search.length !== 0)
            return filterGroup.options.filter((item) =>
              item.label
                .toLowerCase()
                .includes(filtersSelected[index].search.toLowerCase())
            )
          else return filterGroup.options
        })
        : [],
    [filtersSelected, filtersGroup]
  )

  const toggleShowFilters = () => {
    setShowFilters((p) => !p)
  }
  const toggleShowOrders = () => {
    setShowOrders((p) => !p)
  }

  const handleClear = () => {
    setFiltersSelected((filter) =>
      filter.map((filterSelected) => ({
        ...filterSelected,
        option: filterSelected.defaultOption,
      }))
    )
  }

  const handleOptionSelected = (filterIndex: number, option: Option) => {
    setFiltersSelected((prev) => {
      if (
        JSON.stringify(filtersSelected[filterIndex].option.value) ===
        JSON.stringify(option.value)
      )
        return [
          ...prev.slice(0, filterIndex),
          {
            ...prev[filterIndex],
            option: filtersSelected[filterIndex].defaultOption,
          },
          ...prev.slice(filterIndex + 1),
        ]
      else
        return [
          ...prev.slice(0, filterIndex),
          {
            ...prev[filterIndex],
            option: option,
          },
          ...prev.slice(filterIndex + 1),
        ]
    })
  }

  const handleSearch = (filterIndex: number, value: string) => {
    setFiltersSelected((prev) => [
      ...prev.slice(0, filterIndex),
      {
        ...prev[filterIndex],
        search: value,
      },
      ...prev.slice(filterIndex + 1),
    ])
  }

  return (
    <>
      {filtersSelected ? (
        <div className="TableFiltersMobile">
          <button
            className="TableFiltersMobile__Title"
            onClick={toggleShowFilters}
          >
            <IList className="text-secondary" />
            <span className="text-5 text-secondary">Filter by</span>
          </button>
          <CSSTransition in={showFilters} timeout={300} unmountOnExit>
            <div className="TableFiltersMobile__Content">
              <div className="TableFiltersMobile__Content_Title">
                <p className="text-2">Filters</p>
                <button
                  className={'TableFiltersMobile__Content_Title_Button'}
                  onClick={toggleShowFilters}
                >
                  <IAngleUp width={20} height={20} className="-rotate-90" />
                </button>
              </div>
              <button
                className={classNames('TableFiltersMobile__Content_Clear', {
                  Disabled: filtersSelected.every(
                    (filter) => filter.option.value === ''
                  ),
                })}
                onClick={handleClear}
              >
                <p>Clear all filters</p>
                <FontAwesomeIcon
                  icon={faX}
                  className="TableFiltersMobile__Content_Clear_Icon"
                />
              </button>

              {filtersGroup.map((filter, filterIndex) => (
                <div
                  key={filterIndex}
                  className="TableFiltersMobile__Content_Item"
                >
                  <p className="TableFiltersMobile__Content_Item_Title text-blue_primary">
                    {filter.label}
                  </p>
                  {typeof filtersSelected[filterIndex].search === 'string' ? (
                    <div className="TableFiltersMobile__Content_Item_search">
                      <Input
                        name="Search"
                        placeholder={filter.label}
                        value={filtersSelected[filterIndex].search}
                        onChange={(e) =>
                          handleSearch(filterIndex, e.target.value)
                        }
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {typeof filtersSelected[filterIndex].search === 'string' &&
                    filtersSelected[filterIndex].option.value !== '' && (
                    <div className="TableFiltersMobile__Content_Item_Option_Selected">
                      <Button
                        variant="gray"
                        onClick={() =>
                          handleOptionSelected(
                            filterIndex,
                            filtersSelected[filterIndex].option
                          )
                        }
                      >
                        <p className="text-blue_primary text-4">
                          {filtersSelected[filterIndex].option.label}
                        </p>
                        <FontAwesomeIcon
                          icon={faX}
                          className="TableFiltersMobile__Content_Item_Option_Selected_Icon"
                        />
                      </Button>
                    </div>
                  )}

                  {searchOptionsFiltered[filterIndex]?.map((option, index) => {
                    if (
                      typeof filtersSelected[filterIndex].search === 'string' &&
                      JSON.stringify(option.value) ===
                        JSON.stringify(
                          filtersSelected[filterIndex].option.value
                        )
                    )
                      return
                    return (
                      <button
                        key={
                          typeof option.value === 'string'
                            ? option.value
                            : index
                        }
                        className={classNames(
                          'TableFiltersMobile__Content_Item_Option',
                          {
                            Search:
                              typeof filtersSelected[filterIndex].search ===
                              'string',
                          },
                          {
                            Active:
                              JSON.stringify(option.value) ===
                              JSON.stringify(
                                filtersSelected[filterIndex].option.value
                              ),
                          },
                          'text-5'
                        )}
                        onClick={() =>
                          handleOptionSelected(filterIndex, option)
                        }
                      >
                        <p>{option.label}</p>
                        {JSON.stringify(option.value) ===
                        JSON.stringify(
                          filtersSelected[filterIndex].option.value
                        ) ? (
                            <FontAwesomeIcon
                              icon={faX}
                              className="TableFiltersMobile__Content_Item_Option_Icon"
                            />
                          ) : (
                            <></>
                          )}
                      </button>
                    )
                  })}
                </div>
              ))}
            </div>
          </CSSTransition>
        </div>
      ) : (
        <></>
      )}
      <div className="TableFiltersMobile">
        <button
          className="TableFiltersMobile__Title"
          onClick={toggleShowOrders}
        >
          <span className="text-5 text-secondary">Order by</span>
          <span className="text-5 text-primary">A-Z</span>
        </button>
        <CSSTransition in={showOrders} timeout={300} unmountOnExit>
          <div className="TableFiltersMobile__Content">
            <div className="TableFiltersMobile__Content_Title">
              <p className="text-2">Order by</p>
              <button
                className={'TableFiltersMobile__Content_Title_Button'}
                onClick={toggleShowOrders}
              >
                <IAngleUp width={20} height={20} className="-rotate-90" />
              </button>
            </div>
            {headers.map((header) =>
              header.value.length !== 0 ? (
                <button
                  key={header.id}
                  className={classNames(
                    'TableFiltersMobile__Content_Item_Option justify-content-between',
                    { Active: header.value === sort.value }
                  )}
                  onClick={() => handleSort(header.value)}
                >
                  <span className="text-blue_primary">{header.label}</span>

                  {sort.value === header.value ? (
                    <IAngleUp
                      className={classNames('text-2 text-blue_primary', {
                        rotate_180: sort.bool,
                      })}
                    />
                  ) : (
                    <></>
                  )}
                </button>
              ) : undefined
            )}
          </div>
        </CSSTransition>
      </div>
    </>
  )
}

export default TableFiltersMobile
