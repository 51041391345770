import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { IMoveSub } from '../../iconComponents'
import useReportContext from '../../hooks/useReportContext'
import classNames from 'classnames'
import Switch from '../Switch'
import { PageSingle } from '../../types/generateReport'

type Props = {
  subPage: PageSingle;
};

export default function PagesSortSubOverlay({ subPage }: Props) {
  const { idSelected } = useReportContext()
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: subPage.id })

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <div
      className={classNames('PagesSort__Item_Header Overlay', {
        Active: subPage.id === idSelected ,
      })}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className="PagesSort__Item_Header_Icon" {...listeners}>
        <IMoveSub
          width={24}
          height={24}
        />
      </div>
      <p className="text-6 PagesSort__Item_Header_text">{subPage.label}</p>
      <div className='PagesSort__Item_Header_Switch'>
        <Switch
          active={subPage.active}
          size="Small"
          color="Pink"
        />
      </div>
    </div>
  )
}
