import { useEffect, useState, Dispatch, SetStateAction, createContext, useMemo } from 'react'
import { QueryObserverResult, useQuery } from 'react-query'
import { getFlowDecisions, getSimulationDetails } from '../api/simulations/general-simulation'
import { getHotWaterSystems } from '../api/simulations/input-parameters/existing-hot-water-system-details'
import { getDailyProfiles } from '../api/simulations/input-parameters/hot-water-demand'
import { getPVModels } from '../api/simulations/system-parameters/pv-field'
import { getPanelModels } from '../api/simulations/system-parameters/solar-thermal-field'
import useAppContext from '../hooks/useAppContext'
import useModalsContext from '../hooks/useModalsContext'
import { emptyFlowDecisions, emptySimulation, emptySimulationDetails, steps } from '../constants'
import { FlowDecisions } from '../api/apiTypes'
import { useLocalStorage } from 'usehooks-ts'
import { getSimulationDetails as newGetSimulationDetails } from '../api/newReport'
import { SimulationDetails } from '../types/simulation'

interface NewSimulationContextInterface {
  newSimulation: any,
  setNewSimulation: Dispatch<SetStateAction<any>>,
  createNewSimulation: (edit?: boolean) => Promise<void>,
  // updateNewSimulation: (step?: string, response?: any) => Promise<void>,
  updateNewSimulation: () => Promise<void>,
  resetNewSimulation: () => void,
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  isStepLoading: boolean;
  setIsStepLoading: Dispatch<SetStateAction<boolean>>;
  currentFormik: any,
  setCurrentFormik: Dispatch<SetStateAction<any>>,
  skip: boolean,
  setSkip: Dispatch<SetStateAction<boolean>>,
  asideSteps: any,
  flowDecisions: FlowDecisions,
  isFlowDecisionsLoading: boolean,
  waterProfiles: any[],
  setWaterProfiles: Dispatch<SetStateAction<any[]>>,
  waterSystems: any[],
  setWaterSystems: Dispatch<SetStateAction<any[]>>,
  panelModels: any[],
  setPanelModels: Dispatch<SetStateAction<any[]>>,
  pvModels: any[],
  setPvModels: Dispatch<SetStateAction<any[]>>,
  getExtraData: () => Promise<void>,

  simulationId: number | null;
  setSimulationId: Dispatch<SetStateAction<number | null>>;

  serverSimulationDetail: Awaited<ReturnType<typeof newGetSimulationDetails>>,
  refetchSimulationDetail: () => Promise<
    QueryObserverResult<
      Awaited<ReturnType<typeof newGetSimulationDetails>>,
      unknown
    >
  >;
  isSimulationDetailFetching: boolean;
  simulationDetails: SimulationDetails,
}

export const NewSimulationContext = createContext<NewSimulationContextInterface>(null)

const NewSimulationContextProvider = ({ children }) => {
  const { token } = useAppContext()
  const { setShowContinueSimulationModal } = useModalsContext()
  const [newSimulation, setNewSimulation] = useState<any>(emptySimulation)
  const [simulationId, setSimulationId] = useLocalStorage<number>('simulation_id', null)
  const [isLoading, setIsLoading] = useState(false)
  const [isStepLoading, setIsStepLoading] = useState(false)
  const [currentFormik, setCurrentFormik] = useState('')
  const [skip, setSkip] = useState(false)
  const [asideSteps] = useState([
    {
      title: 'Project details',
      path: 'general-information',
      main: true,
      value: 1,
      group: 1,
      disabled: false,
    }, {
      title: 'General information',
      path: 'general-information',
      main: false,
      value: 1,
      group: 1,
      disabled: false,
    }, {
      title: 'Location details',
      path: 'location-details',
      main: false,
      value: 2,
      group: 1,
      disabled: false,
    }, {
      title: 'Demand Specifications',
      path: 'hot-water-demand',
      main: true,
      value: 3,
      group: 2,
      disabled: false,
    }, {
      title: 'Hot water demand',
      path: 'hot-water-demand',
      main: false,
      value: 3,
      group: 2,
      disabled: false,
    }, {
      title: 'Electricity demand',
      path: 'electricity-demand',
      main: false,
      value: 4,
      group: 2,
      disabled: false,
    }, {
      title: 'Hot water system / roof & plant',
      path: 'hot-water-system',
      main: false,
      value: 5,
      group: 2,
      disabled: false,
    }, {
      title: 'Environmental indicators',
      path: 'environmental-indicators',
      main: false,
      value: 6,
      group: 2,
      disabled: false,
    }, {
      title: 'System parameters',
      path: 'solar-thermal-field',
      main: true,
      value: 7,
      group: 3,
      disabled: false,
    }, {
      title: 'Solar thermal field',
      path: 'solar-thermal-field',
      main: false,
      value: 7,
      group: 3,
      disabled: false,
    }, {
      title: 'Solar storage',
      path: 'solar-storage',
      main: false,
      value: 8,
      group: 3,
      disabled: false,
    }, {
      title: 'Auxiliary heating system',
      path: 'auxiliary-heating-system',
      main: false,
      value: 9,
      group: 3,
      disabled: false,
    }, {
      title: 'PV field',
      path: 'pv-field',
      main: false,
      value: 10,
      group: 3,
      disabled: false,
    }, {
      title: 'Inverter',
      path: 'inverter',
      main: false,
      value: 11,
      group: 3,
      disabled: false,
    }, {
      title: 'System performance aging',
      path: 'system-performance-aging',
      main: false,
      value: 12,
      group: 3,
      disabled: false,
    }, {
      title: 'Financial parameters',
      path: 'energy-price',
      main: true,
      value: 13,
      group: 4,
      disabled: false,
    }, {
      title: 'Energy price',
      path: 'energy-price',
      main: false,
      value: 13,
      group: 4,
      disabled: false,
    }, {
      title: 'Economic parameters',
      path: 'economic-parameters',
      main: false,
      value: 14,
      group: 4,
      disabled: false,
    }, {
      title: 'Subsidy & tax benefits',
      path: 'subsidy-&-tax-benefits',
      main: false,
      value: 15,
      group: 4,
      disabled: false,
    }, {
      title: 'System costing',
      path: 'system-costing',
      main: false,
      value: 16,
      group: 4,
      disabled: false,
    }, {
      title: 'Uncertainty',
      path: 'uncertainty',
      main: false,
      value: 17,
      group: 4,
      disabled: false,
    }, {
      title: 'PPA financial model',
      path: 'ppa-financial-model',
      main: false,
      value: 18,
      group: 4,
      disabled: false,
    }
  ])


  const { data: flowDecisions, refetch: refetchFlowDecisions, isLoading: isFlowDecisionsLoading } = useQuery(
    ['flowDecisions', newSimulation.id],
    () => {
      const simulationId = Number(localStorage.getItem('simulation_id'))
      if (!isNaN(simulationId)) return getFlowDecisions(token, simulationId)
      else return emptyFlowDecisions
    },
    {
      initialData: emptyFlowDecisions,
      enabled:
        token !== '' &&
        newSimulation.id !== null &&
        newSimulation.id === Number(localStorage.getItem('simulation_id')),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )
  const { data: serverSimulationDetail, refetch: refetchSimulationDetail, isFetching: isSimulationDetailFetching } = useQuery(
    ['SimulationDetails', simulationId],
    () => newGetSimulationDetails(token, simulationId),
    {
      initialData: {
        response: new Response(),
        data: {
          data: emptySimulationDetails,
        },
        ok: true,
      },
      enabled:
        token !== '' &&
        Boolean(simulationId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )


  const simulationDetails: SimulationDetails = useMemo(
    () =>
      serverSimulationDetail?.ok
        ? serverSimulationDetail.data.data
        : emptySimulationDetails,
    [serverSimulationDetail]
  )

  // Modals data
  const [waterProfiles, setWaterProfiles] = useState([])
  const [waterSystems, setWaterSystems] = useState([])
  const [panelModels, setPanelModels] = useState([])
  const [pvModels, setPvModels] = useState([])

  // Get started simulation
  useEffect(() => {
    if (token && newSimulation.id) {
      if (window.location.pathname.includes('create-simulation') && !window.location.pathname.includes('step')) {
        setIsLoading(true)
        createNewSimulation()
      } else {
        setShowContinueSimulationModal(true)
      }
    }
  }, [token])

  // Get extra data
  useEffect(() => {
    if (token && newSimulation.id) {
      getExtraData()
    }
  }, [token, newSimulation.id])

  const createNewSimulation = async () => {
    // Get id and simulations
    const id = Number(localStorage.getItem('simulation_id'))

    if (id) {
      // Get current simulation
      const simulation = await getSimulationDetails(token, id)

      if (simulation) {
        // Get step values
        const stepPath = steps.find(item => item.backendStep == simulation?.step_status)?.path
        const currentStep: any = stepPath != 'finished' ? stepPath : 'ppa-financial-model'
        const lastStep = steps.find(item => item.backendStep == simulation?.last_step_done)
        const lastStepPath = lastStep?.path

        // Get current flow decisions
        const { data: currentFlowDecisions } = await refetchFlowDecisions()

        // Get currency data
        const currencyData = simulation?.currency_selected
        const navigatorLanguage = navigator.languages.find(language => language.includes('-')) || 'en-US'
        const currencySymbol = Number(0).toLocaleString(navigatorLanguage, {
          style: 'currency',
          currency: currencyData?.code || 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).replace(/\d/g, '').trim()

        // Set new simulation
        const LocationDetails = simulation.location
        const HotWaterDemandDetails = simulation.demand_detail
        const ElectricityDemandDetails = simulation.electricity_demand
        const HotWaterSystemDetails = simulation.hot_water_system
        const RoofAndPlantDetails = simulation.roof_and_plant_detail
        const EnviromentalIndicatorsDetails = simulation.environmental_indicators
        const SolarThermalFieldDetails = simulation.solar_thermal_field
        const SolarStorageDetails = simulation.solar_storage
        const AuxiliaryHeatingSystemDetails = simulation.new_auxiliary_heating_systems
        const PVFieldDetails = simulation.pv_field
        const InverterDetails = simulation.inverter
        const SystemPerformanceAgingDetails = simulation.system_performance_aging
        const EnergyPriceDetails = simulation.energy_prices
        const EconomicParametersDetails = simulation.economic_parameters
        const SubsidyAndTaxBenefitsDetails = simulation.subsidy_and_tax_benefits
        const SystemCostingDetails = simulation.system_costing
        const UncertaintyDetails = simulation.uncertainty
        const PPAFinancialModelDetails = simulation.PPA_financing_models

        const checkIfIsZero = (value) => {
          return value === 0 ? 0 : value || ''
        }

        const setOneAsDefault = (value) => {
          return value === 0 ? 0 : value || 1
        }

        const setZeroAsDefault = (value) => {
          return value || 0
        }

        const savedSimulation = {
          id: simulation?.id,
          current_step: currentStep || 'general-information',
          last_step: lastStepPath,
          status: simulation?.status,
          client: simulation?.client,
          steps: {
            general_information: {
              name: simulation?.name || '',
              organization: simulation?.organization || '',
              region: simulation?.region || '',
              office: simulation?.office?.id || '',
              client: simulation?.client?.id || '',
              currency_selected: simulation?.currency_selected || '',
              currency_symbol: currencySymbol || '$',
            },
            location_details: {
              country: LocationDetails?.country.id || '',
              state: LocationDetails?.state || '',
              city: LocationDetails?.city || '',
              address: LocationDetails?.address || '',
              postal_code: LocationDetails?.postal_code || '',
              weather_file: LocationDetails?.weather_file?.id || '',
              weather_file_name: LocationDetails?.weather_file?.file_name || '',
            },
            hot_water_demand: {
              sector: HotWaterDemandDetails?.sector || '',
              daily_profile: HotWaterDemandDetails?.daily_profile?.id || '',
              maximum_hot_water_demand: checkIfIsZero(HotWaterDemandDetails?.maximum_hot_water_demand),
              desired_hot_water_temperature: checkIfIsZero(HotWaterDemandDetails?.desired_hot_water_temperature),
              is_recirculation: HotWaterDemandDetails?.water_temperature ? true : false,
              water_temperature: checkIfIsZero(HotWaterDemandDetails?.water_temperature),
              f_mon: setOneAsDefault(HotWaterDemandDetails?.weekly_profile?.f_mon),
              f_tue: setOneAsDefault(HotWaterDemandDetails?.weekly_profile?.f_tue),
              f_wed: setOneAsDefault(HotWaterDemandDetails?.weekly_profile?.f_wed),
              f_thu: setOneAsDefault(HotWaterDemandDetails?.weekly_profile?.f_thu),
              f_fri: setOneAsDefault(HotWaterDemandDetails?.weekly_profile?.f_fri),
              f_sat: setOneAsDefault(HotWaterDemandDetails?.weekly_profile?.f_sat),
              f_sun: setOneAsDefault(HotWaterDemandDetails?.weekly_profile?.f_sun),
              f_jan: setOneAsDefault(HotWaterDemandDetails?.monthly_profile?.f_jan),
              f_feb: setOneAsDefault(HotWaterDemandDetails?.monthly_profile?.f_feb),
              f_mar: setOneAsDefault(HotWaterDemandDetails?.monthly_profile?.f_mar),
              f_apr: setOneAsDefault(HotWaterDemandDetails?.monthly_profile?.f_apr),
              f_may: setOneAsDefault(HotWaterDemandDetails?.monthly_profile?.f_may),
              f_jun: setOneAsDefault(HotWaterDemandDetails?.monthly_profile?.f_jun),
              f_jul: setOneAsDefault(HotWaterDemandDetails?.monthly_profile?.f_jul),
              f_aug: setOneAsDefault(HotWaterDemandDetails?.monthly_profile?.f_aug),
              f_sep: setOneAsDefault(HotWaterDemandDetails?.monthly_profile?.f_sep),
              f_oct: setOneAsDefault(HotWaterDemandDetails?.monthly_profile?.f_oct),
              f_nov: setOneAsDefault(HotWaterDemandDetails?.monthly_profile?.f_nov),
              f_dec: setOneAsDefault(HotWaterDemandDetails?.monthly_profile?.f_dec),
              will_complete: currentFlowDecisions?.hot_water_demand || false,
            },
            electricity_demand: {
              id: ElectricityDemandDetails?.id || '',
              supply_choices: ElectricityDemandDetails?.supply_choices || 'annual',
              annual_demand: checkIfIsZero(ElectricityDemandDetails?.annual_demand),
              fel_jan: checkIfIsZero(ElectricityDemandDetails?.fel_jan),
              fel_feb: checkIfIsZero(ElectricityDemandDetails?.fel_feb),
              fel_mar: checkIfIsZero(ElectricityDemandDetails?.fel_mar),
              fel_apr: checkIfIsZero(ElectricityDemandDetails?.fel_apr),
              fel_may: checkIfIsZero(ElectricityDemandDetails?.fel_may),
              fel_jun: checkIfIsZero(ElectricityDemandDetails?.fel_jun),
              fel_jul: checkIfIsZero(ElectricityDemandDetails?.fel_jul),
              fel_aug: checkIfIsZero(ElectricityDemandDetails?.fel_aug),
              fel_sep: checkIfIsZero(ElectricityDemandDetails?.fel_sep),
              fel_oct: checkIfIsZero(ElectricityDemandDetails?.fel_oct),
              fel_nov: checkIfIsZero(ElectricityDemandDetails?.fel_nov),
              fel_dec: checkIfIsZero(ElectricityDemandDetails?.fel_dec),
              will_complete: currentFlowDecisions?.electricity_demand || false,
            },
            existing_hot_water_system: {
              id: HotWaterSystemDetails?.id || '',
              system: HotWaterSystemDetails?.system?.id || '',
              other_system_name: HotWaterSystemDetails?.other_system_name || '',
              auxiliary_hot_water_system_capacity: checkIfIsZero(HotWaterSystemDetails?.auxiliary_hot_water_system_capacity),
              age_of_the_existing_system: checkIfIsZero(HotWaterSystemDetails?.age_of_the_existing_system),
              new_auxiliary_heating_system_required: HotWaterSystemDetails?.new_auxiliary_heating_system_required || false,
            },
            roof_and_plant: {
              id: RoofAndPlantDetails?.id || '',
              available_roof_space_PVT: checkIfIsZero(RoofAndPlantDetails?.available_roof_space_PVT),
              available_roof_space_PV: checkIfIsZero(RoofAndPlantDetails?.available_roof_space_PV),
              max_roof_load: checkIfIsZero(RoofAndPlantDetails?.max_roof_load),
              space_for_additional_storage_in_plant_room: RoofAndPlantDetails?.space_for_additional_storage_in_plant_room || false,
            },
            environmental_indicators: {
              id: EnviromentalIndicatorsDetails?.id || '',
              CO2_Fuel_txt: EnviromentalIndicatorsDetails?.CO2_Fuel_txt || '',
              kWH_CF_Fuel_txt: checkIfIsZero(EnviromentalIndicatorsDetails?.kWH_CF_Fuel_txt),
              n_ex_syst: checkIfIsZero(EnviromentalIndicatorsDetails?.n_ex_syst),
              electricity: checkIfIsZero(EnviromentalIndicatorsDetails?.electricity),
              CO2_saved_per_year_by_tree: checkIfIsZero(EnviromentalIndicatorsDetails?.CO2_saved_per_year_by_tree) || 24,
            },
            solar_thermal_field: {
              id: SolarThermalFieldDetails?.id || '',
              antifreeze_composition: checkIfIsZero(SolarThermalFieldDetails?.antifreeze_composition),
              include_thermal_system_loss: SolarThermalFieldDetails?.include_thermal_system_loss || false,
              panel_one: SolarThermalFieldDetails?.panels_parameters?.length > 0 ? SolarThermalFieldDetails?.panels_parameters[0] : '',
              panel_two: SolarThermalFieldDetails?.panels_parameters?.length > 0 ? SolarThermalFieldDetails?.panels_parameters[1] : '',
              will_complete: currentFlowDecisions?.include_a_t_or_pvt || false,
              another_panel: currentFlowDecisions?.include_another_t_or_pvt || false,
            },
            solar_storage: {
              total_solar_storage_volume: checkIfIsZero(SolarStorageDetails?.total_solar_storage_volume),
              total_number_buffers: checkIfIsZero(SolarStorageDetails?.total_number_buffers),
              immersed_electric_heater_in_the_buffer: SolarStorageDetails?.name_of_the_electrical_heater ? true : false,
              name_of_the_electrical_heater: SolarStorageDetails?.name_of_the_electrical_heater || '',
              capacity_of_the_electrical_heater: checkIfIsZero(SolarStorageDetails?.capacity_of_the_electrical_heater),
            },
            auxiliary_heating_system: {
              id: AuxiliaryHeatingSystemDetails?.length ? AuxiliaryHeatingSystemDetails[0].id : '',
              system_one: {
                system: AuxiliaryHeatingSystemDetails?.length ? AuxiliaryHeatingSystemDetails[0].heating_system : '',
                name: AuxiliaryHeatingSystemDetails?.length ? AuxiliaryHeatingSystemDetails[0].name_1 : '',
                capacity: AuxiliaryHeatingSystemDetails?.length ? checkIfIsZero(AuxiliaryHeatingSystemDetails[0].capacity_1) : '',
                size_of_the_buffer_connected_to_auxiliary_heater: '',
              },
              system_two: {
                system: AuxiliaryHeatingSystemDetails?.length ? AuxiliaryHeatingSystemDetails[0].solar_buffer_system : '',
                name: AuxiliaryHeatingSystemDetails?.length ? AuxiliaryHeatingSystemDetails[0].name_2 : '',
                capacity: AuxiliaryHeatingSystemDetails?.length ? checkIfIsZero(AuxiliaryHeatingSystemDetails[0].capacity_2) : '',
                size_of_the_buffer_connected_to_auxiliary_heater: AuxiliaryHeatingSystemDetails?.length ? checkIfIsZero(AuxiliaryHeatingSystemDetails[0].size_of_the_buffer_connected_to_auxiliary_heater) : '',
              },
              will_complete: currentFlowDecisions?.include_auxiliary_system || false,
              another_system: currentFlowDecisions?.include_another_auxiliary_system || false,
            },
            pv_field: {
              first_panel: {
                id: PVFieldDetails?.length ? PVFieldDetails[0].id : '',
                name: PVFieldDetails?.length ? PVFieldDetails[0].name : '',
                pv_type: PVFieldDetails?.length ? PVFieldDetails[0].model.panel_type : '',
                model: PVFieldDetails?.length ? PVFieldDetails[0].model?.id : '',
                number_of_panels: PVFieldDetails?.length ? checkIfIsZero(PVFieldDetails[0].number_of_panels) : '',
                type_of_roof: PVFieldDetails?.length ? PVFieldDetails[0].type_of_roof : '',
                panel_orientation: PVFieldDetails?.length ? checkIfIsZero(PVFieldDetails[0].panel_orientation) : '',
                panel_tilt: PVFieldDetails?.length ? checkIfIsZero(PVFieldDetails[0].panel_tilt) : '',
                number_of_panels_per_row: PVFieldDetails?.length ? checkIfIsZero(PVFieldDetails[0].number_of_panels_per_row) : '',
                distance_between_rows: PVFieldDetails?.length ? checkIfIsZero(PVFieldDetails[0].distance_between_rows) : 4.5,
              },
              second_panel: {
                id: PVFieldDetails?.length > 1 ? PVFieldDetails[1].id : '',
                name: PVFieldDetails?.length > 1 ? PVFieldDetails[1].name : '',
                pv_type: PVFieldDetails?.length > 1 ? PVFieldDetails[1].model.panel_type : '',
                model: PVFieldDetails?.length > 1 ? PVFieldDetails[1].model?.id : '',
                number_of_panels: PVFieldDetails?.length > 1 ? checkIfIsZero(PVFieldDetails[1].number_of_panels) : '',
                type_of_roof: PVFieldDetails?.length > 1 ? PVFieldDetails[1].type_of_roof : '',
                panel_orientation: PVFieldDetails?.length > 1 ? checkIfIsZero(PVFieldDetails[1].panel_orientation) : '',
                panel_tilt: PVFieldDetails?.length > 1 ? checkIfIsZero(PVFieldDetails[1].panel_tilt) : '',
                number_of_panels_per_row: PVFieldDetails?.length > 1 ? checkIfIsZero(PVFieldDetails[1].number_of_panels_per_row) : '',
                distance_between_rows: PVFieldDetails?.length > 1 ? checkIfIsZero(PVFieldDetails[1].distance_between_rows) : 4.5,
              },
              will_complete: currentFlowDecisions?.include_a_pv_field || false,
              another_field: currentFlowDecisions?.include_another_pv_field || false,
            },
            inverter: {
              id: InverterDetails?.id || '',
              include_pv_system_loss: InverterDetails?.include_pv_system_loss || false,
              DC_AC_ratio_PV: checkIfIsZero(InverterDetails?.DC_AC_ratio_PV) || 1.2,
              DC_AC_ratio_PVT: checkIfIsZero(InverterDetails?.DC_AC_ratio_PVT) || 1.2
            },
            system_performance_aging: {
              id: SystemPerformanceAgingDetails?.id || '',
              PV_electrical_out_degradation: checkIfIsZero(SystemPerformanceAgingDetails?.PV_electrical_out_degradation),
              auxiliary_heating_degradation: checkIfIsZero(SystemPerformanceAgingDetails?.auxiliary_heating_degradation),
              solar_thermal_out_degradation: checkIfIsZero(SystemPerformanceAgingDetails?.solar_thermal_out_degradation),
            },
            energy_price: {
              id: EnergyPriceDetails?.id || '',
              average_heat_price: checkIfIsZero(EnergyPriceDetails?.average_heat_price),
              average_electricity_price: checkIfIsZero(EnergyPriceDetails?.average_electricity_price),
              heat_escalation_price: checkIfIsZero(EnergyPriceDetails?.heat_escalation_price),
              electricity_price_escalation_rate: checkIfIsZero(EnergyPriceDetails?.electricity_price_escalation_rate),
              electricity_price_rise_scenario: checkIfIsZero(EnergyPriceDetails?.electricity_price_rise_scenario),
            },
            economic_parameters: {
              id: EconomicParametersDetails?.id || '',
              inflation_rate: checkIfIsZero(EconomicParametersDetails?.inflation_rate),
              discount_rate: checkIfIsZero(EconomicParametersDetails?.discount_rate),
              interest_rate: checkIfIsZero(EconomicParametersDetails?.interest_rate),
              O_and_M_escalation_rates: checkIfIsZero(EconomicParametersDetails?.O_and_M_escalation_rates),
              project_economic_lifetime: EconomicParametersDetails?.project_economic_lifetime || '',
            },
            subsidy_and_tax_benefits: {
              id: SubsidyAndTaxBenefitsDetails?.id || '',
              is_tax_subsidy_applicable: SubsidyAndTaxBenefitsDetails?.is_tax_subsidy_applicable || false,
              num_of_years_for_operational_subsidy: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.num_of_years_for_operational_subsidy),
              operational_subsidy_for_heat: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.operational_subsidy_for_heat),
              operational_subsidy_for_electricity: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.operational_subsidy_for_electricity),
              is_operational_subsidy_applicable: SubsidyAndTaxBenefitsDetails?.num_of_years_for_operational_subsidy ? true : false,
              tax_subsidy: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.tax_subsidy),
              number_of_years: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.number_of_years),
              year_1: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_1),
              year_2: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_2),
              year_3: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_3),
              year_4: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_4),
              year_5: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_5),
              year_6: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_6),
              year_7: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_7),
              year_8: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_8),
              year_9: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_9),
              year_10: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_10),
              year_11: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_11),
              year_12: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_12),
              year_13: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_13),
              year_14: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_14),
              year_15: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_15),
              year_16: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_16),
              year_17: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_17),
              year_18: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_18),
              year_19: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_19),
              year_20: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_20),
              year_21: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_21),
              year_22: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_22),
              year_23: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_23),
              year_24: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_24),
              year_25: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.year_25),
              is_funding_or_grant_applicable: SubsidyAndTaxBenefitsDetails?.is_funding_or_grant_applicable || false,
              description_of_funding_or_grant: SubsidyAndTaxBenefitsDetails?.description_of_funding_or_grant || '',
              funding_or_grant_amount: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.funding_or_grant_amount),
              co2_tax: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.co2_tax),
              co2_tax_escalation_rate: checkIfIsZero(SubsidyAndTaxBenefitsDetails?.co2_tax_escalation_rate),
            },
            system_costing: {
              id: SystemCostingDetails?.id || '',
              currency_conversion_factor: setZeroAsDefault(SystemCostingDetails?.currency_conversion_factor),
              total_PVT_system_cost: setZeroAsDefault(SystemCostingDetails?.total_PVT_system_cost),
              total_thermal_system_cost: setZeroAsDefault(SystemCostingDetails?.total_thermal_system_cost),
              total_PV_system_cost: setZeroAsDefault(SystemCostingDetails?.total_PV_system_cost),
              total_auxiliary_system_cost: setZeroAsDefault(SystemCostingDetails?.total_auxiliary_system_cost),
              other_costs: setZeroAsDefault(SystemCostingDetails?.other_costs),
              contingency_project: setZeroAsDefault(SystemCostingDetails?.contingency_project),
              contingency_civil: setZeroAsDefault(SystemCostingDetails?.contingency_civil),
              margin_for_third_party: setZeroAsDefault(SystemCostingDetails?.margin_for_third_party),
              discount: setZeroAsDefault(SystemCostingDetails?.discount),
              O_and_M_PVT_cost: setZeroAsDefault(SystemCostingDetails?.O_and_M_PVT_cost),
              O_and_M_thermal_cost: setZeroAsDefault(SystemCostingDetails?.O_and_M_thermal_cost),
              O_and_M_PV_cost: setZeroAsDefault(SystemCostingDetails?.O_and_M_PV_cost),
              O_and_M_auxiliary_cost: setZeroAsDefault(SystemCostingDetails?.O_and_M_auxiliary_cost),
              system_insurance: setZeroAsDefault(SystemCostingDetails?.system_insurance),
              monitoring_and_metering_charges: setZeroAsDefault(SystemCostingDetails?.monitoring_and_metering_charges),
              year_of_solar_equipment_replacement: setZeroAsDefault(SystemCostingDetails?.year_of_solar_equipment_replacement),
              replacement_PVT_system_cost: setZeroAsDefault(SystemCostingDetails?.replacement_PVT_system_cost),
              replacement_thermal_system_cost: setZeroAsDefault(SystemCostingDetails?.replacement_thermal_system_cost),
              replacement_PV_system_cost: setZeroAsDefault(SystemCostingDetails?.replacement_PV_system_cost),
              year_of_auxilairy_system_replacement: setZeroAsDefault(SystemCostingDetails?.year_of_auxilairy_system_replacement),
              replacement_auxiliary_heaying_system_cost: setZeroAsDefault(SystemCostingDetails?.replacement_auxiliary_heaying_system_cost),
            },
            uncertainty: {
              id: UncertaintyDetails?.id || '',
              cost_uncertainty: setZeroAsDefault(UncertaintyDetails?.cost_uncertainty),
              heat_production_uncertainty: setZeroAsDefault(UncertaintyDetails?.heat_production_uncertainty),
              electricity_production_uncertainty: setZeroAsDefault(UncertaintyDetails?.electricity_production_uncertainty),
              cash_flow_year_1: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_1),
              cash_flow_year_2: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_2),
              cash_flow_year_3: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_3),
              cash_flow_year_4: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_4),
              cash_flow_year_5: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_5),
              cash_flow_year_6: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_6),
              cash_flow_year_7: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_7),
              cash_flow_year_8: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_8),
              cash_flow_year_9: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_9),
              cash_flow_year_10: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_10),
              cash_flow_year_11: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_11),
              cash_flow_year_12: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_12),
              cash_flow_year_13: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_13),
              cash_flow_year_14: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_14),
              cash_flow_year_15: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_15),
              cash_flow_year_16: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_16),
              cash_flow_year_17: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_17),
              cash_flow_year_18: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_18),
              cash_flow_year_19: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_19),
              cash_flow_year_20: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_20),
              cash_flow_year_21: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_21),
              cash_flow_year_22: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_22),
              cash_flow_year_23: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_23),
              cash_flow_year_24: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_24),
              cash_flow_year_25: setZeroAsDefault(UncertaintyDetails?.cash_flow_year_25),
            },
            ppa_financial_model: {
              id: PPAFinancialModelDetails?.id || '',
              period_1: {
                id: PPAFinancialModelDetails?.period_1.id || '',
                number_of_years: checkIfIsZero(PPAFinancialModelDetails?.period_1.number_of_years),
                electricity_price_per_kwh: checkIfIsZero(PPAFinancialModelDetails?.period_1.electricity_price_per_kwh),
                electricity_escalation_rate: checkIfIsZero(PPAFinancialModelDetails?.period_1.electricity_escalation_rate),
                heat_price_per_kwh: checkIfIsZero(PPAFinancialModelDetails?.period_1.heat_price_per_kwh),
                heat_escalation_rate: checkIfIsZero(PPAFinancialModelDetails?.period_1.heat_escalation_rate),
              },
              period_2: {
                id: PPAFinancialModelDetails?.period_2.id || '',
                number_of_years: checkIfIsZero(PPAFinancialModelDetails?.period_2.number_of_years),
                electricity_price_per_kwh: checkIfIsZero(PPAFinancialModelDetails?.period_2.electricity_price_per_kwh),
                electricity_escalation_rate: checkIfIsZero(PPAFinancialModelDetails?.period_2.electricity_escalation_rate),
                heat_price_per_kwh: checkIfIsZero(PPAFinancialModelDetails?.period_2.heat_price_per_kwh),
                heat_escalation_rate: checkIfIsZero(PPAFinancialModelDetails?.period_2.heat_escalation_rate),
              },
              period_3: {
                id: PPAFinancialModelDetails?.period_3.id || '',
                number_of_years: checkIfIsZero(PPAFinancialModelDetails?.period_3.number_of_years),
                electricity_price_per_kwh: checkIfIsZero(PPAFinancialModelDetails?.period_3.electricity_price_per_kwh),
                electricity_escalation_rate: checkIfIsZero(PPAFinancialModelDetails?.period_3.electricity_escalation_rate),
                heat_price_per_kwh: checkIfIsZero(PPAFinancialModelDetails?.period_3.heat_price_per_kwh),
                heat_escalation_rate: checkIfIsZero(PPAFinancialModelDetails?.period_3.heat_escalation_rate),
              },
              will_complete: currentFlowDecisions.PPA_option || false,
            }
          }
        }

        // console.log('\n\n==== Create ====')
        // console.log(savedSimulation)
        // console.log('================\n\n')

        setNewSimulation(savedSimulation)
      }
    }

    setIsLoading(false)
  }

  const updateNewSimulation = async () => {
    await createNewSimulation()
  }

  const resetNewSimulation = () => {
    setSimulationId(null)
    setNewSimulation(emptySimulation)
  }

  const getExtraData = async () => {
    const responseOne = await getDailyProfiles(token, newSimulation.id) || []

    // console.log('newSimulation.id', newSimulation)
    // console.log('responseOne', responseOne)

    responseOne.sort((a: any, b: any) => a.is_admin_creation - b.is_admin_creation)
    setWaterProfiles(responseOne)

    const responseTwo = await getHotWaterSystems(token) || []
    responseTwo.sort((a: any, b: any) => a.is_admin_creation - b.is_admin_creation)
    setWaterSystems(responseTwo)

    const responseThree = await getPanelModels(token) || []
    responseThree.sort((a: any, b: any) => a.is_admin_creation - b.is_admin_creation)
    setPanelModels(responseThree)

    const responseFour = await getPVModels(token) || []
    responseFour.sort((a: any, b: any) => a.is_admin_creation - b.is_admin_creation)
    setPvModels(responseFour)
  }

  return (
    <NewSimulationContext.Provider
      value={{
        newSimulation,
        setNewSimulation,
        createNewSimulation,
        updateNewSimulation,
        resetNewSimulation,

        simulationId,
        setSimulationId,
        serverSimulationDetail,
        refetchSimulationDetail,
        isSimulationDetailFetching,
        simulationDetails,

        isLoading,
        setIsLoading,
        isStepLoading,
        setIsStepLoading,

        currentFormik,
        setCurrentFormik,

        skip,
        setSkip,

        asideSteps,

        flowDecisions,
        isFlowDecisionsLoading,

        waterProfiles,
        setWaterProfiles,
        waterSystems,
        setWaterSystems,
        panelModels,
        setPanelModels,
        pvModels,
        setPvModels,

        getExtraData,
      }}>
      {children}
    </NewSimulationContext.Provider>
  )
}

export default NewSimulationContextProvider
