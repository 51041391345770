import { useMemo, useState } from 'react'
import { Client } from '../../api/apiTypes'
import Modal from './Modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CheckModal from './CheckModal'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useQuery } from 'react-query'
import { getCountries, getOrganizations } from '../../api/organizations'
import useAppContext from '../../hooks/useAppContext'
import { updateClient } from '../../api/clients'
import Input from '../Input'
import SearchInput from '../SearchInput'
import PhoneNumberInput from '../PhoneNumberInput'
import LoadingDots from '../LoadingDots'
import { IAlert } from '../../iconComponents'
import Button from '../buttons/Button'

interface Props {
  closeModal: () => void;
  clientInfo: Client;
  refetch: () => void;
}

const EditClientModal = ({ closeModal, clientInfo, refetch }: Props) => {
  const { token, isCentralAdmin, isOrganizationAdmin, isRegionAdmin } = useAppContext()
  const [step, setStep] = useState(1)
  const [serverError, setServerError] = useState(false)
  const initialData = { page: 1, total_items: 0, total_pages: 1, data: [] }
  const { data: organizatoins } = useQuery(
    ['getOrganizations'],
    () => getOrganizations(token, initialData),
    {
      initialData,
      enabled: token !== '' && isCentralAdmin,
    }
  )
  const organizatoinsForInput = useMemo(
    () =>
      organizatoins.data?.map((organizatoin) => ({
        value: organizatoin.id,
        label: organizatoin.name,
      })),
    [organizatoins]
  )

  const { data } = useQuery(['getCountries'], () => getCountries(token), {
    initialData: {
      user_countries: [],
      all_countries: [],
    },
    enabled: token !== '',
  })
  const countries = useMemo(() => {
    const userCountries = data.user_countries.map((country) => ({
      value: country.id,
      label: country.name,
    }))
    const allCountries = data.all_countries.map((country) => ({
      value: country.id,
      label: country.name,
    }))
    return [...userCountries, ...allCountries]
  }, [data])

  const isEditable = useMemo(
    () =>
      (!(isCentralAdmin || isOrganizationAdmin || isRegionAdmin) &&
      (clientInfo.simulation_count === 0)) || isCentralAdmin || isOrganizationAdmin || isRegionAdmin,
    [isCentralAdmin, isOrganizationAdmin, isRegionAdmin, clientInfo]
  )

  const formik = useFormik<{
    name: string;
    country: number | null;
    organization: number | null;
    company: string;
    role: string;
    address: string;
    email: string;
    phone: string;
  }>({
    initialValues: {
      name: clientInfo.name,
      organization: clientInfo.organization_id,
      country: clientInfo.country_id,
      company: clientInfo.company ?? '',
      role: clientInfo.role ?? '',
      address: clientInfo.address,
      email: clientInfo.email,
      phone: clientInfo.phone,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      country: Yup.number().required('Required'),
      organization: Yup.number().required('Required'),
      company: Yup.string(),
      role: Yup.string(),
      address: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      phone: Yup.string()
        .required('Required')
        .test((value) => isValidPhoneNumber(value)),
    }),
    onSubmit: async (values) => {
      const response = await updateClient(
        token,
        { client_id: clientInfo.id },
        values
      )

      if (
        response.name &&
        response.name.includes(
          'The fields name, organization must make a unique set.'
        )
      )
        formik.setFieldError('name', 'Client with this name already exists')
      else if (
        response.error?.non_field_errors &&
        response.error.non_field_errors.includes(
          'The fields name, organization must make a unique set.'
        )
      )
        formik.setFieldError('name', 'Client with this name already exists')
      else if (response.error) setServerError(true)
      else if (response) {
        setStep(2)
        refetch()
      }
    },
  })

  // si el usuario es operador y si el cliente tiene silumaciones (no puede editar el nombre y el pais) (no puede eliminar)

  if (step === 1)
    return (
      <Modal title={`Edit ${clientInfo.name}`} closeModal={closeModal} notCloseOnBlur>
        <div className="Modal__Scroll-Content d-flex flex-column align-items-center gap-3 py-3">
          {!isEditable ? (
            <>
              <div className="d-flex align-items-center gap-2 ">
                <div className="flex-shrink-0 d-flex justify-content-center align-items-center p-2 bg-transparent_red rounded-full">
                  <IAlert width={12} height={12} className="text-red" />
                </div>
                <div
                  className="text-5 py-2 px-3 bg-transparent_red"
                  style={{ borderRadius: '30px' }}
                >
                  You cannot edit the name and country of this client because it has simulations on
                  course.
                </div>
              </div>
              <p className="text-5 ">
                If you need to edit or delete this client, get in contact with
                your administrator.
              </p>
            </>
          ) : (
            <></>
          )}

          <Input
            label="Name"
            name="name"
            placeholder="Enter your name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
            disabled={!isEditable}
          />

          {isCentralAdmin && (
            <SearchInput
              label="Organization"
              name="organization"
              placeholder="Choose organization"
              search={organizatoinsForInput}
              value={Number(formik.values.organization)}
              onChange={(id) => formik.setFieldValue('organization', id)}
              error={formik.errors.organization}
              disabled
            />
          )}

          <SearchInput
            label="Country"
            name="country"
            placeholder="Select a country"
            search={countries}
            value={formik.values.country}
            onChange={(id) => formik.setFieldValue('country', id)}
            error={formik.errors.country}
          />

          <Input
            label="Company"
            name="company"
            placeholder="Company"
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.errors.company}
          />

          <Input
            label="Role"
            name="role"
            placeholder="Role"
            value={formik.values.role}
            onChange={formik.handleChange}
            error={formik.errors.role}
          />

          <Input
            label="Address"
            name="address"
            placeholder="Address"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.errors.address}
          />

          <Input
            label="Email"
            name="email"
            type='email'
            placeholder="someone@gmail.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
          />

          <PhoneNumberInput
            label="Phone number"
            name="phone"
            value={formik.values.phone}
            onChange={(value) => formik.setFieldValue('phone', value)}
            errors={formik.errors.phone}
          />

          {serverError && <p className="text-red">Something went wrong</p>}

          <Button
            className="gap-0 mx-auto w-100"
            type="submit"
            onClick={() => formik.handleSubmit()}
            disabled={
              formik.isSubmitting
            }
          >
            {formik.isSubmitting ? (
              <>
                <span>Saving</span>
                <LoadingDots className="enter-done" />
              </>
            ) : (
              <span>Save changes</span>
            )}
          </Button>
        </div>
      </Modal>
    )

  return (
    <CheckModal
      closeModal={closeModal}
      title={`Edit ${clientInfo.name}`}
      message="¡Client edited succesfully!"
    />
  )
}

export default EditClientModal
