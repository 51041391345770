import { NavLink } from 'react-router-dom'
import AccountActions from '../components/AccountActions'
import Logo from '../assets/images/logo.png'

const Header = () => {
  return (
    <>
      <header className='Header d-flex justify-content-between align-items-center'>
        <NavLink to='/'>
          <img src={Logo} alt="Makt logo" className='Header__Logo' />
        </NavLink>
        <AccountActions />
      </header>
    </>
  )
}

export default Header