import { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import {
  createPanelModel,
  getPanelModelDetails,
  updatePanelModel,
} from '../../api/simulations/system-parameters/solar-thermal-field'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import useModalsContext from '../../hooks/useModalsContext'
import Input from '../Input'
import InputSmall from '../InputSmall'
import Modal from './Modal'
import CheckModal from './CheckModal'
import ErrorIcon from '../../assets/icons/warning-red.svg'
import {
  normalizePercentToSend,
  normalizePercentToShow,
  threeDecimalRegex,
} from '../../constants'
import LoadingDots from '../LoadingDots'
import Button from '../buttons/Button'

const NewModelModal = () => {
  const { token } = useAppContext()
  const { getExtraData } = useNewSimulationContext()
  const { panelModelTypeSelected, modalId, closeShowNewModelModal, toggleShowManageModelsModal } =
    useModalsContext()
  const [step, setStep] = useState(1)
  const [save, setSave] = useState(false)
  const [serverError, setServerError] = useState(false)

  const modelType = useMemo(() => {
    if (panelModelTypeSelected.panelSelected == 'firstPanel') {
      return panelModelTypeSelected.firstPanel
    } else if (panelModelTypeSelected.panelSelected == 'secondPanel') {
      return panelModelTypeSelected.secondPanel
    }
  }, [panelModelTypeSelected])

  const yupNumberValidation = Yup.mixed()
    .required('Required')
    .transform((value) => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', (value) => !isNaN(value))
    .test(
      'decimals',
      'Max: 3 decimals',
      (value) => !threeDecimalRegex.test(value)
    )

  const formikStepOne = useFormik({
    initialValues: {
      model_name: '',
      panel_type: modelType || '',
      power_thermal_capacity: '',
      gross_area: '',
      aperture_area: '',
      collector_height: '',
      collector_width: '',
      optical_efficiency: null,
      kd: '',
      c1: '',
      c2: '',
      c3: '',
      c4: '',
      c5: '',
      c6: '',
      transversal_10: '',
      transversal_20: '',
      transversal_30: '',
      transversal_40: '',
      transversal_50: '',
      transversal_60: '',
      transversal_70: '',
      transversal_80: '',
      transversal_90: '',
      longitudinal_10: '',
      longitudinal_20: '',
      longitudinal_30: '',
      longitudinal_40: '',
      longitudinal_50: '',
      longitudinal_60: '',
      longitudinal_70: '',
      longitudinal_80: '',
      longitudinal_90: '',
      transversal_error: '',
      longitudinal_error: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      model_name: Yup.string().required('Required'),
      panel_type: Yup.string().required('Required'),
      power_thermal_capacity: yupNumberValidation,
      gross_area: yupNumberValidation,
      aperture_area: yupNumberValidation,
      collector_height: yupNumberValidation,
      collector_width: yupNumberValidation,
      optical_efficiency: yupNumberValidation
        .test('min', 'Min: 0%', (value) => value >= 0)
        .test('max', 'Max: 100%', (value) => value <= 100),
      kd: yupNumberValidation,
      c1: yupNumberValidation,
      c2: yupNumberValidation,
      c3: yupNumberValidation,
      c4: yupNumberValidation,
      c5: yupNumberValidation,
      c6: yupNumberValidation,
    }),
    validate: (values) => {
      const errors = {
        transversal_error: '',
        longitudinal_error: '',
      }

      const tansversalValues = {
        transversal_10:
          values.transversal_10 === '' ? '' : Number(values.transversal_10),
        transversal_20:
          values.transversal_20 === '' ? '' : Number(values.transversal_20),
        transversal_30:
          values.transversal_30 === '' ? '' : Number(values.transversal_30),
        transversal_40:
          values.transversal_40 === '' ? '' : Number(values.transversal_40),
        transversal_50:
          values.transversal_50 === '' ? '' : Number(values.transversal_50),
        transversal_60:
          values.transversal_60 === '' ? '' : Number(values.transversal_60),
        transversal_70:
          values.transversal_70 === '' ? '' : Number(values.transversal_70),
        transversal_80:
          values.transversal_80 === '' ? '' : Number(values.transversal_80),
        transversal_90:
          values.transversal_90 === '' ? '' : Number(values.transversal_90),
      }

      const longitudinalValues = {
        longitudinal_10:
          values.longitudinal_10 === '' ? '' : Number(values.longitudinal_10),
        longitudinal_20:
          values.longitudinal_20 === '' ? '' : Number(values.longitudinal_20),
        longitudinal_30:
          values.longitudinal_30 === '' ? '' : Number(values.longitudinal_30),
        longitudinal_40:
          values.longitudinal_40 === '' ? '' : Number(values.longitudinal_40),
        longitudinal_50:
          values.longitudinal_50 === '' ? '' : Number(values.longitudinal_50),
        longitudinal_60:
          values.longitudinal_60 === '' ? '' : Number(values.longitudinal_60),
        longitudinal_70:
          values.longitudinal_70 === '' ? '' : Number(values.longitudinal_70),
        longitudinal_80:
          values.longitudinal_80 === '' ? '' : Number(values.longitudinal_80),
        longitudinal_90:
          values.longitudinal_90 === '' ? '' : Number(values.longitudinal_90),
      }

      const tansversalValuesArray = Object.values(tansversalValues)
      const hasTransversalEmptyValues = tansversalValuesArray.some(
        (value) => value === ''
      )
      const hasTransversalInvalidValues = tansversalValuesArray.some(
        (value) => typeof value !== 'number' || isNaN(value)
      )
      const hasTransversalInvalidMinMaxValues = tansversalValuesArray.some(
        (value) => Number(value) < 0 || Number(value) > 1
      )
      const hasTransversalMaxDecimals = tansversalValuesArray.some((value) =>
        threeDecimalRegex.test(String(value))
      )

      const longitudinalValuesArray = Object.values(longitudinalValues)
      const hasLongitudinalEmptyValues = longitudinalValuesArray.some(
        (value) => value === ''
      )
      const hasLongitudinalInvalidValues = longitudinalValuesArray.some(
        (value) => typeof value !== 'number' || isNaN(value)
      )
      const hasLongitudinalInvalidMinMaxValues = longitudinalValuesArray.some(
        (value) => Number(value) < 0 || Number(value) > 1
      )
      const hasLongitudinalMaxDecimals = longitudinalValuesArray.some((value) =>
        threeDecimalRegex.test(String(value))
      )

      // Transversal Errors
      if (hasTransversalEmptyValues) {
        errors.transversal_error = 'Complete all fields'
      } else if (hasTransversalInvalidValues) {
        errors.transversal_error =
          'There are invalid values, only numbers are allowed'
      } else if (hasTransversalInvalidMinMaxValues) {
        errors.transversal_error =
          'Invalid values: all values must be between 0 and 1'
      } else if (hasTransversalMaxDecimals) {
        errors.transversal_error =
          'Invalid values: all values must have a maximum of 3 decimals'
      }

      // Longitudinal Errors
      if (hasLongitudinalEmptyValues) {
        errors.longitudinal_error = 'Complete all fields'
      } else if (hasLongitudinalInvalidValues) {
        errors.longitudinal_error =
          'There are invalid values, only numbers are allowed'
      } else if (hasLongitudinalInvalidMinMaxValues) {
        errors.longitudinal_error =
          'Invalid values: all values must be between 0 and 1'
      } else if (hasLongitudinalMaxDecimals) {
        errors.longitudinal_error =
          'Invalid values: all values must have a maximum of 3 decimals'
      }

      if (errors.transversal_error || errors.longitudinal_error) return errors
      else return
    },
    onSubmit: async (values) => {
      panelModelTypeSelected[panelModelTypeSelected.panelSelected]
      if (panelModelTypeSelected[panelModelTypeSelected.panelSelected] == 'T') {
        handleSubmit(values)
      } else {
        setStep(2)
      }
    },
  })
  const formikStepTwo = useFormik({
    initialValues: {
      peak_eletricity_capacity: '',
      panel_efficiency_capacity: null,
      maximum_power_point_voltage: '',
      maximum_power_point_current: '',
      open_circuit_voltage: '',
      short_circuit_current: '',
      temperature_coefficient_max_power_point: null,
      temperature_coefficient_open_circuit_voltage_yVoc: null,
      temperature_coefficient_open_circuit_voltage_ylsc: null,
      nominal_operating_cell_temperature: '',
      number_of_cells: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      peak_eletricity_capacity: yupNumberValidation,
      panel_efficiency_capacity: yupNumberValidation,
      maximum_power_point_voltage: yupNumberValidation,
      maximum_power_point_current: yupNumberValidation,
      open_circuit_voltage: yupNumberValidation.test(
        'isc',
        'Voc can\'t be less than or equal to Isc',
        (value) => !(value <= formikStepTwo.values.short_circuit_current)
      ),
      short_circuit_current: yupNumberValidation.test(
        'voc',
        'Isc can\'t be greater than to Voc',
        (value) => !(value > formikStepTwo.values.open_circuit_voltage)
      ),
      temperature_coefficient_max_power_point: yupNumberValidation,
      temperature_coefficient_open_circuit_voltage_yVoc: yupNumberValidation,
      temperature_coefficient_open_circuit_voltage_ylsc: yupNumberValidation,
      nominal_operating_cell_temperature: yupNumberValidation,
      number_of_cells: yupNumberValidation,
    }),
    onSubmit: async (values) => {
      const newValues = {
        ...formikStepOne.values,
        ...values,
      }

      handleSubmit(newValues)
    },
  })

  useEffect(() => {
    if (!modalId) return
    const getInfo = async () => {
      const response = await getPanelModelDetails(token, modalId)

      if (response.error) console.error(response.error)
      else if (response) {
        formikStepOne.setValues({
          model_name: response.model_name,
          panel_type: response.panel_type,
          power_thermal_capacity: response.power_thermal_capacity,
          gross_area: response.gross_area,
          aperture_area: response.aperture_area,
          collector_height: response.collector_height,
          collector_width: response.collector_width,
          optical_efficiency: normalizePercentToShow(
            response.optical_efficiency
          ),
          kd: response.kd,
          c1: response.c1,
          c2: response.c2,
          c3: response.c3,
          c4: response.c4,
          c5: response.c5,
          c6: response.c6,
          transversal_10: response.positional_fields.transversal_10,
          transversal_20: response.positional_fields.transversal_20,
          transversal_30: response.positional_fields.transversal_30,
          transversal_40: response.positional_fields.transversal_40,
          transversal_50: response.positional_fields.transversal_50,
          transversal_60: response.positional_fields.transversal_60,
          transversal_70: response.positional_fields.transversal_70,
          transversal_80: response.positional_fields.transversal_80,
          transversal_90: response.positional_fields.transversal_90,
          longitudinal_10: response.positional_fields.longitudinal_10,
          longitudinal_20: response.positional_fields.longitudinal_20,
          longitudinal_30: response.positional_fields.longitudinal_30,
          longitudinal_40: response.positional_fields.longitudinal_40,
          longitudinal_50: response.positional_fields.longitudinal_50,
          longitudinal_60: response.positional_fields.longitudinal_60,
          longitudinal_70: response.positional_fields.longitudinal_70,
          longitudinal_80: response.positional_fields.longitudinal_80,
          longitudinal_90: response.positional_fields.longitudinal_90,
          transversal_error: '',
          longitudinal_error: '',
        })
        if (response.panel_type === 'PVT') {
          formikStepTwo.setValues({
            peak_eletricity_capacity: response.peak_eletricity_capacity,
            panel_efficiency_capacity: normalizePercentToShow(
              response.panel_efficiency_capacity
            ),
            maximum_power_point_voltage: response.maximum_power_point_voltage,
            maximum_power_point_current: response.maximum_power_point_current,
            open_circuit_voltage: response.open_circuit_voltage,
            short_circuit_current: response.short_circuit_current,
            temperature_coefficient_max_power_point: normalizePercentToShow(
              response.temperature_coefficient_max_power_point
            ),
            temperature_coefficient_open_circuit_voltage_yVoc:
              normalizePercentToShow(
                response.temperature_coefficient_open_circuit_voltage_yVoc
              ),
            temperature_coefficient_open_circuit_voltage_ylsc:
              normalizePercentToShow(
                response.temperature_coefficient_open_circuit_voltage_ylsc
              ),
            nominal_operating_cell_temperature:
              response.nominal_operating_cell_temperature,
            number_of_cells: response.number_of_cells,
          })
        }
      }
    }
    getInfo()
  }, [modalId])

  useEffect(() => {
    formikStepOne.setFieldValue(
      'panel_type',
      panelModelTypeSelected[panelModelTypeSelected.panelSelected]
    )
  }, [panelModelTypeSelected])

  const handleSubmit = async (values) => {
    let response
    const newValues = {
      ...values,
      optical_efficiency: normalizePercentToSend(values.optical_efficiency),
    }
    if (newValues.panel_type === 'PVT') {
      newValues.panel_efficiency_capacity = normalizePercentToSend(
        newValues.panel_efficiency_capacity
      )
      newValues.temperature_coefficient_max_power_point =
        normalizePercentToSend(
          newValues.temperature_coefficient_max_power_point
        )
      newValues.temperature_coefficient_open_circuit_voltage_yVoc =
        normalizePercentToSend(
          newValues.temperature_coefficient_open_circuit_voltage_yVoc
        )
      newValues.temperature_coefficient_open_circuit_voltage_ylsc =
        normalizePercentToSend(
          newValues.temperature_coefficient_open_circuit_voltage_ylsc
        )
    }

    if (!modalId) response = await createPanelModel(token, newValues)
    else response = await updatePanelModel(token, modalId, newValues)

    const nonFieldErrors = response?.error?.non_field_errors

    if (
      (nonFieldErrors?.length &&
        nonFieldErrors[0] ==
          'A panel model with the same name already exists') ||
      response?.error == 'Model with this name already exists'
    ) {
      formikStepOne.setFieldError('model_name', 'This name is already in use')
    } else if (response.error) setServerError(true)
    else if (response?.data) {
      getExtraData()
      setStep(3)
    }
  }

  const angleLabels = [
    '10°',
    '20°',
    '30°',
    '40°',
    '50°',
    '60°',
    '70°',
    '80°',
    '90°',
  ]

  const tansversalInputs = [
    {
      name: 'transversal_10',
      value: formikStepOne.values.transversal_10,
    },
    {
      name: 'transversal_20',
      value: formikStepOne.values.transversal_20,
    },
    {
      name: 'transversal_30',
      value: formikStepOne.values.transversal_30,
    },
    {
      name: 'transversal_40',
      value: formikStepOne.values.transversal_40,
    },
    {
      name: 'transversal_50',
      value: formikStepOne.values.transversal_50,
    },
    {
      name: 'transversal_60',
      value: formikStepOne.values.transversal_60,
    },
    {
      name: 'transversal_70',
      value: formikStepOne.values.transversal_70,
    },
    {
      name: 'transversal_80',
      value: formikStepOne.values.transversal_80,
    },
    {
      name: 'transversal_90',
      value: formikStepOne.values.transversal_90,
    },
  ]

  const longitudinalInputs = [
    {
      name: 'longitudinal_10',
      value: formikStepOne.values.longitudinal_10,
    },
    {
      name: 'longitudinal_20',
      value: formikStepOne.values.longitudinal_20,
    },
    {
      name: 'longitudinal_30',
      value: formikStepOne.values.longitudinal_30,
    },
    {
      name: 'longitudinal_40',
      value: formikStepOne.values.longitudinal_40,
    },
    {
      name: 'longitudinal_50',
      value: formikStepOne.values.longitudinal_50,
    },
    {
      name: 'longitudinal_60',
      value: formikStepOne.values.longitudinal_60,
    },
    {
      name: 'longitudinal_70',
      value: formikStepOne.values.longitudinal_70,
    },
    {
      name: 'longitudinal_80',
      value: formikStepOne.values.longitudinal_80,
    },
    {
      name: 'longitudinal_90',
      value: formikStepOne.values.longitudinal_90,
    },
  ]

  const toggleSave = () => setSave((prev) => !prev)

  if (step == 1)
    return (
      <Modal
        title={!modalId ? '+ Add new model' : 'Edit model'}
        closeModal={closeShowNewModelModal}
        prevStep={() => {toggleShowManageModelsModal(); closeShowNewModelModal()}}
        notCloseOnBlur
        isBig
      >
        <div
          className="h-100 flex flex-col justify-center items-center gap-3 py-3 mx-auto"
          style={{
            maxHeight: `calc(95vh - ${
              panelModelTypeSelected[panelModelTypeSelected.panelSelected] ==
              'PVT'
                ? '164px'
                : '140px'
            })`,
          }}
        >
          <div className="Columns Two">
            <Input
              label="Type of panel"
              name="panel_type"
              value={modelType}
              placeholder="Type of panel"
              disabled
            />

            <Input
              label="Name of model"
              name="model_name"
              value={formikStepOne.values.model_name}
              placeholder="Enter model name"
              onChange={formikStepOne.handleChange}
              error={formikStepOne.errors.model_name}
            />
          </div>

          <div className="Modal__Divider" />

          <div className="Modal__Scroll-Content flex flex-column items-center gap-3 pb-3">
            <div className="CreateSimulation__Grid Two_Col_and_seven_row End">
              <InputSmall
                topLabel="Power thermal capacity Wpth"
                rightLabel="W"
                name="power_thermal_capacity"
                value={formikStepOne.values.power_thermal_capacity}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.power_thermal_capacity}
              />

              <InputSmall
                topLabel="Gross area"
                rightLabel="m2"
                name="gross_area"
                value={formikStepOne.values.gross_area}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.gross_area}
              />

              <InputSmall
                topLabel="Aperture area"
                rightLabel="m2"
                name="aperture_area"
                value={formikStepOne.values.aperture_area}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.aperture_area}
              />

              <InputSmall
                topLabel="Collector height"
                rightLabel="m"
                name="collector_height"
                value={formikStepOne.values.collector_height}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.collector_height}
              />

              <InputSmall
                topLabel="Collector width"
                rightLabel="m"
                name="collector_width"
                value={formikStepOne.values.collector_width}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.collector_width}
              />

              <InputSmall
                topLabel="Optical efficiency eta0b"
                rightLabel="%"
                name="optical_efficiency"
                value={formikStepOne.values.optical_efficiency}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.optical_efficiency}
              />

              <InputSmall
                topLabel="Kd"
                name="kd"
                value={formikStepOne.values.kd}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.kd}
              />

              <InputSmall
                topLabel="c1"
                rightLabel="W/m2K"
                name="c1"
                value={formikStepOne.values.c1}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.c1}
              />

              <InputSmall
                topLabel="c2"
                rightLabel="W/m2K"
                name="c2"
                value={formikStepOne.values.c2}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.c2}
              />

              <InputSmall
                topLabel="c3"
                rightLabel="J/m3K"
                name="c3"
                value={formikStepOne.values.c3}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.c3}
              />

              <InputSmall
                topLabel="c4"
                name="c4"
                value={formikStepOne.values.c4}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.c4}
              />

              <InputSmall
                topLabel="c5"
                rightLabel="J/m2K"
                name="c5"
                value={formikStepOne.values.c5}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.c5}
              />

              <InputSmall
                topLabel="c6"
                rightLabel="s/m"
                name="c6"
                value={formikStepOne.values.c6}
                placeholder="0"
                onChange={formikStepOne.handleChange}
                error={formikStepOne.errors.c6}
              />
            </div>

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-2">
                <h3 className="text-5 fw-medium">Incidence angle modifier</h3>

                <div className="CreateSimulation__Grid Ten Modal">
                  {angleLabels.map((item, index) => (
                    <Input
                      key={`Input-Angle-${index}`}
                      name={`name-${item}`}
                      value={item}
                      placeholder="0"
                      disabled={true}
                      small
                    />
                  ))}
                  <div
                    className="text-6 fw-medium text-secondary text-center"
                    style={{ minWidth: '36px' }}
                  >
                    Angle
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="w-100 d-flex align-items-center gap-2">
                  <h3 className="d-flex align-items-center gap-1 text-5 fw-medium">
                    {formikStepOne.errors.transversal_error && (
                      <div className="Input__Error-Icon-Container">
                        <img className="Input__Error-Icon" src={ErrorIcon} />
                      </div>
                    )}
                    Transversal
                  </h3>
                  {formikStepOne.errors.transversal_error && (
                    <span className="Input__Error text-6">
                      {formikStepOne.errors.transversal_error}
                    </span>
                  )}
                </div>

                <div className="CreateSimulation__Grid Ten Modal">
                  {tansversalInputs.map((item, index) => (
                    <Input
                      key={`Input-Transversal-${index}`}
                      name={item.name}
                      value={item.value}
                      placeholder="0"
                      onChange={formikStepOne.handleChange}
                      small
                    />
                  ))}
                  <div
                    className="text-6 fw-medium text-secondary text-center"
                    style={{ minWidth: '36px' }}
                  >
                    KθT,coll
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="w-100 d-flex align-items-center gap-2">
                  <h3 className="d-flex align-items-center gap-1 text-5 fw-medium">
                    {formikStepOne.errors.longitudinal_error && (
                      <div className="Input__Error-Icon-Container">
                        <img className="Input__Error-Icon" src={ErrorIcon} />
                      </div>
                    )}
                    Longitudinal
                  </h3>
                  {formikStepOne.errors.longitudinal_error && (
                    <span className="Input__Error text-6">
                      {formikStepOne.errors.longitudinal_error}
                    </span>
                  )}
                </div>

                <div className="CreateSimulation__Grid Ten Modal">
                  {longitudinalInputs.map((item, index) => (
                    <Input
                      key={`Input-Angle-${index}`}
                      name={item.name}
                      value={item.value}
                      placeholder="0"
                      onChange={formikStepOne.handleChange}
                      small
                    />
                  ))}
                  <div
                    className="text-6 fw-medium text-secondary text-center"
                    style={{ minWidth: '36px' }}
                  >
                    KθL,coll
                  </div>
                </div>
              </div>
            </div>
          </div>

          {panelModelTypeSelected[panelModelTypeSelected.panelSelected] ===
            'T' && !modalId ? (
              <>
                <div className="Modal__Divider" />

                <div className="d-flex flex-column gap-2 w-100">
                  <p className="text-5 fw-medium">
                  Save system for future simulations?
                  </p>
                  <button className="d-flex gap-2" onClick={toggleSave}>
                    <div className={classNames('Checkbox', { Selected: save })} />
                    <p className="text-6">Yes</p>
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
        </div>

        {serverError ? <p className="text-red">Something went wrong</p> : <></>}

        <Button
          className="gap-0 mx-auto w-100"
          type="submit"
          onClick={() => formikStepOne.handleSubmit()}
          disabled={formikStepOne.isSubmitting}
        >
          {formikStepOne.isSubmitting ? (
            <>
              <span>{!modalId ? 'Creating' : 'Saving'}</span>
              <LoadingDots className="enter-done" />
            </>
          ) : (
            <span>
              {panelModelTypeSelected[panelModelTypeSelected.panelSelected] ==
              'PVT'
                ? 'Continue'
                : !modalId
                  ? 'Create'
                  : 'Save'}
            </span>
          )}
        </Button>

        {panelModelTypeSelected[panelModelTypeSelected.panelSelected] ==
          'PVT' && (
          <div className="AuthCard__Steps mt-2">
            <div className="Radio Selected"></div>
            <div className="Radio"></div>
          </div>
        )}
      </Modal>
    )

  if (step == 2)
    return (
      <Modal
        title={!modalId ? '+ Add PV data' : 'Edit PV data'}
        closeModal={closeShowNewModelModal}
        prevStep={() => setStep(1)}
        notCloseOnBlur
        isBig
      >
        <div
          className="h-100 flex flex-col justify-center items-center gap-3 py-3 mx-auto"
          style={{ maxHeight: 'calc(95vh - 140px)' }}
        >
          <div className="Modal__Scroll-Content flex flex-column items-center gap-3 w-100 pb-3">
            <div className="Columns Auto_flow">
              <Input
                label="Type of panel"
                name="panel_type"
                value={modelType}
                placeholder="Type of panel"
                disabled
              />

              <Input
                label="Name of model"
                name="model_name"
                value={formikStepOne.values.model_name}
                placeholder="Enter model name"
                disabled
              />
            </div>

            <div className="Modal__Divider" />

            <div className="Columns Auto_flow">
              <div className="d-flex flex-column gap-3">
                <InputSmall
                  topLabel="Peak electrical capacity Wpel"
                  rightLabel="W"
                  name="peak_eletricity_capacity"
                  value={formikStepTwo.values.peak_eletricity_capacity}
                  placeholder="0"
                  onChange={formikStepTwo.handleChange}
                  error={formikStepTwo.errors.peak_eletricity_capacity}
                />

                <InputSmall
                  topLabel="Panel efficiency at STC"
                  rightLabel="%"
                  name="panel_efficiency_capacity"
                  value={formikStepTwo.values.panel_efficiency_capacity}
                  placeholder="0"
                  onChange={formikStepTwo.handleChange}
                  error={formikStepTwo.errors.panel_efficiency_capacity}
                />

                <InputSmall
                  topLabel="Maximum power point voltage Vmpp"
                  rightLabel="V"
                  name="maximum_power_point_voltage"
                  value={formikStepTwo.values.maximum_power_point_voltage}
                  placeholder="0"
                  onChange={formikStepTwo.handleChange}
                  error={formikStepTwo.errors.maximum_power_point_voltage}
                />

                <InputSmall
                  topLabel="Maximum power point current Impp"
                  rightLabel="A"
                  name="maximum_power_point_current"
                  value={formikStepTwo.values.maximum_power_point_current}
                  placeholder="0"
                  onChange={formikStepTwo.handleChange}
                  error={formikStepTwo.errors.maximum_power_point_current}
                />
              </div>
              <div className="d-flex flex-column gap-3">
                <InputSmall
                  topLabel="Open circuit voltage Voc"
                  rightLabel="V"
                  name="open_circuit_voltage"
                  value={formikStepTwo.values.open_circuit_voltage}
                  placeholder="0"
                  onChange={formikStepTwo.handleChange}
                  error={formikStepTwo.errors.open_circuit_voltage}
                />

                <InputSmall
                  topLabel="Short circuit current Isc"
                  rightLabel="A"
                  name="short_circuit_current"
                  value={formikStepTwo.values.short_circuit_current}
                  placeholder="0"
                  onChange={formikStepTwo.handleChange}
                  error={formikStepTwo.errors.short_circuit_current}
                />

                <InputSmall
                  topLabel="Temperature coefficient for maximum power point ympp"
                  rightLabel="%/°C"
                  name="temperature_coefficient_max_power_point"
                  value={
                    formikStepTwo.values.temperature_coefficient_max_power_point
                  }
                  placeholder="0"
                  onChange={formikStepTwo.handleChange}
                  error={
                    formikStepTwo.errors.temperature_coefficient_max_power_point
                  }
                />

                <InputSmall
                  topLabel="Temperature coefficient for oper circuit voltage yVoc"
                  rightLabel="%/°C"
                  name="temperature_coefficient_open_circuit_voltage_yVoc"
                  value={
                    formikStepTwo.values
                      .temperature_coefficient_open_circuit_voltage_yVoc
                  }
                  placeholder="0"
                  onChange={formikStepTwo.handleChange}
                  error={
                    formikStepTwo.errors
                      .temperature_coefficient_open_circuit_voltage_yVoc
                  }
                />
              </div>

              <div className="d-flex flex-column gap-3">
                <InputSmall
                  topLabel="Temperature coefficient for oper circuit voltage yIsc"
                  rightLabel="%/°C"
                  name="temperature_coefficient_open_circuit_voltage_ylsc"
                  value={
                    formikStepTwo.values
                      .temperature_coefficient_open_circuit_voltage_ylsc
                  }
                  placeholder="0"
                  onChange={formikStepTwo.handleChange}
                  error={
                    formikStepTwo.errors
                      .temperature_coefficient_open_circuit_voltage_ylsc
                  }
                />

                <InputSmall
                  topLabel="Nominal operation cell temperature TNOCT"
                  rightLabel="°C"
                  name="nominal_operating_cell_temperature"
                  value={
                    formikStepTwo.values.nominal_operating_cell_temperature
                  }
                  placeholder="0"
                  onChange={formikStepTwo.handleChange}
                  error={
                    formikStepTwo.errors.nominal_operating_cell_temperature
                  }
                />

                <InputSmall
                  topLabel="Number of cells in series"
                  name="number_of_cells"
                  value={formikStepTwo.values.number_of_cells}
                  placeholder="0"
                  onChange={formikStepTwo.handleChange}
                  error={formikStepTwo.errors.number_of_cells}
                />
              </div>
            </div>
          </div>

          {!modalId ? (
            <>
              <div className="Modal__Divider" />

              <div className="d-flex flex-column gap-2 w-100">
                <p className="text-5 fw-medium">
                  Save system for future simulations?
                </p>
                <button className="d-flex gap-2" onClick={toggleSave}>
                  <div className={classNames('Checkbox', { Selected: save })} />
                  <p className="text-6">Yes</p>
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        {serverError ? <p className="text-red">Something went wrong</p> : <></>}

        <Button
          className={classNames('gap-0 mx-auto w-100', { 'mt-2': modalId })}
          type="submit"
          onClick={() => formikStepTwo.handleSubmit()}
          disabled={formikStepTwo.isSubmitting}
        >
          {formikStepTwo.isSubmitting ? (
            <>
              <span>{!modalId ? 'Creating' : 'Saving'}</span>
              <LoadingDots className="enter-done" />
            </>
          ) : (
            <span>{!modalId ? 'Create' : 'Save'}</span>
          )}
        </Button>

        <div className="AuthCard__Steps mt-2">
          <div className="Radio Complete"></div>
          <div className="Radio Selected"></div>
        </div>
      </Modal>
    )

  if (step === 3)
    return (
      <CheckModal
        closeModal={closeShowNewModelModal}
        title={!modalId ? '+ Add new model' : 'Edit model'}
        message={
          !modalId
            ? 'The new model has been added successfully, now you can select it in the simulation'
            : 'Model has been edited successfully'
        }
      />
    )
}

export default NewModelModal
