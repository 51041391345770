import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import {
  getReportData,
} from '../../api/simulations/general-simulation'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import SummaryField from '../../components/SummaryField'
// import ReportModal from '../../components/modals/ReportModal'
import Loader from '../../components/Loader'
import { normalizeNumber } from '../../constants'
import PaperIcon from '../../assets/icons/paper.svg'
import EnergyIcon from '../../assets/icons/electricity.svg'
import CoinIcon from '../../assets/icons/money.svg'
import useMatchMedia from '../../hooks/useMatchMedia'
// import ReportLanguageModal from '../../components/modals/ReportLanguageModal'
import { useLocalStorage } from 'usehooks-ts'
import handleReportContext from '../../utils/handleReportContext'
import Modal from '../../components/modals/Modal'
import { IAlert } from '../../iconComponents'
import ReportInfoModal from '../../components/modals/ReportInfoModal'
import Button from '../../components/buttons/Button'

const RunningComplete = () => {
  const [pages, setPages] = useLocalStorage('pages', [])
  const [idSelected, setIdSelected] = useLocalStorage('idSelected', '')
  const isMedia = useMatchMedia('(max-width: 991px)')
  const { token, user } = useAppContext()
  const { simulationId, newSimulation, setNewSimulation, serverSimulationDetail, simulationDetails, isLoading, setIsLoading } =
    useNewSimulationContext()
  // const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [showErrorReportModal, setShowErrorReportModal] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)
  // const [report, setReport] = useState(null)
  const { data: summary, isFetched } = useQuery(
    ['simulationSummary', simulationId],
    () => getReportData(token, simulationId),
    {
      enabled:
        token !== '' &&
        newSimulation.id !== '' &&
        newSimulation.status === 'finished',
      refetchOnWindowFocus: false,
    }
  )
  const navigate = useNavigate()
  const currentCurrency =
    newSimulation.steps.general_information.currency_symbol

  const checkIfExistValue = (value) => value !== null && value !== undefined

  const solutionDetails = useMemo(() => {
    if (summary && !summary?.error) {
      const data = summary?.solution_details
      return [
        {
          title: `PVT "${data?.PVT_1_name}" field`,
          value: checkIfExistValue(data?.PVT_1_quantity)
            ? `${normalizeNumber(data?.PVT_1_quantity, 3)} panels`
            : '',
          caption: `${normalizeNumber(
            data?.PVT_1_surface_area,
            3
          )} m2 surface area`,
        },
        {
          title: `PVT "${data?.PVT_1_name}" field thermal capacity`,
          value: checkIfExistValue(data?.PVT_1_thermal_capacity)
            ? `${normalizeNumber(data?.PVT_1_thermal_capacity, 3)} kW`
            : '',
        },
        {
          title: `PVT "${data?.PVT_1_name}" field electrical capacity`,
          value: checkIfExistValue(data?.PVT_1_electrical_capacity)
            ? `${normalizeNumber(data?.PVT_1_electrical_capacity, 3)} kW`
            : '',
        },
        {
          title: `PVT "${data?.PVT_2_name}" field`,
          value: checkIfExistValue(data?.PVT_2_quantity)
            ? `${normalizeNumber(data?.PVT_2_quantity, 3)} panels`
            : '',
          caption: `${normalizeNumber(
            data?.PVT_2_surface_area,
            3
          )} m2 surface area`,
        },
        {
          title: `PVT "${data?.PVT_2_name}" field thermal capacity`,
          value: checkIfExistValue(data?.PVT_2_thermal_capacity)
            ? `${normalizeNumber(data?.PVT_2_thermal_capacity, 3)} kW`
            : '',
        },
        {
          title: `PVT "${data?.PVT_2_name}" field electrical capacity`,
          value: checkIfExistValue(data?.PVT_2_electrical_capacity)
            ? `${normalizeNumber(data?.PVT_2_electrical_capacity, 3)} kW`
            : '',
        },
        {
          title: 'Total solar buffer capacity',
          value: checkIfExistValue(data?.total_solar_buffer_capacity)
            ? `${normalizeNumber(data?.total_solar_buffer_capacity, 3)} liters`
            : '',
          caption: `${normalizeNumber(data?.number_of_buffers, 3)} buffers`,
        },
        // {
        //   title: 'Thermal field',
        //   value: checkIfExistValue(data?.PVT_2_quantity) ? `${normalizeNumber(data?.PVT_2_quantity, 3)} panels` : '',
        //   caption: `${normalizeNumber(data?.PVT_2_surface_area, 3)} m2 surface area`
        // }, {
        //   title: 'Thermal field thermal capacity',
        //   value: checkIfExistValue(data?.PVT_2_thermal_capacity) ? `${normalizeNumber(data?.PVT_2_thermal_capacity, 3)} kW` : '',
        // }, {
        {
          title: `PV "${data?.PV_field_1_name}" field`,
          value: checkIfExistValue(data?.PV_field_1_quantity)
            ? `${normalizeNumber(data?.PV_field_1_quantity, 3)} panels`
            : '',
          caption: `${normalizeNumber(
            data?.PV_field_1_surface_area,
            3
          )} m2 surface area`,
        },
        {
          title: `PV "${data?.PV_field_1_name}" field electrical capacity`,
          value: checkIfExistValue(data?.PV_field_1_electrical_capacity)
            ? `${normalizeNumber(data?.PV_field_1_electrical_capacity, 3)} kW`
            : '',
        },
        {
          title: `PV "${data?.PV_field_2_name}" field`,
          value: checkIfExistValue(data?.PV_field_2_quantity)
            ? `${normalizeNumber(data?.PV_field_2_quantity, 3)} panels`
            : '',
          caption: `${normalizeNumber(
            data?.PV_field_2_surface_area,
            3
          )} m2 surface area`,
        },
        {
          title: `PV "${data?.PV_field_2_name}" field electrical capacity`,
          value: checkIfExistValue(data?.PV_field_2_electrical_capacity)
            ? `${normalizeNumber(data?.PV_field_2_electrical_capacity, 3)} kW`
            : '',
        },
        {
          title: 'In store electric heater heat capacity',
          value: checkIfExistValue(data?.elec_heater_capacity)
            ? `${normalizeNumber(data?.elec_heater_capacity, 3)} kW`
            : '',
        },
        {
          title: 'In store indirect gas boiler heat capacity',
          value: checkIfExistValue(data?.aux_heater_1_capacity)
            ? `${normalizeNumber(data?.aux_heater_1_capacity, 3)} kW`
            : '',
        },
        {
          title: 'Daikin Heat pump heat capacity',
          value: checkIfExistValue(data?.aux_heater_2_capacity)
            ? `${normalizeNumber(data?.aux_heater_2_capacity, 3)} kW`
            : '',
        },
        {
          title: 'Auxiliary buffer capacity',
          value: checkIfExistValue(data?.aux_buffer_capacity)
            ? `${normalizeNumber(data?.aux_buffer_capacity, 3)} liters`
            : '',
        },
      ]
    } else return []
  }, [summary])

  const energyPerformance = useMemo(() => {
    if (summary && !summary?.error) {
      const data = summary?.energy_performance
      const detailsData = summary?.solution_details
      return [
        {
          title: 'Total heat demand',
          value: checkIfExistValue(data?.total_heat_demand)
            ? `${normalizeNumber(data?.total_heat_demand, 3)} kWh`
            : '',
        },
        {
          title: 'Total solar heat supplied',
          value: checkIfExistValue(data?.total_heat_supplied)
            ? `${normalizeNumber(data?.total_heat_supplied, 3)} kWh`
            : '',
        },
        {
          title: 'Heat produced by the PVT field',
          value: checkIfExistValue(data?.heat_produced_first_PVT)
            ? `${normalizeNumber(data?.heat_produced_first_PVT, 3)} kWh`
            : '',
        },
        {
          title: 'Heat produced by the Thermal field',
          value: checkIfExistValue(data?.heat_produced_second_PVT)
            ? `${normalizeNumber(data?.heat_produced_second_PVT, 3)} kWh`
            : '',
        },
        {
          title: 'Heat supplied by the in store electric heater',
          value: checkIfExistValue(data?.heat_supplied_by_existing_hot_water)
            ? `${normalizeNumber(
              data?.heat_supplied_by_existing_hot_water,
              3
            )} kWh`
            : '',
        },
        {
          title: 'Heat supplied by the In store indirect gas boiler',
          value: checkIfExistValue(data?.heat_supplied_by_first_new_hot_water)
            ? `${normalizeNumber(
              data?.heat_supplied_by_first_new_hot_water,
              3
            )} kWh`
            : '',
        },
        {
          title: 'Heat supplied by the Daikin Heat pump',
          value: checkIfExistValue(data?.heat_supplied_by_second_new_hot_water)
            ? `${normalizeNumber(
              data?.heat_supplied_by_second_new_hot_water,
              3
            )} kWh`
            : '',
        },
        {
          title: 'Solar fraction',
          value: checkIfExistValue(data?.solar_fraction)
            ? `${normalizeNumber(data?.solar_fraction, 3)} %`
            : '',
        },
        {
          title: 'Renewable Energy fraction',
          value: checkIfExistValue(data?.renewable_fraction)
            ? `${normalizeNumber(data?.renewable_fraction, 3)} %`
            : '',
        },
        {
          title: 'Total AC solar electricity supplied',
          value: checkIfExistValue(data?.total_ac_electricity)
            ? `${normalizeNumber(data?.total_ac_electricity, 3)} kWh`
            : '',
        },
        {
          title: 'DC electricity produced by the PVT field',
          value: checkIfExistValue(data?.dc_electricity_first_PVT)
            ? `${normalizeNumber(data?.dc_electricity_first_PVT, 3)} kWh`
            : '',
        },
        {
          title: `DC electricity produced by the PV "${detailsData?.PV_field_1_name}" field`,
          value: checkIfExistValue(data?.dc_electricity_first_PV)
            ? `${normalizeNumber(data?.dc_electricity_first_PV, 3)} kWh`
            : '',
        },
        {
          title: `DC electricity produced by the PV "${detailsData?.PV_field_2_name}" field`,
          value: checkIfExistValue(data?.dc_electricity_second_PV)
            ? `${normalizeNumber(data?.dc_electricity_second_PV, 3)} kWh`
            : '',
        },
        {
          title: 'Electricity consumed by the solar circulation pumps',
          value: checkIfExistValue(data?.electricity_consumed_solar_pumps)
            ? `${normalizeNumber(
              data?.electricity_consumed_solar_pumps,
              3
            )} kWh`
            : '',
        },
        {
          title: 'Electricity consumed by the In store electric heater',
          value: checkIfExistValue(
            data?.electricity_consumed_first_new_hot_water
          )
            ? `${normalizeNumber(
              data?.electricity_consumed_first_new_hot_water,
              3
            )} kWh`
            : '',
        },
        {
          title: 'Electricity consumed by the Daikin Heat pump',
          value: checkIfExistValue(
            data?.electricity_consumed_second_new_hot_water
          )
            ? `${normalizeNumber(
              data?.electricity_consumed_second_new_hot_water,
              3
            )} kWh`
            : '',
        },
      ]
    } else return []
  }, [summary])

  const economicSummary = useMemo(() => {
    if (summary && !summary?.error) {
      const data = summary?.financial_indicators
      return [
        {
          title: 'Energy savings',
          value: checkIfExistValue(data?.energy_savings)
            ? `${normalizeNumber(
              data?.energy_savings,
              3
            )} ${currentCurrency} / year`
            : '',
        },
        {
          title: 'Payback period',
          value: checkIfExistValue(data?.payback_period)
            ? `${normalizeNumber(data?.payback_period, 3)} years`
            : '',
        },
        {
          title: 'Internal rate of return (IRR)',
          value: checkIfExistValue(data?.internal_rate_of_return)
            ? `${normalizeNumber(data?.internal_rate_of_return, 3)} %`
            : '',
        },
        {
          title: 'ROI',
          value: checkIfExistValue(data?.roi)
            ? `${normalizeNumber(data?.roi, 3)} %`
            : '',
        },
        {
          title: 'TCO',
          value: checkIfExistValue(data?.tco)
            ? `${normalizeNumber(data?.tco)} ${currentCurrency}`
            : '',
        },
        {
          title: 'System cost',
          value: checkIfExistValue(data?.system_cost)
            ? `${normalizeNumber(data?.system_cost)} ${currentCurrency}`
            : '',
        },
        {
          title: 'Investment subsidy',
          value: checkIfExistValue(data?.investment_subsidy)
            ? `${normalizeNumber(data?.investment_subsidy)} ${currentCurrency}`
            : '',
        },
        {
          title: 'Net system cost',
          value: checkIfExistValue(data?.net_system_cost)
            ? `${normalizeNumber(data?.net_system_cost)} ${currentCurrency}`
            : '',
        },
        {
          title: 'Net present value',
          value: checkIfExistValue(data?.net_present_value)
            ? `${normalizeNumber(data?.net_present_value)} ${currentCurrency}`
            : '',
        },
        {
          title: 'Total Cost Savings Over Life-Span',
          value: checkIfExistValue(data?.total_savings_life_span)
            ? `${normalizeNumber(
              data?.total_savings_life_span
            )} ${currentCurrency}`
            : '',
        },
      ]
    } else return []
  }, [summary])

  const ppaGenericData = useMemo(() => {
    if (summary && !summary?.error) {
      const data = summary?.financial_ppa?.length
        ? summary?.financial_ppa[0]
        : {
          total_energy_bill: 0,
          current_heat_price: 0,
          current_electricity_price: 0,
          current_energy_price_escalation: 0,
        }
      return [
        {
          title: 'Total energy bill',
          value: checkIfExistValue(data?.total_energy_bill)
            ? `${normalizeNumber(data?.total_energy_bill)} ${currentCurrency}`
            : '',
        },
        {
          title: 'Current heat price',
          value: checkIfExistValue(data?.current_heat_price)
            ? `${normalizeNumber(
              data?.current_heat_price,
              3
            )} ${currentCurrency}`
            : '',
          caption: 'per kWh',
        },
        {
          title: 'Current electricity price',
          value: checkIfExistValue(data?.current_electricity_price)
            ? `${normalizeNumber(
              data?.current_electricity_price,
              3
            )} ${currentCurrency}`
            : '',
          caption: 'per kWh',
        },
        {
          title: 'Current energy price escalation',
          value: checkIfExistValue(data?.current_energy_price_escalation)
            ? `${normalizeNumber(data?.current_energy_price_escalation, 3)} %`
            : '',
        },
      ]
    } else return []
  }, [summary])

  const periodOne = useMemo(() => {
    if (summary && !summary?.error) {
      const data = summary?.financial_ppa?.length
        ? summary?.financial_ppa[0]
        : {
          irr_for_spv: 0,
          ppa_heat_price: 0,
          ppa_electricity_price: 0,
          ppa_energy_price_escalation: 0,
          net_cost_savings: 0,
          // total_net_cost_savings: 0,
          average_yearly_payments: 0,
        }
      return [
        {
          title: 'IRR for SPV',
          value: checkIfExistValue(data?.irr_for_spv)
            ? `${normalizeNumber(data?.irr_for_spv, 3)} %`
            : '',
        },
        {
          title: 'PPA heat price',
          value: checkIfExistValue(data?.ppa_heat_price)
            ? `${normalizeNumber(
              data?.ppa_heat_price,
              3
            )} ${currentCurrency} per kWh`
            : '',
        },
        {
          title: 'PPA electricity price',
          value: checkIfExistValue(data?.ppa_electricity_price)
            ? `${normalizeNumber(
              data?.ppa_electricity_price,
              3
            )} ${currentCurrency} per kWh`
            : '',
        },
        {
          title: 'PPA energy price escalation',
          value: checkIfExistValue(data?.ppa_energy_price_escalation)
            ? `${normalizeNumber(data?.ppa_energy_price_escalation, 3)} %`
            : '',
        },
        // {
        //   title: 'Net cost savings (year-1)',
        //   value: checkIfExistValue(data?.net_cost_savings)
        //     ? `${normalizeNumber(data?.net_cost_savings)} ${currentCurrency}`
        //     : '',
        // }, {
        {
          title: 'Total net cost savings',
          value: checkIfExistValue(data?.net_cost_savings)
            ? `${normalizeNumber(data?.net_cost_savings)} ${currentCurrency}`
            : '',
        },
        {
          title: 'Average yearly payments',
          value: checkIfExistValue(data?.average_yearly_payments)
            ? `${normalizeNumber(
              data?.average_yearly_payments
            )} ${currentCurrency}`
            : '',
        },
      ]
    } else return []
  }, [summary])

  const periodTwo = useMemo(() => {
    if (summary && !summary?.error) {
      const data = summary?.financial_ppa?.length
        ? summary?.financial_ppa[1]
        : {
          irr_for_spv: 0,
          ppa_heat_price: 0,
          ppa_electricity_price: 0,
          ppa_energy_price_escalation: 0,
          net_cost_savings: 0,
          // total_net_cost_savings: 0,
          average_yearly_payments: 0,
        }
      return [
        {
          title: 'IRR for SPV',
          value: checkIfExistValue(data?.irr_for_spv)
            ? `${normalizeNumber(data?.irr_for_spv, 3)} %`
            : '',
        },
        {
          title: 'PPA heat price',
          value: checkIfExistValue(data?.ppa_heat_price)
            ? `${normalizeNumber(
              data?.ppa_heat_price,
              3
            )} ${currentCurrency} per kWh`
            : '',
        },
        {
          title: 'PPA electricity price',
          value: checkIfExistValue(data?.ppa_electricity_price)
            ? `${normalizeNumber(
              data?.ppa_electricity_price,
              3
            )} ${currentCurrency} per kWh`
            : '',
        },
        {
          title: 'PPA energy price escalation',
          value: checkIfExistValue(data?.ppa_energy_price_escalation)
            ? `${normalizeNumber(data?.ppa_energy_price_escalation, 3)} %`
            : '',
        },
        // {
        //   title: 'Net cost savings (year-1)',
        //   value: checkIfExistValue(data?.net_cost_savings)
        //     ? `${normalizeNumber(data?.net_cost_savings)} ${currentCurrency}`
        //     : '',
        // },
        {
          title: 'Total net cost savings',
          value: checkIfExistValue(data?.net_cost_savings)
            ? `${normalizeNumber(data?.net_cost_savings)} ${currentCurrency}`
            : '',
        },
        {
          title: 'Average yearly payments',
          value: checkIfExistValue(data?.average_yearly_payments)
            ? `${normalizeNumber(
              data?.average_yearly_payments
            )} ${currentCurrency}`
            : '',
        },
      ]
    } else return []
  }, [summary])

  const periodThree = useMemo(() => {
    if (summary && !summary?.error) {
      const data = summary?.financial_ppa?.length
        ? summary?.financial_ppa[2]
        : {
          irr_for_spv: 0,
          ppa_heat_price: 0,
          ppa_electricity_price: 0,
          ppa_energy_price_escalation: 0,
          net_cost_savings: 0,
          // total_net_cost_savings: 0,
          average_yearly_payments: 0,
        }
      return [
        {
          title: 'IRR for SPV',
          value: checkIfExistValue(data?.irr_for_spv)
            ? `${normalizeNumber(data?.irr_for_spv, 3)} %`
            : '',
        },
        {
          title: 'PPA heat price',
          value: checkIfExistValue(data?.ppa_heat_price)
            ? `${normalizeNumber(
              data?.ppa_heat_price,
              3
            )} ${currentCurrency} per kWh`
            : '',
        },
        {
          title: 'PPA electricity price',
          value: checkIfExistValue(data?.ppa_electricity_price)
            ? `${normalizeNumber(
              data?.ppa_electricity_price,
              3
            )} ${currentCurrency} per kWh`
            : '',
        },
        {
          title: 'PPA energy price escalation',
          value: checkIfExistValue(data?.ppa_energy_price_escalation)
            ? `${normalizeNumber(data?.ppa_energy_price_escalation, 3)} %`
            : '',
        },
        // {
        //   title: 'Net cost savings (year-1)',
        //   value: checkIfExistValue(data?.net_cost_savings)
        //     ? `${normalizeNumber(data?.net_cost_savings)} ${currentCurrency}`
        //     : '',
        // },
        {
          title: 'Total net cost savings',
          value: checkIfExistValue(data?.net_cost_savings)
            ? `${normalizeNumber(data?.net_cost_savings)} ${currentCurrency}`
            : '',
        },
        {
          title: 'Average yearly payments',
          value: checkIfExistValue(data?.average_yearly_payments)
            ? `${normalizeNumber(
              data?.average_yearly_payments
            )} ${currentCurrency}`
            : '',
        },
      ]
    } else return []
  }, [summary])

  const hasPPAValues = Object.values(periodOne).find(
    (item) => item.value !== ''
  )

  const solutionDetailsFiltered = useMemo(
    () => solutionDetails?.filter((item) => item.value),
    [solutionDetails]
  )

  const energyPerformanceFiltered = useMemo(
    () => energyPerformance?.filter((item) => item.value),
    [energyPerformance]
  )

  const economicSummaryFiltered = useMemo(
    () => economicSummary?.filter((item) => item.value),
    [economicSummary]
  )

  const ppaGenericDataFiltered = useMemo(
    () => ppaGenericData?.filter((item) => item.value),
    [ppaGenericData]
  )

  const goBackToSimualtion = () => {
    setNewSimulation({
      ...newSimulation,
      status: 'creating',
    })
    navigate('/dashboard/create-simulation/step/general-information', {
      replace: true,
    })
  }

  const yearsPeriodOne =
    newSimulation.steps.ppa_financial_model.period_1.number_of_years
  const yearsPeriodTwo =
    newSimulation.steps.ppa_financial_model.period_2.number_of_years
  const yearsPeriodThree =
    newSimulation.steps.ppa_financial_model.period_3.number_of_years

  return (
    <div className="RunningComplete">
      <Helmet>
        <title>Running Complete</title>
      </Helmet>

      <header className="RunningComplete__Title_Container">
        <div className="RunningComplete__Title">
          <h1 className="text-3">¡Running complete!</h1>{' '}
          <p className="text-5 fw-meduim text-secondary d-none d-lg-inline">
            Please, review simulation results before generating the report
          </p>
        </div>
      </header>

      {isLoading || !isFetched ? (
        <div className="RunningComplete__Content d-flex justify-content-center align-items-center w-100 h-100">
          <Loader />
        </div>
      ) : (
        <>
          <div className="RunningComplete__Content Scroll_MS-SEC">
            <div className="RunningComplete__Container">
              <div className="RunningComplete__Fields-Container">
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={PaperIcon}
                    alt="Solution details"
                    title="Solution details"
                  />
                  <h2 className="text-3 fw-medium">Solution details</h2>
                </div>
                <div className="RunningComplete__Fields">
                  {solutionDetailsFiltered.map((item, index) => (
                    <SummaryField
                      key={`Summary-Field-One-${index}`}
                      title={item.title}
                      value={item.value}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className="RunningComplete__Container">
              <div className="RunningComplete__Fields-Container">
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={EnergyIcon}
                    alt="Energy performance"
                    title="Energy performance"
                  />
                  <h2 className="text-3 fw-medium">Energy performance</h2>
                </div>
                <div className="RunningComplete__Fields">
                  {energyPerformanceFiltered.map((item, index) => (
                    <SummaryField
                      key={`Summary-Field-Two-${index}`}
                      title={item.title}
                      value={item.value}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className="RunningComplete__Container">
              <div className="RunningComplete__Fields-Container">
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={CoinIcon}
                    alt="Economic summary"
                    title="Economic summary"
                  />
                  <h2 className="text-3 fw-medium">Economic summary</h2>
                </div>
                <div className="RunningComplete__Fields">
                  {economicSummaryFiltered.map((item, index) => (
                    <SummaryField
                      key={`Summary-Field-Three-${index}`}
                      title={item.title}
                      value={item.value}
                    />
                  ))}
                </div>
              </div>
            </div>

            {hasPPAValues && (
              <>
                <div className="RunningComplete__Container">
                  <div className="w-100 d-flex flex-column gap-3">
                    <div className="d-flex align-items-center gap-2">
                      <img src={CoinIcon} alt="PPA" title="PPA" />
                      <h2 className="text-3 fw-medium">PPA</h2>
                      <span className="text-5 fw-medium text-secondary">
                        Current situation
                      </span>
                    </div>

                    <div className="RunningComplete__PPA_Fields">
                      {ppaGenericDataFiltered.map((item, index) => (
                        <SummaryField
                          key={`Summary-Field-Four-${index}`}
                          title={item.title}
                          value={item.value}
                          caption={item.caption}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                <div className="RunningComplete__Container">
                  <div className="w-100 d-flex flex-column gap-3">
                    <div className="d-flex align-items-center gap-2">
                      <img src={CoinIcon} alt="PPA" title="PPA" />
                      <h2 className="text-3 fw-medium">PPA</h2>
                    </div>

                    {!isMedia ? (
                      <div className="RunningComplete__PPA d-none d-lg-grid">
                        <div className="RunningComplete__PPA_column">
                          <div className="RunningComplete__PPA_Titles" />
                          {periodOne.map((item, index) => (
                            <div
                              key={index}
                              className="RunningComplete__PPA_Titles"
                            >
                              <p className="text-4">{item.title}</p>
                            </div>
                          ))}
                        </div>
                        <div className="RunningComplete__PPA_column Options">
                          <div
                            className='RunningComplete__PPA_Option'
                          >
                            <div>
                              <h2 className="text-5 fw-medium text-start">
                                Period ({yearsPeriodOne}{' '}
                                {yearsPeriodOne == 1 ? 'year' : 'years'})
                              </h2>
                            </div>
                          </div>

                          {periodOne.map((item, index) => {
                            if (item.value && !item.title.includes('IRR'))
                              return (
                                <SummaryField
                                  key={`Summary-Field-Four-${index}`}
                                  title={item.title}
                                  value={item.value}
                                  onlyValue
                                />
                              )
                            else
                              return (
                                <SummaryField
                                  key={`Summary-Field-Four-${index}`}
                                  title={item.title}
                                  value={item.value}
                                  onlyValue
                                />
                              )
                          })}
                        </div>
                        <div className="RunningComplete__PPA_column Options">
                          <div
                            className='RunningComplete__PPA_Option'
                          >
                            <div>
                              <h2 className="text-5 fw-medium text-start">
                                Period ({yearsPeriodTwo}{' '}
                                {yearsPeriodTwo == 1 ? 'year' : 'years'})
                              </h2>
                            </div>
                          </div>

                          {periodTwo.map((item, index) => {
                            if (item.value && !item.title.includes('IRR'))
                              return (
                                <SummaryField
                                  key={`Summary-Field-Five-${index}`}
                                  title={item.title}
                                  value={item.value}
                                  onlyValue
                                />
                              )
                            else
                              return (
                                <SummaryField
                                  key={`Summary-Field-Five-${index}`}
                                  title={item.title}
                                  value={item.value}
                                  onlyValue
                                />
                              )
                          })}
                        </div>
                        <div className="RunningComplete__PPA_column Options">
                          <div className="RunningComplete__PPA_Option">
                            <div>
                              <h2 className="text-5 fw-medium text-start">
                                Period ({yearsPeriodThree}{' '}
                                {yearsPeriodThree == 1 ? 'year' : 'years'})
                              </h2>
                            </div>
                          </div>

                          {periodThree.map((item, index) => {
                            if (item.value && !item.title.includes('IRR'))
                              return (
                                <SummaryField
                                  key={`Summary-Field-Six-${index}`}
                                  title={item.title}
                                  value={item.value}
                                  onlyValue
                                />
                              )
                            else
                              return (
                                <SummaryField
                                  key={`Summary-Field-Six-${index}`}
                                  title={item.title}
                                  value={item.value}
                                  onlyValue
                                />
                              )
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="w-100 Columns Auto_flow Big d-grid d-lg-none">
                        <div className="RunningComplete__Fields-Container">
                          <div className="d-flex align-items-center gap-2">
                            <h2 className="text-4 fw-medium">
                              Period ({yearsPeriodOne}{' '}
                              {yearsPeriodOne == 1 ? 'years' : 'years'})
                            </h2>
                          </div>
                          <div className="RunningComplete__Fields PPA">
                            <div className="d-flex flex-column gap-2">
                              {periodOne.map((item, index) => {
                                if (item.value && !item.title.includes('IRR'))
                                  return (
                                    <SummaryField
                                      key={`Summary-Field-Four-${index}`}
                                      title={item.title}
                                      value={item.value}
                                    />
                                  )
                                else
                                  return (
                                    <SummaryField
                                      key={`Summary-Field-Four-${index}`}
                                      title={item.title}
                                      value={item.value}
                                    />
                                  )
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="RunningComplete__Fields-Container">
                          <div className="d-flex align-items-center gap-2">
                            <h2 className="text-4 fw-medium">
                              Period ({yearsPeriodTwo}{' '}
                              {yearsPeriodTwo == 1 ? 'years' : 'years'})
                            </h2>
                          </div>
                          <div className="RunningComplete__Fields PPA">
                            <div className="d-flex flex-column gap-2">
                              {periodTwo.map((item, index) => {
                                if (item.value && !item.title.includes('IRR'))
                                  return (
                                    <SummaryField
                                      key={`Summary-Field-Five-${index}`}
                                      title={item.title}
                                      value={item.value}
                                    />
                                  )
                                else
                                  return (
                                    <SummaryField
                                      key={`Summary-Field-Five-${index}`}
                                      title={item.title}
                                      value={item.value}
                                    />
                                  )
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="RunningComplete__Fields-Container">
                          <div className="d-flex align-items-center gap-2">
                            <h2 className="text-4 fw-medium">
                              Period ({yearsPeriodThree}{' '}
                              {yearsPeriodThree == 1 ? 'years' : 'years'})
                            </h2>
                            <span className="text-5 fw-medium text-secondary">
                              Select{' '}
                              {periodThree.length - 1 > 4
                                ? '4'
                                : periodThree.length - 1}{' '}
                              for the report
                            </span>
                          </div>
                          <div className="RunningComplete__Fields PPA">
                            <div className="d-flex flex-column gap-2">
                              {periodThree.map((item, index) => {
                                if (item.value && !item.title.includes('IRR'))
                                  return (
                                    <SummaryField
                                      key={`Summary-Field-Six-${index}`}
                                      title={item.title}
                                      value={item.value}
                                    />
                                  )
                                else
                                  return (
                                    <SummaryField
                                      key={`Summary-Field-Six-${index}`}
                                      title={item.title}
                                      value={item.value}
                                    />
                                  )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <footer className="RunningComplete__Footer">
            <Button variant='white_black' style={{ minWidth: 150}} onClick={goBackToSimualtion}>
              Edit simulation
            </Button>
            <Button
              style={{ minWidth: 150}}
              onClick={() => setShowReportModal(true)}
            >
              View report
            </Button>
          </footer>
        </>
      )}

      {showReportModal && (
        <ReportInfoModal
          closeModal={() => setShowReportModal(false)}
          handleGenerateReport={(language, numbersType, dateFormat) => handleReportContext({
            token,
            simulationDetailsOk: serverSimulationDetail.ok,
            simulationDetails,
            setIsLoading,
            setPages,
            setIdSelected,
            navigate,
            user,
            language,
            numbersType,
            dateFormat,
            setShowErrorReportModal,
          })}
        />
      )}

      {showErrorReportModal && (
        <Modal
          title="Save report"
          closeModal={() => setShowErrorReportModal(false)}
        >
          <div className="CheckModal text-center">
            <IAlert className='text-red' width={50}  height={50} />
            <h4 className='text-4 fw-medium'>We could not get the information from the report.</h4>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default RunningComplete
