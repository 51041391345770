import { ChangeEventHandler, useState } from 'react'
import { Helmet } from 'react-helmet'
import Input from '../../components/Input'
import TableFilters from '../../components/TableFilters'
import Table from '../../components/tables/Table'
import TableTitle from '../../components/TableTitle'
import DeleteSimulationModal from '../../components/modals/DeleteSimulationModal'
import { useNavigate } from 'react-router-dom'
import LoadingDots from '../../components/LoadingDots'
import { CSSTransition } from 'react-transition-group'
import useTable from '../../hooks/useTable'
import TableFiltersMobile from '../../components/TableFiltersMobile'
import useAppContext from '../../hooks/useAppContext'
import { useQuery } from 'react-query'
import { FiltersGroup, FiltersSelected, TableHeaders } from '../../types'
import { Simulation } from '../../api/apiTypes'
import {
  duplicateSimulation,
  getSimulations,
} from '../../api/simulations/general-simulation'
import { TableSimulations } from '../../components/tables/TableSimulations'
import { simulationStatus } from '../../constants'
import useOrderby from '../../hooks/useOrderby'
import Modal from '../../components/modals/Modal'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import useModalsContext from '../../hooks/useModalsContext'
import { ISearch } from '../../iconComponents'
import useMatchMedia from '../../hooks/useMatchMedia'
import ShowCopyToClipboard from '../../components/modals/ShowCopyToClipboard'
import Button from '../../components/buttons/Button'

const ManageSimulations = () => {
  const {
    token,
    isCentralAdmin,
    isOrganizationAdmin,
    isRegionAdmin,
    setsimulationsToTransfer,
  } = useAppContext()
  const { setShowContinueSimulationModal } = useModalsContext()
  const { createNewSimulation, setIsLoading, setSimulationId } = useNewSimulationContext()
  const [page, setPage] = useState(1)
  const perPage = 20
  const [search, setSearch] = useState('')
  const { orderBy, sort, handleSort } = useOrderby('created_at', false)
  const [filtersSelected, setFiltersSelected] = useState<FiltersSelected[]>([
    // {
    //   label: 'City',
    //   search: '',
    //   defaultOption: {
    //     label: 'All',
    //     value: '',
    //   },
    //   option: {
    //     label: 'All',
    //     value: '',
    //   },
    // },
    {
      label: 'Status',
      defaultOption: {
        label: 'All',
        value: '',
      },
      option: {
        label: 'All',
        value: '',
      },
    },
  ])
  const params = {
    page,
    per_page: perPage,
    search,
    order_by: orderBy,
    status:
      typeof filtersSelected[0].option.value === 'string'
        ? filtersSelected[0].option.value
        : '',
  }
  const { data, isFetching, refetch } = useQuery(
    ['getSimulations', page, search, filtersSelected, orderBy],
    () => getSimulations(token, params),
    {
      initialData: {
        page: 1,
        total_items: 0,
        total_pages: 1,
        data: [],
      },
      enabled: token !== '',
      refetchInterval: 10000,
      refetchOnWindowFocus: false,
    }
  )
  const { selectedItems, handleSelectItem, handleSelectAll } = useTable({
    tableData: data.data,
  })
  const [simulationToDelete, setSimulationToDelete] = useState<Simulation>(
    {} as Simulation
  )
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isDuplicating, setIsDuplicating] = useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [showCopyToClipboard, setShowCopyToClipboard] = useState(false)
  const [valueCopied, setValueCopied] = useState('')

  const isMobile = useMatchMedia()
  const navigate = useNavigate()

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPage(1)
    setSearch(event.target.value)
  }

  const openShowDeleteModal = () => {
    setSimulationToDelete(selectedItems[0])
    setShowDeleteModal(true)
  }

  const closeShowDeleteModal = () => setShowDeleteModal(false)

  const openShowCopyToClipboard = (value: string) => {
    setValueCopied(value)
    setShowCopyToClipboard(true)
  }

  const closeShowCopyToClipboard = () => setShowCopyToClipboard(false)

  const filtersGroup: FiltersGroup[] = [
    // {
    //   label: 'City',
    //   options: [
    //     {
    //       label: 'Córdoba',
    //       value: 'cba',
    //     },
    //   ],
    // },
    {
      label: 'Status',
      options: Object.keys(simulationStatus).map((status) => ({
        label: simulationStatus[status],
        value: status,
      })),
    },
  ]

  const tableHeaders: TableHeaders[] = [
    {
      id: 0,
      label: 'Date',
      value: 'created_at',
    },
    {
      id: 1,
      label: 'Last update',
      value: 'updated_at',
    },
    {
      id: 2,
      label: 'Location',
      value: 'location',
    },
    {
      id: 3,
      label: 'Client',
      value: 'client',
    },
    {
      id: 4,
      label: 'Project name',
      value: 'name',
    },
    {
      id: 6,
      label: 'Creator',
      value: 'creator',
    },
    {
      id: 7,
      label: 'Status',
      value: 'status',
    },
    {
      id: 8,
      label: 'Id',
      value: 'id_number',
    },
    {
      id: 9,
      label: 'Edit',
      value: '',
    },
  ]

  if (isCentralAdmin || isOrganizationAdmin || isRegionAdmin) {
    tableHeaders.splice(5, 0, {
      id: 5,
      label: 'Owner',
      value: 'owner',
    })
  }

  const closeShowAlertModal = () => setShowAlertModal(false)

  const handleDuplicate = async () => {
    if (isDuplicating) return null
    setIsDuplicating(true)

    const response = await duplicateSimulation(token, selectedItems[0].id)

    if (response.error) console.error(response.error)
    else if (response) {
      refetch()
    }
    setIsDuplicating(false)
  }

  const handleTransferSimulation = () => {
    setsimulationsToTransfer(selectedItems)
    navigate('/dashboard/manage-simulations/transfer-simulation')
  }

  const handleEditSimulation = async (simulation: Simulation) => {
    if (simulation.status === 'waiting' || simulation.status === 'running')
      return setShowAlertModal(true)

    setSimulationId(simulation.id)

    const startSimulation = async () => {
      setIsLoading(true)
      setShowContinueSimulationModal(false)
      await createNewSimulation()
    }

    switch (simulation.status) {
    case 'creating':
      navigate('/dashboard/create-simulation/step/general-information', {
        state: { from: 'edit' },
      })
      startSimulation()
      break
    case 'ready_to_run':
      navigate('/dashboard/create-simulation/project-summary')
      startSimulation()
      break
    case 'error_running':
      navigate('/dashboard/create-simulation/running-simulation', {
        state: { from: 'edit' },
      })
      startSimulation()
      break
    case 'finished':
      navigate('/dashboard/create-simulation/running-complete')
      startSimulation()
      break
    default:
      break
    }
  }

  return (
    <>
      <Helmet>
        <title>Makt: Manage Simulations</title>
      </Helmet>

      <section className="Dashboard">
        <div>
          <Input
            name="Search"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            icon={<ISearch className="Input__Icon" />}
          />
        </div>

        <div className="Dashboard__Filters_Container">
          <div className="Dashboard__Filters_Container_Mobile d-flex justify-between d-md-none pr-1 w-100">
            <TableFiltersMobile
              filtersSelected={filtersSelected}
              setFiltersSelected={setFiltersSelected}
              filtersGroup={filtersGroup}
              headers={tableHeaders}
              sort={sort}
              handleSort={handleSort}
            />

            <TableTitle
              totalItems={data.data.length}
              itemsSelected={selectedItems.length}
              mobile
            />
          </div>
          <div className="Dashboard__Filters_Container_Mobile gap-2 d-flex d-md-none">
            <Button
              variant='gray'
              className="gap-0 text-5"
              onClick={handleDuplicate}
              disabled={selectedItems.length !== 1 || isDuplicating}
            >
              Duplicate
              <CSSTransition in={isDuplicating} timeout={300} unmountOnExit>
                <LoadingDots />
              </CSSTransition>
            </Button>
            <Button
              variant='gray'
              className="text-5"
              onClick={handleTransferSimulation}
              disabled={selectedItems.length === 0}
            >
              Transfer to
            </Button>
            <button
              className="button_red text-5"
              onClick={openShowDeleteModal}
              disabled={selectedItems.length !== 1}
            >
              Delete
            </button>
          </div>

          <TableFilters
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            filtersGroup={filtersGroup}
          />
          <TableTitle
            totalItems={data.data.length}
            itemsSelected={selectedItems.length}
          />
          <Button
            variant='gray'
            className="gap-0 text-5 d-none d-md-flex"
            onClick={handleDuplicate}
            disabled={selectedItems.length !== 1 || isDuplicating}
          >
            Duplicate
            <CSSTransition in={isDuplicating} timeout={300} unmountOnExit>
              <LoadingDots />
            </CSSTransition>
          </Button>
          <Button
            variant='gray'          
            className="text-5 d-none d-md-flex"
            onClick={handleTransferSimulation}
            disabled={selectedItems.length === 0}
          >
            Transfer to
          </Button>
          <button
            className="button_red text-5 d-none d-md-flex"
            onClick={openShowDeleteModal}
            disabled={selectedItems.length !== 1}
          >
            Delete
          </button>
        </div>

        <Table
          type={
            isCentralAdmin || isOrganizationAdmin || isRegionAdmin
              ? 'Simulations_Extended_Action'
              : 'Simulations'
          }
          handleSelectAll={handleSelectAll}
          selectedItemsLength={selectedItems.length}
          headers={tableHeaders}
          dataLength={data.data.length}
          loading={isFetching || token === ''}
          sort={sort}
          handleSort={handleSort}
          page={page}
          setPage={setPage}
          perPage={perPage}
          totalPages={data.total_pages}
          totalItems={data.total_items}
        >
          {data.data.map((item) => (
            <TableSimulations
              key={item.id}
              data={item}
              selected={selectedItems.some(
                (itemSelect) => itemSelect?.id === item.id
              )}
              handleSelectItem={handleSelectItem}
              action={handleEditSimulation}
              handleCopy={openShowCopyToClipboard}
              isMobile={isMobile}
            />
          ))}
        </Table>
      </section>

      {showDeleteModal ? (
        <DeleteSimulationModal
          simulationToDelete={simulationToDelete}
          refetch={refetch}
          closeModal={closeShowDeleteModal}
        />
      ) : (
        <></>
      )}
      {showAlertModal ? (
        <Modal title="" closeModal={closeShowAlertModal}>
          <div className="py-5 text-center">
            <p className="text-3 fw-medium">
              {'simulations that are running or waiting can\'t  be edited'}
            </p>
          </div>
        </Modal>
      ) : (
        <></>
      )}

      {showCopyToClipboard ? (
        <ShowCopyToClipboard
          value={valueCopied}
          handleClose={closeShowCopyToClipboard}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default ManageSimulations
