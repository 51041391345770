import { Helmet } from 'react-helmet'
import { ChangeEventHandler, useState } from 'react'
import { useQuery } from 'react-query'
import Input from '../../components/Input'
import TableFilters from '../../components/TableFilters'
import TableFiltersMobile from '../../components/TableFiltersMobile'
import Table from '../../components/tables/Table'
import TableTitle from '../../components/TableTitle'
import useAppContext from '../../hooks/useAppContext'
import { FiltersGroup, FiltersSelected, TableHeaders } from '../../types'
import { TableLogs } from '../../components/tables/TableLogs'
import { Client, Organization, User } from '../../api/apiTypes'
import LogInfoModal from '../../components/modals/LogInfoModal'
import useOrderby from '../../hooks/useOrderby'
import { ISearch } from '../../iconComponents'
import useMatchMedia from '../../hooks/useMatchMedia'

export interface FakeLog {
  id: string;
  date: string;
  type: string;
  action: string;
  user: User;
  simulation_id: string;
  client: Client;
  organization: Organization;
  target_user: User;
}

interface GetLogs {
  page: number;
  total_items: number;
  total_pages: number;
  data?: FakeLog[];
  error?: string;
}

const getLogs = (
  token: string,
  params?: {
    page?: number;
    per_page?: number;
    search?: string;
  }
) => {
  // console.log(token, params)
  const response: GetLogs = {
    page: 1,
    total_items: 1,
    total_pages: 1,
    data: [
      {
        id: 'sadfasf',
        date: '2023-02-22T12:10:39.489844Z',
        action: 'New simulation created',
        type: 'Simulation',
        user: {
          date_joined: '2023-01-04T19:25:06Z',
          email: 'lukway.dev@gmail.com',
          first_name: 'Lucas',
          id: 3,
          is_register_completed: true,
          last_name: 'Ojeda',
          username: 'Lukway',
        },
        simulation_id: '20230127AR0002',
        client: {
          id: 1,
          name: 'test',
          organization_id: 1,
          organization_name:  'adf',
          country_id: 1,
          country_name: 'Argentina',
          simulation_count: 3,
          email: 'lukway.developer@gmail.com',
          phone: '123456789',
          address: 'Mar del Plata',
          company: 'asfq',
          role: 'dad',
          country_currencies: [],
        },
        organization: {
          address: 'Argentina',
          country_residence: {id: 10, country_name: 'Argentina'},
          email: 'linkchar@gmail.com',
          id: 1,
          name: 'Linkchar',
          phone: '12312321312',
        },
        target_user: {
          date_joined: '2023-01-05T18:03:06Z',
          email: 'brunomartin.dev@gmail.com',
          first_name: 'Bruno',
          id: 6,
          is_register_completed: true,
          last_name: 'Martin',
          username: 'Bruno',
        },
      },
    ],
  }
  return response
}

const Logs = () => {
  const { token } = useAppContext()
  const [page, setPage] = useState(1)
  const perPage = 20
  const [search, setSearch] = useState('')
  const { sort, handleSort } = useOrderby('name') // TODO
  const [filtersSelected, setFiltersSelected] = useState<FiltersSelected[]>([
    {
      label: 'Action',
      defaultOption: {
        label: 'All',
        value: '',
      },
      option: {
        label: 'All',
        value: '',
      },
    },
    {
      label: 'Affected',
      defaultOption: {
        label: 'All',
        value: '',
      },
      option: {
        label: 'All',
        value: '',
      },
    },
  ])

  const params = {
    page,
    per_page: perPage,
    search,
  }
  const { data } = useQuery(
    ['getLogs', page, search, filtersSelected],
    () => getLogs(token, params),
    {
      initialData: { page: 1, total_items: 0, total_pages: 1, data: [] },
      enabled: token !== '',
    }
  )
  const [logSelected, setLogSelected] = useState(null)
  const [showLogInfoModal, setShowLogInfoModal] = useState(false)
  const isMobile = useMatchMedia()

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value)
  }

  const openShowLogInfoModal = (info: any) => {
    setLogSelected(info)
    setShowLogInfoModal(true)
  }

  const closeShowLogInfoModal = () => {
    setShowLogInfoModal(false)
  }

  const filtersGroup: FiltersGroup[] = [
    {
      label: 'Action',
      options: [
        {
          label: 'Added',
          value: 'added',
        },
        {
          label: 'Created',
          value: 'created',
        },
        {
          label: 'Deleted',
          value: 'deleted',
        },
        {
          label: 'Downloaded',
          value: 'downloaded',
        },
        {
          label: 'Invited',
          value: 'invited',
        },
        {
          label: 'Transferred',
          value: 'transferred',
        },
        {
          label: 'Uploaded',
          value: 'uploaded',
        },
      ],
    },
    {
      label: 'Affected',
      options: [
        {
          label: 'Clients',
          value: 'clients',
        },
        {
          label: 'Organizations',
          value: 'organizations',
        },
        {
          label: 'Simulations',
          value: 'simulations',
        },
        {
          label: 'Users',
          value: 'users',
        },
      ],
    },
  ]

  const tableHeaders: TableHeaders[] = [
    {
      id: 0,
      label: 'Date & time',
      value: '', // TODO
    },
    {
      id: 1,
      label: 'Action',
      value: '', // TODO
    },
    {
      id: 2,
      label: 'User',
      value: '', // TODO
    },
    {
      id: 3,
      label: 'Simulation ID',
      value: '', // TODO
    },
    {
      id: 4,
      label: 'Client',
      value: '', // TODO
    },
    {
      id: 5,
      label: 'Organization',
      value: '', // TODO
    },
    {
      id: 6,
      label: 'Target user',
      value: '', // TODO
    },
  ]

  return (
    <>
      <Helmet>
        <title>Makt: Logs</title>
      </Helmet>

      <section className="Dashboard">
        <div>
          <Input
            name="Search"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            icon={<ISearch className="Input__Icon" />}
          />
        </div>

        <div className="Dashboard__Filters_Container">
          <div className="Dashboard__Filters_Container_Mobile d-flex justify-between d-md-none pr-1">
            <TableFiltersMobile
              filtersSelected={filtersSelected}
              setFiltersSelected={setFiltersSelected}
              filtersGroup={filtersGroup}
              headers={tableHeaders}
              sort={sort}
              handleSort={handleSort}
            />
          </div>

          <TableFilters
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            filtersGroup={filtersGroup}
          />
          <TableTitle totalItems={data.data.length} />
        </div>

        <Table
          type={'Logs'}
          headers={tableHeaders}
          dataLength={data.data.length}
          loading={token === ''}
          sort={sort}
          handleSort={handleSort}
          page={page}
          setPage={setPage}
          perPage={perPage}
          totalPages={data.total_pages}
          totalItems={data.total_items}
        >
          {data.data.map((item) => {
            return (
              <TableLogs
                key={item.id}
                data={item}
                handleSelectItem={() => openShowLogInfoModal(item)}
                isMobile={isMobile}
              />
            )
          })}
        </Table>
      </section>

      {showLogInfoModal ? (
        <LogInfoModal info={logSelected} closeModal={closeShowLogInfoModal} />
      ) : (
        <></>
      )}
    </>
  )
}

export default Logs
