import {
  deleteQuery,
  getQuery,
  patchQuery,
  postQuery,
} from '../../apiFunctions'

export const getHotWaterDemand = async (token, simulation_id) => {
  return getQuery<any>({
    path: `/simulations/hot-water-demand/${simulation_id}/`,
    token,
    callback: (data) => data.data,
  })
}

export const addHotWaterDemand = async (token, simulation_id, body) => {
  return postQuery<any>({
    path: `/simulations/hot-water-demand/${simulation_id}/`,
    token,
    body,
  })
}

export const updateHotWaterDemand = async (token, simulation_id, body) => {
  return patchQuery<any>({
    path: `/simulations/hot-water-demand/${simulation_id}/`,
    token,
    body,
  })
}

// Profiles
export const getDailyProfiles = async (
  token: string,
  simulation_id?: number
) => {
  let params = {}
  if (simulation_id)
    params = {
      simulation_id,
    }

  return getQuery<any>({
    path: '/simulations/daily-profiles/',
    token,
    params,
    callback: (data) => data.data,
  })
}

export const createDailyProfile = async (token, body) => {
  return postQuery<any>({
    path: '/simulations/daily-profiles/',
    token,
    body,
  })
}

export const getDailyProfileDetail = async (token, profile_id) => {
  return getQuery<any>({
    path: `/simulations/daily-profiles/${profile_id}/`,
    token,
    callback: (data) => data.data,
  })
}

export const updateDailyProfile = async (token, profile_id, body) => {
  return patchQuery<any>({
    path: `/simulations/daily-profiles/${profile_id}/`,
    token,
    body,
  })
}

export const deleteDailyProfile = async (token, profile_id) => {
  return deleteQuery<any>({
    path: `/simulations/daily-profiles/${profile_id}/`,
    token,
  })
}
