import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getSimulationDetails } from '../../api/simulations/general-simulation'
import useAppContext from '../../hooks/useAppContext'
import useModalsContext from '../../hooks/useModalsContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import Modal from './Modal'
import Button from '../buttons/Button'

const ContinueSimulationModal = () => {
  const { token } = useAppContext()
  const {
    newSimulation,
    resetNewSimulation,
    createNewSimulation,
    setIsLoading,
  } = useNewSimulationContext()
  const { setShowContinueSimulationModal } = useModalsContext()
  const [simulationName, setSimulationName] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const getData = async () => {
      const response = await getSimulationDetails(token, newSimulation.id, {
        part_detail: true,
      })
      setSimulationName(response.name)
    }

    if (newSimulation.id) getData()
  }, [newSimulation])

  const deleteSavedSimulation = () => {
    resetNewSimulation()
    navigate('/dashboard/create-simulation/step/general-information')
    setShowContinueSimulationModal(false)
  }

  const continueSimulation = () => {
    setIsLoading(true)
    createNewSimulation()
    setShowContinueSimulationModal(false)
  }

  return (
    <Modal title="Continue with the simulation?">
      <div className="d-flex flex-column gap-5 pt-3 text-center">
        <p className="text-4">
          You have the simulation{' '}
          <span className="fw-medium">&quot;{simulationName}&quot;</span> in
          progress
        </p>

        <p className="text-4">Do you want to finish or create a new one?</p>

        <div className="d-flex gap-4">
          <Button variant="white_black" className='w-100' onClick={deleteSavedSimulation}>
            Create new
          </Button>
          
          <Button
            className="w-100"
            onClick={() => newSimulation.status ? setShowContinueSimulationModal(false) : continueSimulation()}
          >
              Continue
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ContinueSimulationModal
