import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import {
  createSimulation,
  updateSimulation,
} from '../../api/simulations/general-simulation'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import Input from '../../components/Input'
import SearchInput from '../../components/SearchInput'
import { submitStep } from '../../utils/submitStep'
import {
  getAllOrganizations,
  getOffices,
  getOrganizationRegions,
} from '../../api/organizations'
import { GetClients, getClients } from '../../api/clients'
import { Client } from '../../api/apiTypes'
import { IAngleUp } from '../../iconComponents'

const GeneralInformation = () => {
  const {
    token,
    user,
    toggleShowNewClientModal,
    setNewClientCallback,
    isCentralAdmin,
    isOrganizationAdmin,
  } = useAppContext()
  const { newSimulation, updateNewSimulation, setIsStepLoading } =
    useNewSimulationContext()
  const [resetInputs, setResetInputs] = useState({
    organization: false,
    region: false,
    office: false,
    client: false,
    currency_selected: false,
  })
  const [organizations, setOrganizations] = useState([])
  const [regions, setRegions] = useState([])
  const [offices, setOffices] = useState([])
  const [clients, setClients] = useState<GetClients>({
    page: 0,
    total_items: 0,
    total_pages: 0,
    data: [],
    dolar: {
      id: 2,
      name: 'United States Dollar',
      code: 'USD',
    },
    euro: {
      id: 6,
      name: 'Euro',
      code: 'EUR',
    },
  })
  const [searchClients, setSearchClients] = useState([])
  const [currencies, setCurrencies] = useState([])
  const location = useLocation()

  const formik = useFormik({
    initialValues: {
      name: '',
      organization: null,
      region: null,
      office: null,
      client: null,
      currency_selected: null,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      organization: Yup.lazy(() => {
        if (isCentralAdmin) {
          return Yup.number().nullable().required('Required')
        }
        return Yup.number().nullable().notRequired()
      }),
      region: Yup.lazy(() => {
        if (isCentralAdmin || isOrganizationAdmin) {
          return Yup.number().nullable().required('Required')
        }
        return Yup.number().nullable().notRequired()
      }),
      office: Yup.number().nullable().required('Required'),
      client: Yup.number().nullable().required('Required'),
      currency_selected: Yup.number().nullable().required('Required'),
    }),
    onSubmit: async (values) => {
      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => createSimulation(token, values),
        updateFunction: () =>
          updateSimulation(token, {
            simulation_id: newSimulation.id,
            ...values,
          }),
        updateNewSimulation,
        setIsStepLoading,
      })
    },
    onReset: () => {
      setResetInputs({
        organization: true,
        region: true,
        office: true,
        client: true,
        currency_selected: true,
      })

      setTimeout(() => {
        setResetInputs({
          organization: false,
          region: false,
          office: false,
          client: false,
          currency_selected: false,
        })
      }, 100)
    },
  })

  // Set saved data
  useEffect(() => {
    if (user) {
      const organization = isCentralAdmin
        ? newSimulation.steps.general_information?.region?.organization
        : user.organization?.id
      const region =
        isCentralAdmin || isOrganizationAdmin
          ? newSimulation.steps.general_information?.region?.id
          : user.region?.id

      formik.setValues({
        name: newSimulation.steps.general_information.name,
        organization,
        region,
        office: newSimulation.steps.general_information.office,
        client: newSimulation.steps.general_information.client,
        currency_selected:
          newSimulation.steps.general_information.currency_selected?.id,
      })
    }
  }, [newSimulation, user])

  // Set organization
  useEffect(() => {
    const getData = async () => {
      const response = await getAllOrganizations(token)
      const normalizedOrganizations = response.data.map((client) => ({
        value: client.id,
        label: client.name,
      }))

      setOrganizations(normalizedOrganizations)
    }

    if (token && isCentralAdmin) getData()
  }, [token, isCentralAdmin])

  // Set regions
  useEffect(() => {
    const getData = async () => {
      const response = await getOrganizationRegions(
        token,
        formik.values.organization
      )
      const normalizedRegions = response.data.map((client) => ({
        value: client.id,
        label: client.name,
      }))

      setRegions(normalizedRegions)
    }

    if (
      token &&
      (isCentralAdmin || isOrganizationAdmin) &&
      formik.values.organization
    )
      getData()
  }, [token, formik.values.organization, isCentralAdmin, isOrganizationAdmin])

  // Set offices
  useEffect(() => {
    const getData = async () => {
      const response = await getOffices(token, {
        organization_id: formik.values.organization,
      })
      const normalizedOffices = response.data.map((office) => ({
        value: office.id,
        label: office.name,
      }))

      setOffices(normalizedOffices)
    }

    if (token && formik.values.organization) getData()
  }, [token, formik.values.organization])

  // Set clients
  useEffect(() => {
    const getData = async () => {
      const response = await getClients(token, {
        organization: formik.values.organization,
      })

      if (response.data) {
        setClients(response)

        const normalizedClients = response.data.map((client) => ({
          value: client.id,
          label: client.name,
        }))

        setSearchClients(normalizedClients)
      }
    }

    if (token && formik.values.organization) getData()
  }, [token, formik.values.organization])

  // Set currencies
  useEffect(() => {
    const setCurrenciesOptions = async () => {
      const clientSelected = clients.data.find(
        (client) => client.id == formik.values.client
      )

      if (clientSelected?.country_currencies) {
        const newCurrencies = clientSelected.country_currencies
        newCurrencies.push(clients.dolar, clients.euro)

        // const normalizeCurrencies = newCurrencies.map(currency => ({
        //   value: currency.id,
        //   label: `${currency.code} - ${currency.name}`
        // }))
        // console.log(normalizeCurrencies)

        const uniqueCurrencies = {}
        const filteredCurrencies = []

        newCurrencies.forEach((currency) => {
          if (!uniqueCurrencies[currency.id]) {
            uniqueCurrencies[currency.id] = true
            filteredCurrencies.push({
              value: currency.id,
              label: `${currency.code} - ${currency.name}`,
            })
          }
        })

        setCurrencies(filteredCurrencies)
      }
    }

    if (formik.values.client && clients.data?.length > 0) {
      setCurrenciesOptions()
    }
  }, [formik.values.client, clients])

  const handleInputChange = (key, value) => {
    if (!value) {
      switch (key) {
      case 'organization':
        setResetInputs({
          organization: true,
          region: true,
          office: true,
          client: isCentralAdmin,
          currency_selected: isCentralAdmin,
        })
        formik.setValues({
          name: formik.values.name,
          organization: null,
          region: null,
          office: null,
          client: isCentralAdmin ? null : formik.values.client,
          currency_selected: isCentralAdmin
            ? null
            : formik.values.currency_selected,
        })
        break

      case 'region':
        setResetInputs({
          organization: false,
          region: true,
          office: true,
          client: false,
          currency_selected: false,
        })
        formik.setValues({
          name: formik.values.name,
          organization: formik.values.organization,
          region: null,
          office: null,
          client: formik.values.client,
          currency_selected: formik.values.currency_selected,
        })
        break

      case 'office':
        setResetInputs({
          organization: false,
          region: false,
          office: true,
          client: false,
          currency_selected: false,
        })
        formik.setValues({
          name: formik.values.name,
          organization: formik.values.organization,
          region: formik.values.region,
          office: null,
          client: formik.values.client,
          currency_selected: formik.values.currency_selected,
        })
        break

      case 'client':
        setResetInputs({
          organization: false,
          region: false,
          office: false,
          client: true,
          currency_selected: true,
        })
        formik.setValues({
          name: formik.values.name,
          organization: formik.values.organization,
          region: formik.values.region,
          office: formik.values.office,
          client: null,
          currency_selected: null,
        })
        break

      default:
        break
      }

      setTimeout(() => {
        setResetInputs({
          organization: false,
          region: false,
          office: false,
          client: false,
          currency_selected: false,
        })
      }, 100)
    } else {
      formik.setFieldValue(key, value)
    }
  }

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: General Information</title>
      </Helmet>

      <div className="CreateSimulation__Container">
        <h1 className='text-4 fw-medium text-center'>General information</h1>

        <Input
          label="Project Name"
          name="name"
          placeholder="Enter project name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.errors.name}
        />

        {isCentralAdmin && (
          <SearchInput
            label="Organization"
            name="organization"
            placeholder="Select an organization"
            search={organizations}
            value={formik.values.organization}
            onChange={(id) => handleInputChange('organization', id)}
            error={formik.errors.organization}
            reset={resetInputs.organization}
            disabled={organizations.length == 0}
            disabledPlaceholder="There are no organizations"
          />
        )}

        {(isCentralAdmin || isOrganizationAdmin) && (
          <SearchInput
            label="Region"
            name="region"
            placeholder="Select an region"
            search={regions}
            value={formik.values.region}
            onChange={(id) => handleInputChange('region', id)}
            error={formik.errors.region}
            reset={resetInputs.region}
            disabled={
              (isCentralAdmin && !formik.values.organization) ||
              regions.length == 0
            }
            disabledPlaceholder={
              isCentralAdmin && !formik.values.organization
                ? 'Select an organization first'
                : 'There are no regions'
            }
          />
        )}

        <SearchInput
          label="Office"
          name="office"
          placeholder="Select an office"
          search={offices}
          value={formik.values.office}
          onChange={(id) => handleInputChange('office', id)}
          error={formik.errors.office}
          reset={resetInputs.office}
          disabled={
            ((isCentralAdmin || isOrganizationAdmin) &&
              !formik.values.region) ||
            offices.length == 0
          }
          disabledPlaceholder={
            (isCentralAdmin || isOrganizationAdmin) && !formik.values.region
              ? 'Select a region first'
              : 'There are no offices'
          }
        />

        <SearchInput
          label="Client"
          name="client"
          placeholder="Client's name"
          search={searchClients}
          value={formik.values.client}
          onChange={(id) => handleInputChange('client', id)}
          error={formik.errors.client}
          reset={resetInputs.client}
          disabled={
            (isCentralAdmin && !formik.values.organization) ||
            searchClients.length == 0
          }
          disabledPlaceholder={
            isCentralAdmin && !formik.values.organization
              ? 'Select an organization first'
              : 'There are no clients'
          }
          buttonLabel="Add new"
          buttonIcon={<IAngleUp width={10} height={10} className="flex-shrink-0 rotate_180"/>}
          buttonOnClick={() => {
            setNewClientCallback(() => async (client: Client) => {
              const response = await getClients(token, {
                organization: formik.values.organization,
              })

              if (response.data) {
                setClients(response)

                const normalizedClients = response.data.map((client) => ({
                  value: client.id,
                  label: client.name,
                }))

                setSearchClients(normalizedClients)

                handleInputChange('client', client.id)
              }
            })
            toggleShowNewClientModal()
          }}
        />

        <SearchInput
          label="Currency"
          name="currency_selected"
          placeholder="Select currency"
          search={currencies}
          value={formik.values.currency_selected}
          onChange={(id) => formik.setFieldValue('currency_selected', id)}
          error={formik.errors.currency_selected}
          reset={resetInputs.currency_selected}
          disabled={!formik.values.client || currencies.length == 0}
          disabledPlaceholder={
            formik.values.client && currencies.length == 0
              ? 'The client has no assigned currencies'
              : 'Select a client first'
          }
        />
      </div>
    </SimulationFormikHandler>
  )
}

export default GeneralInformation
