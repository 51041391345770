import { useState, useMemo } from 'react'
import { useQuery } from 'react-query'
import { editOrganizationRegion, getCountries } from '../../api/organizations'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useAppContext from '../../hooks/useAppContext'
import { IDelete } from '../../iconComponents'
import Input from '../Input'
import SearchInput from '../SearchInput'
import Modal from './Modal'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import LoadingDots from '../LoadingDots'
import CheckModal from './CheckModal'
import classNames from 'classnames'
import { Region } from '../../api/apiTypes'
import Button from '../buttons/Button'

interface Props {
  closeModal: () => void;
  organizaionId: number;
  regionInfo: Region;
  refetch: () => void;
}

const EditRegionModal = ({ closeModal, regionInfo, refetch }: Props) => {
  const { token } = useAppContext()
  const [step, setStep] = useState(1)
  const [serverError, setServerError] = useState(false)
  const [initialCountries] = useState<Array<number>>(regionInfo.countries?.map(country => country.country))
  const [countryList, setCountryList] =
    useState<Array<number>>(initialCountries)
  const [countryError, setCountryError] = useState('')
  const [removedCountries, setRemovedCountries] = useState<Array<number>>([])
  const [resetInput, setResetInput] = useState(false)
  const { data } = useQuery(['getCountries'], () => getCountries(token), {
    initialData: {
      user_countries: [],
      all_countries: [],
    },
    enabled: token !== '',
  })
  const countries = useMemo(
    () => data.all_countries ? data.all_countries.map((country) => ({ value: country.id, label: country.name })) : [],
    [data]
  )
  const countriesFiltered = useMemo(
    () =>
      countries.filter((country) => ![...countryList].includes(country.value)),
    [countries, countryList]
  )

  const formik = useFormik<{
    name: string;
  }>({
    initialValues: {
      name: regionInfo.name,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
    onSubmit: async ({ name }) => {
      if (countryList.length === 0)
        return setCountryError('at least one country is required')

      const response = await editOrganizationRegion(token, {
        id: regionInfo.id,
        name,
        countries: countryList.filter((countryId) => countryId !== 0),
      })

      if (response.error === 'Region already exists in this organization')
        formik.setErrors({
          name: 'Region already exists in this organization',
        })
      else if (response.error) {
        setServerError(true)
      } else if (response) {
        setStep((prev) => prev + 1)
        refetch()
      }
    },
  })

  const handleSearch = (value: string) => {
    if (!countryList.includes(Number(value)))
      setCountryList((prev) => [...prev, Number(value)])

    setResetInput(true)
    setTimeout(() => {
      setResetInput(false)
    })
  }

  const handleRemoveCountry = (
    value: number,
    index: number,
    isInitial: boolean
  ) => {
    setCountryList((prev) => {
      // const contryIndex = prev.findIndex((country) => country === value)
      return [...prev.slice(0, index), ...prev.slice(index + 1)]
    })
    if (isInitial) setRemovedCountries((prev) => [...prev, value])
  }

  const handleAddReamoved = (value: number, index: number) => {
    setCountryList((prev) => [...prev, value])

    setRemovedCountries((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ])
  }

  switch (step) {
  case 1:
    return (
      <Modal title="Edit region" closeModal={closeModal}>
        <div className="Modal__Scroll-Content d-flex flex-column gap-3 py-3">
          <Input
            label="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
          />
          <SearchInput
            label="Country"
            name="Country"
            search={countriesFiltered}
            onChange={handleSearch}
            reset={resetInput}
            error={countryError}
          />
          <div className="d-flex flex-wrap gap-3">
            {/* {initialCountries.map((item) => {
              const countrySelected = countries.find(
                (country) => country.value === item
              )
              if (countrySelected)
                return (
                  <button
                    key={item}
                    className="button-pill d-flex align-items-center gap-2 text-6"
                    onClick={() => handleRemoveCountry(item)}
                  >
                    <span>{countrySelected.label}</span>
                    <IDelete width={12} height={12} />
                  </button>
                )
            })} */}
            {countryList.map((item, index) => {
              const countrySelected = countries.find(
                (country) => country.value === item
              )
              const isNewCountry = !initialCountries.includes(item)

              if (countrySelected)
                return (
                  <button
                    key={item}
                    className={classNames(
                      {
                        'button-pill': !isNewCountry,
                        'button-green': isNewCountry,
                      },
                      'd-flex align-items-center gap-2 text-6'
                    )}
                    onClick={() =>
                      handleRemoveCountry(item, index, !isNewCountry)
                    }
                  >
                    <span>{countrySelected.label}</span>
                    <IDelete width={12} height={12} />
                  </button>
                )
            })}
            {removedCountries.map((item, index) => {
              const countrySelected = countries.find(
                (country) => country.value === item
              )

              if (countrySelected)
                return (
                  <button
                    key={item}
                    className="button_transparent_red d-flex align-items-center gap-2 text-6"
                    onClick={() => handleAddReamoved(item, index)}
                  >
                    <span>{countrySelected.label}</span>
                    <IDelete width={12} height={12} />
                  </button>
                )
            })}
          </div>

          {serverError ? <p className='text-red'>Something went wrong</p> : <></>}

          <div className="d-flex flex-column align-items-center gap-2 py-4">
            <SwitchTransition>
              <CSSTransition
                key={formik.isSubmitting.toString()}
                timeout={300}
                unmountOnExit
              >
                <Button
                  className="gap-0  w-100"
                  type="submit"
                  onClick={() => formik.handleSubmit()}
                  disabled={formik.isSubmitting || countryList.length === 0}
                >
                  {formik.isSubmitting ? (
                    <>
                      <span>Saving</span>
                      <LoadingDots className="enter-done" />
                    </>
                  ) : (
                    <span>Save changes</span>
                  )}
                </Button>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </div>
      </Modal>
    )
  default:
    return (
      <CheckModal
        closeModal={closeModal}
        title="Edit region"
        message="¡Changes saved!"
      />
    )
  }
}

export default EditRegionModal
