import { Helmet } from 'react-helmet'

const Parameters = () => {
  return (
    <>
      <Helmet>
        <title>Makt: Parameters</title>
      </Helmet>

      <div className='w-100 d-flex justify-content-center align-items-center py-5 px-3'>
        <h4>Here will go the parameters</h4>
      </div>
    </>
  )
}

export default Parameters