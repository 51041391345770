import dayjs from 'dayjs'
import { SectionExpirationDate } from '../../types/generateReport'
import { DateFormats } from '../../types/index'
import { Html } from 'react-pdf-html'

type Props = {
  section: SectionExpirationDate;
  dateFormat: DateFormats
  language: 'en' | 'du'
}
const stylesheet = {
  ['*']: {
    padding: 0,
    margin: 0,
  },
  ['p, h1, h2, h3, h4, h5, h6']: {
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
}

export default function ExpirationDateRender({ section, language, dateFormat }: Props) {
  return (
    <Html
      stylesheet={stylesheet}
      style={{
        fontFamily: 'Roboto',
        fontSize: 12,
      }}
    >
      {language === 'en' 
        ? `<h5><span style="color: rgb(229, 0, 126);">Expires on ${section.value ? dayjs(section.value, 'YYYY-MM-DD').format(dateFormat) : ''}</span></h5>`
        : `<h5><span style="color: rgb(229, 0, 126);">Verloopt op ${section.value ? dayjs(section.value, 'YYYY-MM-DD').format(dateFormat) : ''}</span></h5>`
      }
    </Html>
  )
}
